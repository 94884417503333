export class ColumnsDisplayOption {
  name: string;
  displayName: string;
  //For display
  displayValueTransform?: (value) => any;
  //For sorting
  valueTransform?: (value) => any;
  columnType?: ColumnType;
  columnTypeTransform?: (row: any) => ColumnType;
  headerClass?: string;
  sortable?: boolean = true;
  innerText?: boolean = false;
  permission?: string[] = [];
  dictionaryArrayName?: string;
  columnValue?: (value) => any
}

export enum ColumnType {
  Text = 1,
  List = 2,
  ImageLarge = 3,
  Status = 4,
  Avatar = 5,
  Tooltip = 6,
  Logo = 7,
  Delete = 8,
  Chips = 9,
}
