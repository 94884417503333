import { Module } from "../_models/module.model";

export enum AppModule {
  TechnicalConditions = 1,
  Orders = 2,
  Assistance = 3,
  PriceList = 4,
  Legal = 5,
  EmergencyNumbers = 6,
  Tenants = 7,
  Infrastructure = 8,
  Agreements = 9,
  BuildingDiagram = 10,
  Building = 11,
  User = 12,
  Operator = 13,
  Project = 21,
  Home = 22,
  Task = 23,
  Document = 24,
  StocekdProduct = 25,
  CustomersNetwork = 26,
  Other = 0,
  None = -1,
}

export const Modules: Module[] = [
  {
    title: "Start",
    url: "/home",
    iconSrc: "../../assets/icons/menu/home-icon.svg",
    module: AppModule.Home,
    permission: "menu.home",
    isHomeView: false,
    isMenu: true,
  },
  {
    title: "Dane kontaktowe",
    url: "/emergency-number",
    iconSrc: '../assets/icons/menu/call.svg',
    module: AppModule.EmergencyNumbers,
    permission: "menu.emergency-number",
    isHomeView: false,
    isMenu: true,
  },
  {
    title: "Lista Najemców",
    url: "/tenant", // Company type: operator = 1 | tenant = 2
    iconSrc: '../assets/icons/menu/community.svg',
    module: AppModule.Tenants,
    permission: "menu.tenants",
    isHomeView: false,
    isMenu: true,
  },
  {
    title: "Warunki techniczne",
    url: "/technical-conditions",
    iconSrc: "../../assets/icons/menu/technical-conditions.svg",
    module: AppModule.TechnicalConditions,
    permission: "menu.technical-conditions",
    isHomeView: true,
    homeViewButton: "+ Dodaj warunki techniczne",
    homeViewButtonLink: "/technical-conditions/add",
    homeViewPermission: "technical-conditions.add-new-condition",
    description: "Funkcjonalność pozwalająca na złożenie zapytania o warunki techniczne, uzyskania oferty, złożenia zamówienia",
    isMenu: true,
  },
  {
    title: "Zamówienia",
    url: "/order",
    iconSrc: "../../assets/icons/menu/orders.svg",
    module: AppModule.Orders,
    permission: "menu.order",
    isHomeView: true,
    description: "Funkcjonalność pozwalająca na	monitorowanie statusu realizacji zamówienia, odbioru zrealizowanej usługi, zamówienia asysty w celu odbioru usługi, pobrania dokumentów zamówienia i odbioru",
    isMenu: true,
  },
  {
    title: "Infrastruktura",
    url: "/infrastructure",
    iconSrc: '../assets/icons/menu/infrastructure.svg',
    module: AppModule.Infrastructure,
    permission: "menu.infrastructure",
    isHomeView: false,
    isMenu: true,
  },
  {
    title: "Weryfikacja projektu",
    url: "/project",
    iconSrc: '../assets/icons/menu/project.svg',
    module: AppModule.Project,
    permission: "menu.project",
    isHomeView: false,
    isMenu: true,
  },
  {
    title: "Budynki",
    url: "/building",
    iconSrc: "../../assets/icons/menu/buildings.svg",
    module: AppModule.Building,
    permission: "menu.building",
    isHomeView: false,
    isMenu: true,
  },
  {
    title: "ASSISTANCE.MULTIPLE_TITLE",
    url: "/assistance",
    iconSrc: "../../assets/icons/menu/assists.svg",
    module: AppModule.Assistance,
    permission: "menu.assistance",
    isHomeView: true,
    homeViewButton: "ASSISTANCE.HOME_VIEW_BUTTON",
    homeViewButtonLink: "/assistance/add",
    homeViewPermission: "assistance.add-assistance",
    description: "ASSISTANCE.DESCRIPTION",
    isMenu: true,
  },
  {
    title: "Użytkownicy",
    url: "/user",
    iconSrc: "../../assets/icons/menu/users.svg",
    module: AppModule.User,
    permission: "menu.user",
    isHomeView: true,
    homeViewButton: "Sprawdź listę",
    homeViewButtonLink: "/user",
    homeViewPermission: "menu.user",
    description: "Lista użytkowników korzystających z Panelu Operatora oraz poziomu ich uprawnień",
    isMenu: true,
  },
  {
    title: "OPERATOR.MULTIPLE_TITLE",
    url: "/company", // Company type: operator = 1 | tenant = 2
    iconSrc: "../../assets/icons/menu/operators.svg",
    module: AppModule.Operator,
    permission: "menu.operator",
    isHomeView: false,
    homeViewButton: "OPERATOR.ADD",
    homeViewButtonLink: "",
    isMenu: true,
  },
  {
    title: "Cenniki",
    url: "/price-list",
    iconSrc: "../../assets/icons/menu/price-list.svg",
    module: AppModule.PriceList,
    permission: "menu.price-list",
    isHomeView: false,
    isMenu: true,
  },
  {
    title: 'Megaustawa',
    // url: '/legal',
    iconSrc: '../assets/icons/menu/legal-aid.svg',
    module: AppModule.Legal,
    permission: "menu.legal",
    header: true,
    isHomeView: false,
    submenu: [
      {
        title: "Kompendium wiedzy",
        url: "/legal/info",
        permission: "menu.legal.info",
      },
      {
        title: "Raport",
        url: "/legal/report",
        permission: "menu.legal.report",
      },
      {
        title: "Pomoc prawna",
        url: "/legal/legal",
        permission: "menu.legal",
      },
    ],
    isMenu: true,
  },
  {
    title: "Klienci sieciowi",
    url: "/customer",
    module: AppModule.CustomersNetwork,
    permission: 'customers-network.view',
    isHomeView: false,
    isMenu: false,
  },
  {
    title: "Zadania",
    url: "/task",
    iconSrc: "../../assets/icons/menu/task.svg",
    module: AppModule.Task,
    permission: "menu.task",
    homeViewButton: "Sprawdź listę",
    homeViewButtonLink: "/task",
    homeViewPermission: "menu.task",
    isHomeView: true,
    isMenu: true,
  },
  {
    title: "Magazyn",
    url: "/warehouse",
    iconSrc: "../../assets/icons/menu/warehouse.svg",
    module: AppModule.StocekdProduct,
    permission: "menu.stocked-product",
    homeViewButton: "Sprawdź listę",
    homeViewButtonLink: "/warehouse",
    homeViewPermission: "menu.stocked-product",
    isHomeView: false,
    isMenu: true,
  },
  {
    title: "Dokumenty",
    url: "/document",
    iconSrc: "../../assets/icons/menu/document.svg",
    module: AppModule.Document,
    permission: "menu.document",
    homeViewButton: "Sprawdź listę",
    homeViewButtonLink: "/document",
    homeViewPermission: "menu.document",
    isHomeView: true,
    isMenu: true,
  },
];
