<div class="container__center">
  <div class="content" *ngIf="pageContent">
    <div class="company-logo-container">
      <img [src]="appInstanceService.getAppAssetsUrl('logo')">
    </div>

    <div class="header center-text">
      <h2>{{ pageContent.header }}</h2>
      <p 
        *ngIf="!sended" 
        class="content__description"
      >
        {{ pageContent.description }}
      </p>
    </div>

    <p 
      *ngIf="pageContent.descriptionSmall" 
      class="content__description-small"
    >
      {{ pageContent.descriptionSmall }}
    </p>

    <div *ngIf="!keyVaild" class="display">
      <button
        class="btn btn-outline-secondary"
        [routerLink]="'/auth/login'"
      >
        Powrót
      </button>
    </div>

    <div *ngIf="keyVaild">
      <form 
        [formGroup]="form" 
        (ngSubmit)="sendNewPassword()" 
        *ngIf="!sended"
      >
        <div *ngIf="errorMessage" class="error-container">
          <span>{{ errorMessage }}</span>
        </div>

        <mat-form-field 
          appearance="outline" 
          class="width__100"
        >
          <mat-label>Hasło</mat-label>
          <input 
            matInput 
            type="password" 
            formControlName="password"
            [type]="hide ? 'text' : 'password'"
          >
          <mat-icon 
            matSuffix 
            (click)="hide=!hide"
          >
            {{hide ? 'visibility_off' : 'visibility'}}
          </mat-icon>
          <mat-error *ngIf="form.get('password')?.hasError('required')">
            Podanie hasła jest wymagane.
          </mat-error>
          <mat-error *ngIf="form.get('password')?.hasError('minlength')">
            Hasło powinno mieć minimum 4 znaki.
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="width__100">
          <mat-label>Powtórz hasło</mat-label>
          <input 
            matInput 
            type="password" 
            formControlName="rePassword"
            [type]="hideConfirm ? 'text' : 'password'"
          >
          <mat-icon 
            matSuffix 
            (click)="hideConfirm=!hideConfirm"
          >
            {{hideConfirm ? 'visibility_off' : 'visibility'}}
          </mat-icon>
          <mat-error *ngIf="form.get('rePassword')?.hasError('required')">
            Podanie hasła jest wymagane.
          </mat-error>
          <mat-error *ngIf="form.get('rePassword')?.hasError('minlength')">
            Hasło powinno mieć minimum 4 znaki.
          </mat-error>
        </mat-form-field>

        <div class="buttons__end">
          <button
            class="btn primary-button "
            (submit)="sendNewPassword()"
            [disabled]="loading"
          >
            {{ pageContent.confirmButton }}
          </button>
        </div>
      </form>

      <div *ngIf="sended" class="direction display">
        <span class="display">
          {{ pageContent.responseCommunicate }}
        </span>

        <button
          class="btn btn-outline-secondary width__35 display"
          [routerLink]="'/auth/login'"
        >
          Powrót
        </button>
      </div>
    </div>
  </div>
</div>
