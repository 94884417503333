<div [ngClass]="(p.isMobile$ | async) ? 'doc-acceptation__container--mobile' : 'doc-acceptation__container--desktop'">
  <div class="danger-dialog__background doc-acceptation__header">
    <h4 class="doc-acceptation__header--text width__80">
      Akceptacja zaległych dokumentów
    </h4>
    <button
      mat-icon-button
      class="doc-acceptation__back-btn"
      (click)="onCancel()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="doc-acceptation__list custom-scrollbar__danger">
    <ng-container *ngFor="let doc of documents">
      <div 
        class="doc-acceptation__box"
        (click)="onConfirm(doc?.id)"
      >
        <span>Numer: <b>{{ doc?.number }}</b></span>
        <span>Typ: {{ doc?.kind.name }}</span>
        <span>Magazyn: {{ doc?.warehouse?.name }}</span>
        <span>Firma: {{ doc?.company?.name }}</span>
        <span>Data wystawienia: {{ doc?.dateCreated | date: "dd.MM.yyyy" }}</span>
      </div>
    </ng-container>
  </div>

  <div class="doc-acceptation__buttons">
    <hr>
    <div class="doc-acceptation__hint">
      W celu akceptacji dokumentu kliknij w wybrany protokół
    </div>
    <!-- <div class="buttons__space-between">
      <button 
        class="btn btn-sm btn-danger"
        (click)="onCancel()"
      >
        Zamknij
      </button>
    </div> -->
  </div>

</div>
