import { HttpParams, HttpResponse } from '@angular/common/http';
import { FormGroup, Validators } from '@angular/forms';
import * as fileSaver from 'file-saver';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, startWith } from 'rxjs/operators';
import { addDays } from 'date-fns';
import { UserService } from '@panel/user';
import { SessionSettings, SessionSettingsAction, CountName } from '@panel/models';
import { CompanyType } from '@panel/company';

export class Utils {
  public static countNames: CountName = {
    month: {
      one: 'Miesiąc',
      few: 'Miesiące',
      many: 'Miesięcy'
    }
  };

  public static convertPropertyValue(type: string, value: number): any {
    if (type == 'bit') {
      return value ? true : false;
    }

    return value;
  }

  public static getDateFromDate(date: Date) {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
  }

  public static dateAddDays(date: Date, days: number) {
    date.setTime(date.getTime() +  (days * 24 * 60 * 60 * 1000))
  }

  public static dateAddHours(date: Date, hours: number) {
    date.setTime(date.getTime() +  (hours * 60 * 60 * 1000))
  }

  public static getFileNameFromResponseContentDisposition(res: HttpResponse<Blob>) {
    const contentDisposition = res.headers.get('Content-Disposition') || '';

    const matches = /filename=.*\\([^;]+)/ig.exec(contentDisposition)!;
    const fileName = (matches[1] || 'untitled').trim();
    return fileName;
  };

  public static saveFile(blobResponse: HttpResponse<Blob>, filename: string) {
    //const filename = Utils.getFileNameFromResponseContentDisposition(blobResponse);
    const blob = new Blob([blobResponse.body!], { type: 'application/octet-stream' });
    fileSaver.saveAs(blob, filename);
  };

  public static getFileExtension(filename: string) {
    let arr = filename?.split('.');
    return arr?.length > 0 ? arr.pop() : '';
  }

  public static getCountName(count: number, objectKey: any) {
    const str: keyof CountName = objectKey;
    let object = Utils.countNames[str];

    let name = object.one;

    if (count >= 5) {
      name = object.many;
    } else {
      name = object.few;
    }

    return count + ' ' + name;
  }

  public static emailValidationPattern() {
    return Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$");
  }

  public static httpParams<T extends object>(httpParams: T) {

    let params = new HttpParams();

    Object.entries(httpParams).forEach(([key, value]) => {
      if (value === undefined || value === null ) return;
      
      params = params.set(key, value.toString());
    });

    return params
  }

  public static compareFn(a: any, b: any) {
    if(a < b) {
      return -1;
    }
    if(a > b) {
      return 1;
    }
    return 0;
  }

  public static setCompanyTypes(userServ: UserService) {
    const companyTypes: CompanyType[] = [];

    userServ.hasRoles(['company.type-company']) ? companyTypes.push(CompanyType.Company) : null;
    userServ.hasRoles(['company.type-operator']) ? companyTypes.push(CompanyType.Operator) : null;
    userServ.hasRoles(['company.type-tenant']) ? companyTypes.push(CompanyType.Tenant) : null;
    userServ.hasRoles(['company.type-service']) ? companyTypes.push(CompanyType.Service) : null;

    return companyTypes;
  }

  /**
   * Helper method to void matSnackBar
   * @param snackBar pass service to void matSnackBar
   * @param message pass message to display
   * @param className pass css class e.g. custom-snack-bar__success / custom-snack-bar__error
   */
  public static openSnack(snackBar: MatSnackBar, message: string, className: string) {
    snackBar.open(message, '', {
      duration: 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['custom-snack-bar', className]
    });
  }

  /**
   * Init filters for autocomplete inputs
   * @param form formGroup
   * @param controlName formControlName to check valueChanges
   * @param array default data to filtering
   * @param filterArray filtered out array 
   */
  public static async initFilter(
    form: FormGroup,
    controlName: string,
    array: any[]
  ) {
    return form?.controls[controlName]?.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', array)),
    );
  }

  /**
   * Helper filter for autocomplete inputs
   * @param value introduced value to input
   * @param array default values to filter
   * @returns filtered array
   */
  private static _filter(value: string, array: any[]): string[] | any[] {
    if (value?.length > 0) {
      const filterValue = value?.toLowerCase().replace(' ', '');

      return array?.filter(option => {
        if ((option?.name + option?.code + option?.internalNumber + option?.firstName + option?.lastName + option?.key)
          .toLowerCase()
          .includes(filterValue)
        ) { return option };
      });
    } else {
      return array;
    };
  }

  public static manageSessionSetting(action: SessionSettingsAction, updateSession?: SessionSettings) {
    let sessionSettings: SessionSettings = JSON.parse(localStorage.getItem('sessionSettings')!);
    if(!sessionSettings) sessionSettings = new SessionSettings()

    switch (action) {
      case SessionSettingsAction.SET: {
        localStorage.setItem('sessionSettings', JSON.stringify(sessionSettings))
        break;
      };
      case SessionSettingsAction.GET: {
        localStorage.setItem('sessionSettings', JSON.stringify(sessionSettings))
        return sessionSettings;
      };
      case SessionSettingsAction.UPDATE: {
        sessionSettings = updateSession!;
        localStorage.setItem('sessionSettings', JSON.stringify(sessionSettings) )
        break;
      };
      default: {
        break;
      }
    }
  }

  public static filterWeekendDays(d: Date | null) {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  };

  public static isDayFallsOnWeekend(date: Date) {
    const dayOfWeek = date.getDay(); // get number of day
    const isWeekend = (dayOfWeek === 6) || (dayOfWeek  === 0); // 6 = Saturday, 0 = Sunday
    return isWeekend
  };

  /**
   * Check if date falls on weekend, if true add days to pass weekend day to workday 
   * @param date date to check if it falls on a weekend
   * @returns modified date to falling on work day after passing weekend days
   */
  public static async passWeekend(date: Date) {
    let passDays = 0; // default value for passing days on mindate assistance 
    if(date.getDay() === 0) { passDays = 2 }; // when assitance mindate falls on sunday add 2 days
    if(date.getDay() === 6) { passDays = 1 }; // when assitance mindate falls on saturday add 1 day

    while(Utils.isDayFallsOnWeekend(date)) {
      date = addDays(date, passDays);
    };

    return date;
  }

  /**
  * Download data as CSV file
  * @param data data to be downloaded and converted to CSV
  * @param filename name of the file to be downloaded
  * @param headerList array of headers to be displayed in CSV file
  */
  public static async downloadFile(data: any, filename='data', headerList: string[] = []) {
    let csvData = await this.ConvertToCSV(data, headerList);

    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;

    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
}

/**
* Convert array of objects to CSV
* @param objArray array of objects to be converted to CSV
* @param headerList array of headers to be displayed in CSV file
*/
public static async ConvertToCSV(objArray: any, headerList: any) {
  let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  let row = 'S.No,';

  for (let index in headerList) {
    row += headerList[index] + ',';
  }
     
  row = row.slice(0, -1);
  str += row + '\r\n';
     
  for (let i = 0; i < array.length; i++) {
    let line = (i+1)+'';
         
    for (let index in headerList) {
      let head = headerList[index];
      line += ',' + array[i][head];
    }
         
    str += line + '\r\n';
  }
    return str;
 }

  /**
  * Flatten nested objects into a single-level object
  * @param obj object to be flattened
  * @param prefix prefix for nested object properties
  * @returns flattened object
  */
  public static flattenObject(obj: any, prefix = '') {
    let flattenedObject: {[key: string]: any} = {};
    
    for (let key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        // Recursively flatten nested objects
        const nestedFlattenedObject = this.flattenObject(obj[key], prefix + key + '.');
        flattenedObject = {...flattenedObject, ...nestedFlattenedObject};
      } else {
        // Assign non-object properties directly
        flattenedObject[prefix + key] = obj[key];
      }
    }
  
  return flattenedObject;
}


}
