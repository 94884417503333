import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmDialogType } from './confirm-dialog-parameters';
import { ConfirmDialogModel, ConsentRequired, ConsentType } from './confirm-dialog.model';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;
  consentsRequired: ConsentRequired[];
  showNoButton: boolean = true;
  noButtonText: string = 'Nie';
  yesButtonText: string = 'Tak';
  error;

  consentType = ConsentType;

  public confirmDialogType: ConfirmDialogType;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmDialogModel,
    public ruter: Router
  ) {
    this.title = data.title;
    this.message = data.message;
    this.consentsRequired = data.consentRequired;

    if (data.noButtonDisabled) {
      this.showNoButton = false;
    }

    if (data.yesButtonText) {
      this.yesButtonText = data.yesButtonText;
    }

    if (data.noButtonText) {
      this.noButtonText = data.noButtonText;
    }

    if(data.confirmDialogType) {
      this.confirmDialogType = data.confirmDialogType;
    }
  }

  ngOnInit() {
  }

  onConfirm(): void {
    this.error = false;

    if (this.consentsRequired) {
      this.consentsRequired.forEach((consent) => {
        if (consent.required && !consent.value) {
          this.error = true;
          return;
        }
      });
    }

    if (this.error == true) {
      return;
    }

    this.dialogRef.close(true);
  }

  linkClick(url: string) {
    this.dialogRef.close(false);
    this.ruter.navigate([url]);
  }

  onDismiss(): void {
    this.error = false;
    this.dialogRef.close(false);
  }
}
