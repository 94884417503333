<div>
  <div class="canvas__form" id="person">
    <form 
      *ngIf="signerForm" 
      [formGroup]="signerForm"
    >
      <mat-form-field appearance="outline">
        <mat-label>Dane osoby podpisującej</mat-label>
        <input
          formControlName="personalData"
          matInput
          autocomplete="off"
        />
        <mat-error>Pole wymagane</mat-error>
      </mat-form-field>
    </form>
  </div>

  <canvas 
    class="canvas__border"
    [ngClass]="{'canvas__border--error': signatureNeeded}"
    (touchstart)="startDrawing($event)"
    (touchmove)="moved($event)"
    height="{{config.height}}"
    width="{{config.width}}"
    #canvas  
  ></canvas>

  <div 
    *ngIf="signatureNeeded" 
    class="custom-alert canvas__error"
  >
    Podpis protokołu od {{ toUppercase(type) }} jest wymagany.
  </div>
  
  <button 
    class="btn btn-sm danger-dialog__button canvas__btn" 
    (click)="clearPad()"
  >
    <div class="canvas__btn">
    <mat-icon>clear</mat-icon>
      <span class="canvas__btn--text">
        {{ signaturePadConfig.signBtnClear }}
      </span>
    </div>
  </button>
</div>
