import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormControl, FormGroup, Validators
} from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from '@panel/user'
import { AppInstanceService, AuthenticationService, TranslateConfigService } from '@panel/auth';
import { SharedModule } from '@panel/shared'

@Component({
  selector: 'lib-login',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {
  checkboxChecked = false;
  errorMessage: string;
  form: FormGroup;
  loading: boolean = false;
  hide: boolean;

  currentYear = new Date().getFullYear();
  version = this.environment.version;

  constructor(
    private userServ: UserService,
    private router: Router,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    public appInstanceService: AppInstanceService,
    private translateConfigService: TranslateConfigService,
    private translate: TranslateService,
    @Inject('env') private environment: any,
  ) {}

  ngOnInit() {
    this.translate.use('pl');

    this.form = this.formBuilder.group({
      email: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required, Validators.minLength(4)],
      }),
      password: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required, Validators.minLength(4)],
      }),
    });
  }

  onLogin() {
    let { email, password } = this.form.controls;

    if (this.form.invalid || this.loading) {
      return;
    }

    this.loading = true;

    this.authService
      .login(email.value, password.value, this.appInstanceService.getAppName()!)
      .subscribe(
        async () => {
          await this.userServ.getPermision();
          await this.userServ.setDefaultRef();

          let url = this.authService.getUrlRefTo();

          if (!this.userServ.userLogged.informationClauseAccepted) {
            url = '/legal/information-claue-accept'
          }

          this.router.navigate([url]);
          this.loading = false;
          this.authService.setUrlFromDefaultRef();
        },
        (error: any) => {
          this.errorMessage = this.translate.instant(error.error.code);
          this.loading = false;
        },
        () => {
          this.translateConfigService.setTranslation();
        }
      );
  }

  errorMessages(error: any) {

    switch (error.code) {
      case 1:
        this.errorMessage = 'Niepoprawne dane logowania.'
        break;
      case 2:
        this.errorMessage = 'Niepoprawne dane logowania.'
        break;
      case 3:
        if(error.description.includes('blocked')) {
          this.errorMessage = 'Konto zostało zablokowane.'
        } else {
          this.errorMessage = 'Konto nie zostało aktywowane, proszę postępować zgodnie z instrukcją wysłaną na adres email.'
        }
        break;
      default:
        this.errorMessage = 'Błąd logowania'
        break;
    }
  }

  openFile() {
    window.open("assets/regulamin-panel.pdf");
  }

}
