<div 
  class="content"
  (click)="onToggleMenu()" 
  [matMenuTriggerFor]="menu" 
  *ngIf="authService.currentUserValue"
  [ngClass]="{'content--mobile' : (p.isMobile$ | async)}"
>

  <app-avatar [user]="authService.currentUserValue"></app-avatar>

  <ng-container *ngIf="!(p.isMobile$ | async)">
    <div class="user-settings__row">
      <span class="user-settings__username" *ngIf="authService.currentUserValue">
        {{ authService.currentUserValue.username }}
      </span>
    
      <span class="user-settings__company" *ngIf="authService.currentUserValue.company">
        {{ authService.currentUserValue.company.name }}
      </span>
    </div>
    
    <button
      class="user-settings__expand-button" 
      [ngClass]="{ 'rotated' : menuActive }"
    >
      <mat-icon>expand_more</mat-icon>
    </button>
  </ng-container>

  <mat-menu #menu="matMenu">
    <button mat-menu-item class="submenu__button" (click)="onSettings()">
      <mat-icon class="submenu__icon">settings</mat-icon>
      <span class="submenu__text">Ustawienia</span>
    </button>
    <button 
      *ngIf="authService.currentUserValue?.companyOrigin?.assignedCompanies?.length>0" 
      mat-menu-item class="submenu__button" (click)="onAccountChange()">
      <mat-icon class="submenu__icon">manage_accounts</mat-icon>
      <span class="submenu__text">Zmień profil</span>
     </button>
    <button mat-menu-item class="submenu__button" (click)="onLogout()">
      <mat-icon class="submenu__icon">exit_to_app</mat-icon>
      <span class="submenu__text">Wyloguj się</span> 
    </button>
  </mat-menu>
  
</div>


