import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { DictionaryValues } from '../../dictionary/dictionary.model';
import { DictionaryService } from '../../dictionary/dictionary.service';
import { ViewType } from '../../_enums/view-type';
// import { isFunction } from 'util';
import { FilterService } from '../filter/filter.service';
import { ColumnsDisplayOption, ColumnType } from './column-display-option';
import { TableDisplayParameters } from './table-display-parameters';

@Component({
  selector: 'app-table-display',
  templateUrl: './table-display.component.html',
  styleUrls: ['./table-display.component.scss']
})
export class TableDisplayComponent implements OnInit {
  @Input()
  rows: Subject<any[]>;

  @Input()
  columnsDisplay: ColumnsDisplayOption[] = [];

  @Input()
  searchText = "";

  @Input()
  parameters: TableDisplayParameters = {};

  @Output()
  deleteRow = new EventEmitter<number>();

  _rows = [];
  rowsReceived = false;
  sortBy = "";
  sortDirection = "";

  columnType = ColumnType;
  loading = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dictionaryServ: DictionaryService,
    public filterServ: FilterService
  ) { }

  ngOnInit() {
    if (this.rows) {
      this.rows.subscribe(x => {
        this._rows = x;
        this.sort();
        this.rowsInit();

        this.rowsReceived = true;
      });
    } else {
      this.rowsReceived = true;
      this.loading = false;
    }
  }

  public sort() {
    if (!this._rows || this._rows?.length == 0 || !this.sortBy)
    {
      return;
    }

    let asc = this.sortDirection == 'ASC' ? 1 : -1;
    let desc = this.sortDirection == 'ASC' ? -1 : 1;
    let sortFn = null, columnDef: ColumnsDisplayOption;

    if (this.columnsDisplay)
    {
      columnDef = this.columnsDisplay?.find((c) => c.name == this.sortBy);
    }

    let fn = columnDef ? columnDef.valueTransform : null;

    if (fn)
    {
      sortFn = (a,b) => (fn(a) > fn(b) ? asc : ((fn(b) > fn(a) ? desc : 0)));
    }
    else
    {
      fn = columnDef ? columnDef.displayValueTransform : null;

      sortFn = (a,b) => {
        let va = this.transformColumnValue(a, fn);
        let vb = this.transformColumnValue(b, fn);
        return (va > vb) ? asc : ((vb > va) ? desc : 0);
      }
    }

    this._rows.sort(sortFn);
  }

  public rowsInit() {
    this._rows?.forEach(row => {
      this.loading = true;
      row._columns = this.columnsDisplay.map(column => {
        let cc = Object.assign({}, column)
        let ct = column.columnTypeTransform;
        cc.columnType = ct ? ct(row): column.columnType;
        this.loading = false
        return cc;
      });
    });

    if(this._rows?.length == 0) {
      this.loading = false;
    }
  }

  sortChange(colum: ColumnsDisplayOption) {
    if (colum.sortable === false) {
      return;
    }

    if (this.sortBy == colum.name) {
      this.sortDirection = this.sortDirection == 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.sortBy = colum.name;
      this.sortDirection = 'ASC';
    }

    this.sort();
  }

  rowClickHandle(row) {
    if (this.parameters.rowClick) {
      this.parameters.rowClick(row);
      return;
    }

    if (this.parameters.rowLink) {
      this.router.navigate([this.parameters.rowLink + row.id], { relativeTo: this.route });
    }
  }

  isListTypeOfColumn(typeOfColumn : ColumnType) {
    return typeOfColumn == ColumnType.List;
  }

  isImageLargeTypeOfColumn(typeOfColumn : ColumnType) {
    return typeOfColumn == ColumnType.ImageLarge;
  }

  private transformColumnValue(row: any[], fn: (v: any) => any): string {
    let v = fn ? fn(row) : row[this.sortBy];

    if (typeof v === 'string')
    {
      return v.toLowerCase();
    }

    return v;
  }

  getColumns() {
    return this.columnsDisplay;//.filter(x => x.permission !== false);
  }

  calcRowStyle(row) {
    let style: any = {};

    if (row.color) {
      style.backgroundColor = row.color;
    }

    return style;
  }

  getTooltipDescripton(value) {
    let td: DictionaryValues

    if(this.parameters.viewType == ViewType.TechnicalConditions) {
      td = this.dictionaryServ?.technicalConditionsStatusValues?.find(x => x.value == value.status);
    }
    if(this.parameters.viewType == ViewType.Order) {
      td = this.dictionaryServ?.ordersStatusValues?.find(x => x.value == value.status);
    }

    return td && td.description ? td.description : " ";
  }

  isTooltipDescription(value) {
    let dv: DictionaryValues

    switch(this.parameters.viewType){
      case ViewType.TechnicalConditions:
        dv = this.dictionaryServ?.technicalConditionsStatusValues?.find(x => x.value == value.status);
        return dv?.description ? true : false;
      case ViewType.Order:
        dv = this.dictionaryServ?.ordersStatusValues?.find(x => x.value == value.status);
        return dv?.description ? true : false;
      default:
        return false;
    }
  }

  onClickDeleteRow(idRow: number) {
    event.stopPropagation();
    this.deleteRow.emit(idRow);
  }

  setTextColor(key: number, arrayName: string): string {
    return this.dictionaryServ.findColor(this.dictionaryServ[arrayName], key)+'4d'
  }
}
