/**
 * Setting full translations keys by adding needed prefix for loaded translations from dictionary
 * @param value value to find name in dictionary 
 * @param translation prefix of full translation
 * @param arrayName dictionary array name e.g taskStatus
 * @returns translated full key
 */
export function setTranslationFromDictionary(
  value: number, 
  translation: string, 
  arrayName: string
): string {
  return this.translate.instant(
    (translation + this.dictionaryServ.getName(
      this.dictionaryServ[arrayName], value)
    )
  );
}