<div
  *ngIf="show"
  class="overlay__container overlay__loading"
  [ngClass]="config.customClass"
>
  <span class="overlay__loading--text">
    {{ config.textDisplay | translate }}
  </span>
  <app-loader
    [show]="true"
  ></app-loader>
</div>
