import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComponentsService } from '../components.service';
import { ConfirmDialogType } from '../confirm-dialog/confirm-dialog-parameters';
import { Comment, CommentsIdentifier } from './comments.module';
import { CommentsService } from './comments.service';
import { CommentsConfiguration } from '../../_models/comments.config.model';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {

  @Input()
  commentsConfig: CommentsConfiguration;

  @Input()
  set idTableObject(value: number) {
    this._idTableObject = value;
    this.getComments();
  }

  get idTableObject(): number {
    return  this._idTableObject;
  }

  @Input()
  removeButtonEnabled: boolean | ((comment: Comment) => boolean);

  @Input()
  filter: (comment: Comment) => boolean;

  @Output()
  array: EventEmitter<Comment[]> = new EventEmitter<Comment[]>();

  _idTableObject: number;
  comment: Comment = new Comment();
  comments: Comment[] = [];

  constructor(
    public commentServ: CommentsService,
    private componentsServ: ComponentsService,
  ) {}

  ngOnInit() {
    this.commentInit();

    this.commentServ.getNewComments().subscribe((commentsIdentifier: CommentsIdentifier) => {
      if (commentsIdentifier.tableName == this.commentsConfig.tableName && commentsIdentifier.idTableObject == this.idTableObject) {
        this.getComments();
      }
    })
  }

  commentInit() {
    this.comment = {
      ...new Comment(),
      content: ''
    };
  }

  async getComments() {
    if (this.commentsConfig.tableName && this.idTableObject) {
      this.comments = await this.commentServ.loadComments(this.commentsConfig.tableName, this.idTableObject);
      this.array.emit(this.comments);
    }
  }

  canRemove(comment: Comment): boolean {
    if (typeof this.removeButtonEnabled === 'function') {
      return this.removeButtonEnabled(comment)
    }

    return this.removeButtonEnabled;
  }

  addComment() {
    if (this.comment.content.trim().length == 0) {
      return;
    }

    this.comment.tableName = this.commentsConfig.tableName;
    this.comment.idTableObject = Number(this.idTableObject);
    this.comment.type = this.commentsConfig.defaultType;

    this.commentServ.createComment(this.comment).subscribe(() => {
      this.getComments();
      this.commentInit();
    });
  }

  removeComment(comment: Comment) {
    this.componentsServ.ShowDialog("Potwierdzenie", "Czy na pewno usunąć?", () => {
      this.commentServ.deleteComment(comment).subscribe(() => {
        this.getComments();
      })
    }, ConfirmDialogType.Danger,
    );
  }

  filterComments() {
    return this.filter ? this.comments.filter(this.filter) : this.comments;
  }
}
