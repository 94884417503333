import { Component, Input } from '@angular/core';
import { OverlayLoaderConfig } from '../../_models/overlay-loader.model';

@Component({
  selector: 'app-overlay-loader',
  templateUrl: './overlay-loader.component.html',
  styleUrls: ['./overlay-loader.component.scss']
})
export class OverlayLoaderComponent {

  @Input()
  show: boolean = false;

  @Input()
  config: OverlayLoaderConfig = new OverlayLoaderConfig();

  constructor() { }

  ngOnInit() { }

  ngOnDestroy() { }

}
