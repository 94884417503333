import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductValuePipe } from './product-value.pipe';
import { ReplaceLineBreaksPipe } from './replace-line-breaks.pipe';
import { SafePipe } from './safe.pipe';
import { YesNoPipe } from './yes-no.pipe';
import { BuildingAdressPipe } from './building-adress.pipe';
import { InitialPipe } from './initial.pipe';
import { ConfigPipe } from './config.pipe';
import { CustomFilterPipe } from './custom-filter.pipe';
import { FilterValuePipe } from './filter-option-value.pipe';
import { UserDetailsPipe } from './user-details.pipe';
import { ArrayLengthPipe } from './array-length.pipe';
import { PersonDetailsPipe } from './person-details.pipe';

@NgModule({
  declarations: [
    ProductValuePipe,
    ReplaceLineBreaksPipe,
    SafePipe,
    YesNoPipe,
    BuildingAdressPipe,
    InitialPipe,
    ConfigPipe,
    CustomFilterPipe,
    FilterValuePipe,
    UserDetailsPipe,
    ArrayLengthPipe,
    PersonDetailsPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ProductValuePipe,
    ReplaceLineBreaksPipe,
    SafePipe,
    YesNoPipe,
    BuildingAdressPipe,
    InitialPipe,
    ConfigPipe,
    CustomFilterPipe,
    FilterValuePipe,
    UserDetailsPipe,
    ArrayLengthPipe,
    PersonDetailsPipe
  ]
})

export class PipesModule { }
