<div class="header">
  <button
    *ngIf="!commentsConfig.hiddenBtnDisplay" 
    id="btn" 
    (click)="commentsConfig.isHidden = !commentsConfig.isHidden"
    class="history-btn"
  >
    {{ commentsConfig.hiddenBtnLabel }}
    <img 
      src="../../assets/icons/arrow-icon.svg" 
      [ngClass]="{ 'rotate' : commentsConfig.isHidden }" 
      alt=""
      class="default-position"
    />
  </button>
  
  <div 
    class="margin comments" 
    [hidden]="commentsConfig.isHidden" 
    [ngClass]="{ 'show' : !commentsConfig.isHidden }"
  >
    <table *ngIf="this.commentsConfig.addButtonEnabled" class="width__100">
      <tr>
        <td class="width__100">
          <mat-form-field>
            <mat-label>Dodaj komentarz</mat-label>
            <textarea 
              rows="2" 
              matInput 
              [(ngModel)]="comment.content"
              name="orderComment.description"
            ></textarea>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td class="btn__left">
          <button 
            class="btn btn-sm primary-button button" 
            (click)="addComment()"
          >+ Dodaj
          </button>
        </td>
      </tr>
    </table>

    <div class="table-container">
      <table class="table table-striped order-items">
        <thead>
          <tr class="header">
            <th class="width__20">Data</th>
            <th class="width__20">Użytkownik</th>
            <th class="width__60">Opis</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of filterComments()">
            <td class="width__20">{{ item.createdAt | date: "dd.MM.yyyy HH:mm"}}</td>
            <td class="width__20">{{ item.user ? item.user.firstName + ' ' + (item.user.lastName || '') : '[SYSTEM]'}}</td>
            <td class="width__60">{{ item.content}}</td>
            <td>
              <button 
                *ngIf="canRemove(item)" 
                mat-icon-button 
                color="primary" 
                (click)="removeComment(item)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
