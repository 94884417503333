
<div *ngIf="!settings.readonly && !uploadingLock && (settings.multiple || this.filterAttachments()?.length == 0)" [ngClass]="settings.showButton ? '' : 'd-none'">
  <div *ngIf="settings.showButton" class="container-buttons">
    <app-table-header [config]="tools" *ngIf="tools && tools?.length > 0" class="tbl-header"></app-table-header>

    <label [for]="settings.inputId" class="settings-container">
      <div *ngIf="!uploadingLoader"
        class="btn primary-button-flat margin-bottom"
        [ngClass]="{ 'width__100' : !(isStepper) }"
        (click)="fileUpload($event)">
        + {{settings.uploadButtonText}}
      </div>

      <div *ngIf="settings.uploadDescription" class="attachment-info">
        {{settings.uploadDescription}}
      </div>
    </label>

  </div>

  <input [name]="'file_' + settings.inputId" [id]="settings.inputId" class="d-sm-none" class="d-none invisible" type="file" (change)="onFileChanged($event)"
    [accept]="settings.fileTypeAccept">
</div>

<div *ngIf="((isStepper || isSummaryStepper) && (attachment.length>0))">
  <b>Załączniki</b>
</div>
<div
  class="file-container"
  [ngClass]="{
    'modal-box' : isModal,
    'stepper-box__add' : isStepper,
    'stepper-box__summary' : isSummaryStepper,
    'mobile__file-container': (p.isMobile$ | async)
  }"
  *ngFor="let item of this.filterAttachments()"
>  
  <div class="row width__100">
    <div class="col-12 col-md-6 m-0 p-0">
      <div 
        class="fileinfo"
        [ngClass]="{'mobile__file-info': (p.isMobile$ | async)}"
      >
        <img src="../../assets/document-icons/{{getFileExtensionForImg(item.publicFileName)}}.png"/>
        <div [ngClass]="{ 'fileinfo-mobile' : (p.isMobile$| async) }">
          <span class="filename">{{ item.publicFileName }}</span>
          <span *ngIf="settings.showAdditionalData" class="filename">{{item?.uploadedBy?.email + " | "}}</span>
          <span *ngIf="settings.dateDisplay" class="filename">{{ item.dateCreated | date: "dd.MM.yyyy HH:mm" }}</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 m-0 p-0">
      <div 
        class="buttons__end"
        [ngClass]="{'mobile__action-btn-container': (p.isMobile$ | async)}"  
      >
        <span 
          class="symlink" 
          *ngIf="isSupported(item) && !(
            p.platform.BLINK && (p.isMobile$ | async)
          )" 
          (click)="modalPreview(item)"
        >
          <mat-icon>find_in_page</mat-icon>
          Podgląd
        </span>
        <span class="symlink" (click)="download(item)">
          <mat-icon>cloud_download</mat-icon>
          Pobierz
        </span>
        <span 
          *ngIf="isStepper || (!settings.readonly && (settings.removeOption || (!item.idTableObject && isFresh(item))))"
          class="symlink"  
          (click)="removeAttachementConfirm(item)"
        >
          <mat-icon>delete</mat-icon>
          Usuń załącznik
        </span>
        <span 
          *ngIf="settings?.canDelete && (!settings.disabled)"
          class="symlink"  
          (click)="deleteAttachment(item)"
        >
          <mat-icon>delete</mat-icon>
          Usuń załącznik
        </span>
        <span 
          *ngIf="(settings?.signature && item?.requiredSignatures?.length > 0) && (!settings.disabled)" 
          class="symlink" 
          (click)="sign(item)"
        >
          <mat-icon>draw</mat-icon>
          Podpisz
        </span>
      </div>
    </div>
  </div>
</div>

<app-loader
  [show]="uploadingLoader"
></app-loader>

