import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentModel } from '../../models/document.model';
import { Router } from '@angular/router';
import { DocumentService } from '../../services/document.service';
import { ThisPlatformService } from '../../../_services/this-platform.service';

@Component({
  selector: 'app-document-acceptation-alert',
  templateUrl: './document-acceptation-alert.component.html',
  styleUrls: ['./document-acceptation-alert.component.scss']
})
export class DocumentAcceptationAlertComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DocumentAcceptationAlertComponent>,
    @Inject(MAT_DIALOG_DATA)
    public documents: DocumentModel[],
    public p: ThisPlatformService,
    private router: Router,
    private documentServ: DocumentService,
  ) { }

  ngOnInit() {
    this.documentServ.setAlertDisplay(true);
  }

  onConfirm(id: number) {
    this.router.navigateByUrl(`/document/edit/${id}`)
      .finally(
        () => {
          this.dialogRef.close(true);
        }
      );
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  ngOnDestroy() {
    this.documentServ.setAlertDisplay(false);
  }
}
