<div class="input auto-complete">
  <div class="control-display">
    <mat-form-field>
      <mat-label>{{label}}</mat-label>
      <div class="pos-relative">
        <div *ngIf="model" class="clear-selected" (click)="onClear()">
        </div>
        <input 
          [(ngModel)]="model" 
          (input)="onInputChnage($event.target.value)"
          (focus)="focus()" 
          [formControl]="control"
          [name]="controlName"
          [ngClass]="isListActive && filteredItems.length > 0 ? 'list-active' : ''"
          matInput
          autocomplete="off"
        />
      </div>
    </mat-form-field>

    <ul class="list" *ngIf="isListActive">
      <li 
        *ngFor="let item of filteredItems; index as j"
        lines="none" 
        (click)="elementSelected(item)"
        [ngClass]="selectedId === j ? 'selected': ''"
        class="item"
        >
        <span>{{ item[searchProperty] }}</span>
        <div class="additional-fields">
          <div class="additional-fields__row" *ngFor="let i of additionalFields">
            <span>{{item[i]}}</span>
          </div>
        </div>
      </li>
    </ul>
    
  </div>
</div>