import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  Building, BuildingLocation, BuildingVertical
} from './building.model';

const url = environment.api;

@Injectable({
  providedIn: 'root'
})
export class BuildingService {

  buildings: Building[] = [];
  buildingLocations: BuildingLocation[] = []
  buildingVerticals: BuildingVertical[] = [];
  buildingLocation: BuildingLocation = new BuildingLocation();
  buildingVertical: BuildingVertical = new BuildingVertical();
  building: Building;
  buildingSelected: Building;
  buildingLoaded: boolean = false;

  constructor(
    private http: HttpClient,
  ) { }

  getBuildings() {
    return this.http.get<Building[]>(url + 'buildings');
  }

  loadBuildings() {
    this.getBuildings().subscribe((list) => {
      this.buildingLoaded = true;
      this.buildings = list;
    });

  }

  loadBuildingsSync() {
    return this.getBuildings().toPromise().then((list) => {
      this.buildingLoaded = true;
      this.buildings = list;
    });

  }


  getBuildingsByIdCompany(idCompany: number) {
    /** TODO przebudować na lepszą składnię parametrów. */
    return this.http.get<Building[]>(`${url}buildings?${idCompany ? `idCompany=${idCompany}` : ''}`);
  }

  getBuilding(id: number) {
    return this.http.get<Building>(url + 'buildings/' + id);
  }

  getBuildingLocations(idBuilding: number) {
    return this.http.get(url + 'building-location?idBuilding=' + idBuilding);
  }

  getBuildingLocationsAll() {
    return this.http.get<BuildingLocation[]>(url + 'building-location');
  }

  loadBuldingLocations(idBuilding: number) {
    this.buildingLocations = [];
    this.getBuildingLocations(idBuilding).subscribe((buildingLocation: BuildingLocation[]) => {
      this.buildingLocations = buildingLocation;
    });
  }


  getBuildingLocation(idBuildingLocation: number) {
    return this.http.get<BuildingLocation>(url + 'building-location/' + idBuildingLocation);
  }

  loadBuildingLocation(idBuildingLocation: number) {
    this.buildingLocation = new BuildingLocation();
    if (idBuildingLocation > 0) {
      this.getBuildingLocation(idBuildingLocation).subscribe((buildingLocation: BuildingLocation) => {
        this.buildingLocation = buildingLocation;
      });
    }
  }



  deleteBuildingLocation(idBuildingLocation) {
    return this.http.delete(url + 'building-location/' + idBuildingLocation);
  }

  createBuildingLocation() {
    return this.http.post(url + 'building-location', this.buildingLocation);
  }


  getBuildingVerticals(idBuilding: number) {
    return this.http.get(url + 'building-vertical?idBuilding=' + idBuilding);
  }

  deleteBuildingVertical(idBuildingVertical) {
    return this.http.delete(url + 'building-vertical/' + idBuildingVertical);
  }

  createBuildingVertical() {
    return this.http.post(url + 'building-vertical', this.buildingVertical);
  }


  loadBuldingVerticals(idBuilding: number) {
    this.buildingVerticals = [];
    this.getBuildingVerticals(idBuilding).subscribe((buildingVertical: BuildingVertical[]) => {
      this.buildingVerticals = buildingVertical;
    });
  }

  getBuildingVertical(id: number) {
    return this.http.get(url + 'building-vertical/' + id);
  }

  loadBuildingVertical(id: number) {
    this.buildingVertical = new BuildingVertical();
    this.getBuildingVertical(id).subscribe((buildingVertical: BuildingVertical) => {
      this.buildingVertical = buildingVertical;
    });
  }

  updateBuildingVertical() {
    return this.http.put(url + 'building-vertical/' + this.buildingVertical.id, this.buildingVertical);
  }

  updateBuildingLocation() {
    return this.http.put(url + 'building-location/' + this.buildingLocation.id, this.buildingLocation)
  }

  getFromData() {
    let {
      name,
      description,
      street,
      city,
      postCode,
      manualPriceVerification,
      remoteAssistance
    } = this.building;

    let formData = new FormData();
    let fileData: File = null;

    fileData ? formData.append('file', fileData) : null;
    name ? formData.append('name', name) : null;
    description ? formData.append('description', description) : null;
    street ? formData.append('street', street) : null;
    city ? formData.append('city', city) : null;
    postCode ? formData.append('postCode', postCode) : null;
    manualPriceVerification ? formData.append('manualPriceVerification', manualPriceVerification ? "true" : "") : null;
    remoteAssistance ? formData.append('remoteAssistance', remoteAssistance ? "true" : "") : null;

    return formData;
  }

  createBuilding() {
    return this.http.post(url + 'buildings', this.building);
  }

  updateBuilding() {
    return this.http.put(url + 'buildings/' + this.building.id, this.building);
  }

  deleteBuilding() {
    return this.http.delete(url + 'buildings/' + this.building.id);
  }

  async getBuildingName(id: string) {
    // let building = await this.getBuilding(id).toPromise();
    // return building ? building.name : '';
    return '';
  }

  getBuildingDisplayName(building: Building) {
    let name = building.name;

    if (building.street) {
      name += ', ' + building.street;
    }

    if (building.city) {
      name += ', ' + building.city;
    }

    return name;
  }

  getBuildingFullAddress(building: Building) {
    return (building.street ? building.street : '') + " "
      + (building.postCode ? building.postCode : '') + " "
      + (building.city ? building.city : '');
  }
}
