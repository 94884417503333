export enum CompanyType {
  Company = 0,
  Operator = 1,
  Tenant = 2,
  Service = 3,
}

export enum CompanyTypeName {
  Company = 'COMPANY_TYPE.COMPANY',
  Operator = 'COMPANY_TYPE.OPERATOR',
  Tenant = 'COMPANY_TYPE.TENANT',
  Service = 'COMPANY_TYPE.SERVICE',
}