import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { ProductComponent } from './product/product.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { SharedDirectivesModule } from '../_directives/shared-directivies.module';

import { NgModule } from '@angular/core';
import { AttachmentViewerComponent } from './attachment-viewer/attachment-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { UploadAttachmentComponent } from './upload-attachment/upload-attachment.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TableDisplayComponent } from './table-display/table-display.component';
import { CommentsComponent } from './comments/comments.component';
import { LegalDetailsComponent } from '../legal/legal-details/legal-details.component';
import { YesNoPipe } from '../_pipes/yes-no.pipe';
import { ProductValuePipe } from '../_pipes/product-value.pipe';
import { SanitizeHtmlPipe } from '../_helpers/SanitizeHtml';
import { PipesModule } from '../_pipes/pipes.module';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { StatusComponent } from './status/status.component';
import { BuildingAdressPipe } from '../_pipes/building-adress.pipe';
import { InitialPipe } from '../_pipes/initial.pipe';
import { ConfigPipe } from '../_pipes/config.pipe';
import { TableHeaderComponent } from './table-header/table-header.component';
import { AutoCompleteComponent } from './auto-complete/auto-complete.component';
import { BuildingLinkComponent } from './building-link/building-link.component';
import { AvatarComponent } from './avatar/avatar.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { MatMenuModule} from '@angular/material/menu';
import { FilterComponent } from './filter/filter.component';
import { PersonAddComponent } from './person-add/person-add.component';
import { CustomDateAdapter } from '../_helpers/customDateAdapter';
import { ConsentComponent } from './consent/consent.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { LoaderComponent } from './loader/loader.component';
import { TranslateModule } from '@ngx-translate/core';
import { CustomFilterPipe } from '../_pipes/custom-filter.pipe';
import { FilterValuePipe } from '../_pipes/filter-option-value.pipe';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TablePaginatorComponent } from './table-paginator/table-paginator.component';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';
import { UserDetailsPipe } from '../_pipes/user-details.pipe';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { BottomSheetBtnComponent } from './bottom-sheet-menu/bottom-sheet-btn/bottom-sheet-btn.component';
import { UserChangeComponent } from './user-change/user-change.component';
import { RealizationTimeAddComponent } from './realization-time/realization-time-add/realization-time-add.component';
import { ProfileChangeComponent } from './profile-change/profile-change.component';
import { MatCardModule } from '@angular/material/card';
import { BottomSheetMenuComponent } from './bottom-sheet-menu/bottom-sheet-menu.component';
import { RealizationTimeDisplayComponent } from './realization-time/realization-time-display/realization-time-display.component';
import { CanvasComponent } from './canvas/canvas.component';
import { OverlayLoaderComponent } from './overlay-loader/overlay-loader.component';
import { UploadThumbnailComponent } from './upload-thumbnail/upload-thumbnail.component';
import { AddCompanyAssignedComponent } from './add-company-assigned/add-company-assigned.component';
import { AcceptationComponent } from './acceptation/acceptation.component';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { ArrayLengthPipe } from '../_pipes/array-length.pipe';
import { MobileCardViewComponent } from './mobile-card-view/mobile-card-view.component';
import { KanbanBoardComponent } from './kanban-board/kanban-board.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { KanbanToggleComponent } from './kanban-board/components/kanban-toggle/kanban-toggle.component';
import { ContentViewComponent } from './content-view/content-view.component';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    MainNavComponent,
    MessageDialogComponent,
    ProductComponent,
    AttachmentViewerComponent,
    UploadAttachmentComponent,
    TableDisplayComponent,
    CommentsComponent,
    LegalDetailsComponent,
    SanitizeHtmlPipe,
    ConfirmationDialogComponent,
    StatusComponent,
    TableHeaderComponent,
    AutoCompleteComponent,
    BuildingLinkComponent,
    AvatarComponent,
    UserSettingsComponent,
    FilterComponent,
    PersonAddComponent,
    ConsentComponent,
    BreadcrumbComponent,
    LoaderComponent,
    TablePaginatorComponent,
    SignaturePadComponent,
    BottomSheetBtnComponent,
    UserChangeComponent,
    RealizationTimeAddComponent,
    ProfileChangeComponent,
    BottomSheetMenuComponent,
    RealizationTimeDisplayComponent,
    CanvasComponent,
    OverlayLoaderComponent,
    UploadThumbnailComponent,
    ProfileChangeComponent,
    AddCompanyAssignedComponent,
    OverlayLoaderComponent,
    BottomSheetMenuComponent,
    AcceptationComponent,
    DialogHeaderComponent,
    MobileCardViewComponent,
    KanbanBoardComponent,
    KanbanToggleComponent,
    ContentViewComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
    NgbModule,
    MatDialogModule,
    MatCheckboxModule,
    FormsModule,
    MatAutocompleteModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    CurrencyMaskModule,
    MatTabsModule,
    MatTooltipModule,
    SharedDirectivesModule,
    PdfViewerModule,
    MatProgressSpinnerModule,
    MatListModule,
    PipesModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    BreadcrumbModule,
    TranslateModule,
    MatPaginatorModule,
    MatBottomSheetModule,
    MatCardModule,
    MatRippleModule,
    MatExpansionModule,
    DragDropModule,
    MatRadioModule
    // MobileCardViewComponent
  ],
  exports: [
    ConfirmDialogComponent,
    MainNavComponent,
    MessageDialogComponent,
    ProductComponent,
    UploadAttachmentComponent,
    RouterModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
    NgbModule,
    MatDialogModule,
    MatCheckboxModule,
    FormsModule,
    MatAutocompleteModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    CurrencyMaskModule,
    MatTabsModule,
    MatTooltipModule,
    SharedDirectivesModule,
    MatProgressSpinnerModule,
    TableDisplayComponent,
    CommentsComponent,
    LegalDetailsComponent,
    YesNoPipe,
    ProductValuePipe,
    SanitizeHtmlPipe,
    BuildingAdressPipe,
    InitialPipe,
    ConfigPipe,
    TableHeaderComponent,
    AutoCompleteComponent,
    BuildingLinkComponent,
    FilterComponent,
    ConsentComponent,
    LoaderComponent,
    TablePaginatorComponent,
    SignaturePadComponent,
    UserDetailsPipe,
    MatBottomSheetModule,
    BottomSheetBtnComponent,
    UserChangeComponent,
    PdfViewerModule,
    BottomSheetMenuComponent,
    RealizationTimeDisplayComponent,
    OverlayLoaderComponent,
    UploadThumbnailComponent,
    ProfileChangeComponent,
    OverlayLoaderComponent,
    BottomSheetMenuComponent, 
    MatSlideToggleModule,
    AcceptationComponent,
    DialogHeaderComponent,
    MatCardModule,
    MatRippleModule,
    MatExpansionModule,
    PipesModule,
    MobileCardViewComponent,
    KanbanBoardComponent,
    KanbanToggleComponent,
    ContentViewComponent,
    MatRadioModule
  ],
  entryComponents: [
    AttachmentViewerComponent,
    ConfirmationDialogComponent,
    PersonAddComponent,
  ],
  providers: [
    InitialPipe,
    YesNoPipe,
    ConfigPipe,
    BuildingAdressPipe,
    CustomFilterPipe,
    FilterValuePipe,
    UserDetailsPipe,
    ArrayLengthPipe,
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} }
  ]
})
export class ComponentsModule { }
