import { Injectable } from '@angular/core';
import { User, UserChangeCompany, UserQueryParams, UserRole } from './user.model';
import { CompanyService } from '../company/company.service';
import { AuthenticationService } from '@panel/auth';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { NewUser } from './add-edit-user/new-user.model';
import { Role } from './role.model';
import { DocumentService } from '../document/services/document.service';
import { DocumentFilterRequest } from '../_components/filter/models/filter-request.model';
import { DocumentStatus } from '../document/enums/document.enum';
import { add } from 'date-fns';
import { MatDialog } from '@angular/material/dialog';
import { DocumentModel } from '../document/models/document.model';
import { DocumentAcceptationAlertComponent } from '../document/components/document-acceptation-alert/document-acceptation-alert.component';
import { UserService as UserBaseService } from '@panel/user';

const url = environment.api;

@Injectable({
  providedIn: 'root'
})
export class UserService extends UserBaseService {

  constructor(
    public authService: AuthenticationService,
    public http: HttpClient,
    public documentServ: DocumentService,
    private dialog: MatDialog
  ) { 
    super(
      authService,
      http,
      environment
    )
  }

  switchCompany(changeCompany: UserChangeCompany) {
    return this.http.post<User>(`${url}users/switch-company`, changeCompany);
  }

  async checkDocumentsAcceptation() {
    /** needed to disable for specific routes */
    if(window.location.pathname.includes('edit/')) { return; }
    if(window.location.pathname.includes('password')) { return; }
    if(window.location.pathname.includes('login')) { return; }
    if(window.location.pathname.includes('register')) { return; }
    if(window.location.pathname.includes('legal')) { return; }

    if( 
      await this.hasRolesAsync(['document.acceptation-alert']) && 
      (this.userLogged.informationClauseAccepted)
    ) {
      this.documentServ.getQuickAccept().subscribe({
        next: (docs) => { 
          this.onAcceptAlert(docs);
        },
        error: (err) => { console.log("DOC_CHECK_ERROR", err) }
      });
    }
  }

  async permissionEditCompanyAsync(
    companyId: number,
    roles: string[],
    rolesCompany: string[]
  ): Promise<boolean> {
    if (this.permission.value?.length == 0) {
      await this.permission.toPromise();
    }

    if (this.hasRoles(roles)) { return true; } 
    else if (this.hasRoles(rolesCompany) && (companyId === this.userLogged.idCompany)) { return true; } 
    else { return false; }
  }

  private onAcceptAlert(docToAccept: DocumentModel[]) { 
    if(docToAccept && docToAccept?.length > 0) {
      const dialogRef = this.dialog.open(DocumentAcceptationAlertComponent, {
        maxWidth: '100vw',
        minWidth: '320px',
        disableClose: true,
        data: docToAccept
      });
    }
  }
}
