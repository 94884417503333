import { FormControl, FormControlName, FormGroup } from "@angular/forms"
import { User } from "../user/user.model"

export class Acceptation {
  id: number;
  status: number;
  idUser: number;
  idTableObject: number;
  tableName: string;
  user: User;
  description: string;
}

export class NewAcceptation {
  status: AcceptationStatus;
  idTableObject: number;
  tableName: string;
  description?: string;
}

export class AcceptationParams {
  tableName: string;
  status?: AcceptationStatus;
  idUser?: number;
  idTableObject?: number;
}

export enum AcceptationStatus {
  Accepted = 1,
  Rejected = 2,
}

export class AcceptationConfig {
  acceptation: Acceptation;
  form: FormGroup;
  acceptFormControl: string;
  descriptionFormControl: string;
  label: string = "Czy akceptujesz?";
  acceptLabel: string = "Zaakceptowane przez:";
  rejectLabel: string = "Odrzucone przez:";
  descriptionLabel: string = "Podaj powód odrzucenia";
}