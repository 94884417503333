import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {


  @Input() set color(value: string) {
    this.elementRef.nativeElement.style.setProperty('--color', value);
    this.colorValue = value;
  }

  @Input() name: string;

  @Input() title: string;

  colorValue: string;

  constructor(
    private elementRef: ElementRef,
  ) { }


  ngOnInit() {
  }

}
