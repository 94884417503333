<ng-container *ngIf="signaturePadConfig.showSignPad">
  <ng-container *ngFor="let signature of signaturesRequired">
    <div class="signature-pad__type">
      Podpis wymagany od: {{ toUppercase(signature.signerType) }}
    </div>
    
    <div class="signature-pad__container">
      <app-canvas 
        [signaturePadConfig]="signaturePadConfig"
        [type]="signature.signerType"
        (signature)="getSignaturePad($event, signature.signerType)"
        (personalData)="getPersonalData($event, signature.signerType)"
      ></app-canvas>
    </div>
  </ng-container>

  <hr>
  
  <div 
    class="width__100"
    [ngClass]="signaturePadConfig?.showNoSignatureBtn ? 'buttons__space-between' : 'buttons__end'"
  >
    <button 
      *ngIf="signaturePadConfig?.showNoSignatureBtn"
      class="btn btn-sm danger-dialog__button"
      [ngClass]="{'signature-pad__btn--mobile': (p.isMobile$ | async)}"
      (click)="onClickSave(false)"
    >
      <div class="signature-pad__btn">
        <mat-icon>draw</mat-icon>
        <span class="signature-pad__btn--text">
          Zapisz bez podpisu
        </span>
      </div>
    </button> 
    <button 
      *ngIf="signaturesRequired?.length > 0"
      class="btn btn-sm success-dialog__button"
      [ngClass]="{'signature-pad__btn--mobile': (p.isMobile$ | async)}"
      (click)="onClickSave(true)"
    >
      <div class="signature-pad__btn">
        <mat-icon>draw</mat-icon>
        <span class="signature-pad__btn--text">
          {{ signaturePadConfig.signBtnText }}
        </span>
      </div>
    </button>  
  </div>
</ng-container>
