<div>
  <div mat-dialog-title class="info-dialog__background profile__header" >
    <h4 class="profile__header--text header width__80"
    >Zmiana profilu użytkownika</h4>
    <button
      mat-icon-button
      class="profile__back-btn"
      (click)="onCancel()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div 
    class="profile__container custom-scrollbar" 
  >
    <div class="profile__cards">
      <mat-card 
        class="profile__card" 
        *ngFor="let company of data.companies"
        (click)="onChangeProfile(company?.id)"
      >
        <div  
          *ngIf="company?.logo?.filePath"  
          class="profile__logo-container width__100"
        >
          <img
            [src]="company?.logo?.filePath" 
            class="profile__logo"
            alt="Company logo"
          />
        </div>
        <div 
          *ngIf="!company?.logo?.filePath"
          class="profile__logo-container width__100"
        >
          <div class="profile__logo profile__icon">
            <mat-icon >business</mat-icon>
          </div>
        </div>
        <div class="profile__company-name">
          <span>{{ company?.name }}</span>
        </div>
      </mat-card>
    </div>
    
    <div class="profile__description">
      Wybierz firmę, na którą chcesz się przełączyć
    </div>

</div>

</div>
