import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import SignaturePad from 'signature_pad';
import { RequiredSignature, SignaturePadData, Signatures, SignerData, SignerType } from '../../_models/signature.model';
import { SignaturePadConfiguration } from '../../_models/signaturePadConfig.model';
import { SignatureService } from '../../_services/signature.service';
import { CanvasConfig } from '../canvas/canvas-config.model';
import { ThisPlatformService } from '../../_services/this-platform.service';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})

export class SignaturePadComponent {

  @Input()
  signaturePadConfig: SignaturePadConfiguration;

  @Input()
  signaturesRequired: RequiredSignature[];

  @Output()
  signatureAdd: EventEmitter<SignaturePadData[]> = new EventEmitter<SignaturePadData[]>()

  signaturePad!: SignaturePad;

  signatureData: SignaturePadData[] = [];
  signatures: Signatures[] = [];
  canvasConfig: CanvasConfig = new CanvasConfig();

  signsRequired: boolean = true;

  constructor(
    private translate: TranslateService,
    private signatureServ: SignatureService,
    public p: ThisPlatformService,
  ) { }

  ngOnInit() {
    this.generateSignatureArray();
  }

  /** Method to find required signature to display */
  getSignaturePad(event: SignaturePad, type: SignerType) {
    this.signaturePad = event;
    this.signatures.find(sign => {
      if(sign.signerType == type) {
        sign.signaturePad = event;
      }
    });
  }

  /** Method to find required signature type for signer of drawed signature */
  getPersonalData(person: FormGroup, type: SignerType) {
    this.signatures.find(sign => {
      if(sign.signerType == type) {
        sign.signer = person.value;
      }
    });
  }

  /**
   * Method to save and set flag of required signatures 
   * @param _signsRequired flag if signs are required
   */
  onClickSave(_signsRequired: boolean) {
    this.signsRequired = _signsRequired;
    this.saveSignatures();
  }

  /** Method for validate and create drawed signatures to be saved */
  saveSignatures() {
    if (!this.validateSignatures()) {
      return
    }

    // to avoid duplicate signatures if against error saving
    this.signatureData = [];

    // create data from draw signatures
    this.signatures.forEach(signature => {
      let signImage: File;
      let signer: SignerData = new SignerData();

      const fileName = (signature.signerType + this.signaturePadConfig.publicFileName);

      if(this.signsRequired) {
        const base64Data = this.signaturePad.toDataURL().split(',')[1];
        const blob = this.getBlob(base64Data, 'image/png');
  
        signImage = this.generateSignImage(blob, fileName);
        signer = signature.signer;

      } else {
        signImage = this.generateSignImage(null, fileName);
        signer = {
          personalData: signature?.signer?.personalData ?? 'BRAK PODPISU',
          signerType: signature.signerType
        };
      }

      this.signatureData.push({
        signer: signer,
        signature: signImage
      });
    });

    this.signatureAdd.emit(this.signatureData);
  }

  toUppercase(text: string) {
    return this.translate.instant(text.toUpperCase());
  }

  private generateSignatureArray() {
    this.signaturesRequired?.forEach(required => {
      this.signatures.push({
        signerType: required.signerType
      });
    });
  }

  private validateSignatures() {
    let validate = true;

    if (!this.signsRequired) {
      validate = this.emitValidation(true);
      return validate;
    }

    this.signaturesRequired.some(required => {
      if (!(this.signatures.some(sign => sign.signerType == required.signerType))) {
        validate = this.emitValidation(false);
      }
    });

    this.signatures.forEach(sign => {
      if (sign?.signer?.personalData?.length < 1) {
        validate = this.emitValidation(false);
      }

      if (sign?.signaturePad?.isEmpty()) {
        validate = this.emitValidation(false);
      }

      if (!sign?.signaturePad || !sign.signer) {
        validate = this.emitValidation(false);
      }
    });

    return validate;
  }

  /** Helper method for emitting validation needed for signature pads */
  private emitValidation(validate: boolean) {
    this.signatureServ.validation$.next(validate);
    return validate;
  }

  /**
   * Method to generate sign image 
   * @param blob blob of file witch will be store
   * @param fileName name of file witch will be store 
   * @returns new file to store
   */
  private generateSignImage(blob: Blob, fileName: string): File {
    if(blob) {
      return new File(
        [blob],
        fileName.replace(' ', '_'),
        { type: 'image/png' }
      );
    } else {
      return null;
    }
  }

  private getBlob(b64Data: string, contentType: string, sliceSize: number = 512) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  ngOnDestroy() { }

}
