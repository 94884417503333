import { AttachmentTables } from "../../_models/attachment-create.model";
import { AttachmentTypeCompany } from "../../company/company.model";
import { AttachmentTypeOrder, AttachmentTypeTask } from "./attachment-type.enum";

export class AttachmentSettings {
  multiple: boolean;
  objectGroupName: string;
  uploadButtonText = 'Dodaj załącznik';
  uploadDescription = '';
  fileTypeAccept = 'application/pdf';
  readonly: boolean = false;
  removeOption: boolean = false;
  inputId: string = 'files';
  type: AttachmentFileType = null;
  showButton?: boolean = true;
  autoAssign?: boolean = false;
  tableName?: AttachmentTables;
  idTableObject?: number;
  signature?: boolean = false;
  dateDisplay?: boolean = false;
  canDelete?: boolean = false;
  // disabled need for correct managing button action displaying deepends of status
  disabled?: boolean = false;
  showAdditionalData?: boolean = false;
}

export class AttachmentFileType {
  attachmentType: AttachmentTypeOrder | AttachmentTypeCompany | AttachmentTypeTask = null;
  filter: boolean;
}
