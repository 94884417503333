<div
  class="avatar"
  [ngClass]="big ? 'avatar--big' : 'avatar--small'"
  [style.background]="backgroundColor"
>
  <label *ngIf="((!personId != user?.id) && !person) || user?.username === 'admin'" >
    {{ (user?.username === "citimedia" || user?.username === "operator_admin" || user?.username === "admin")
    ? "SA"
    : ( user?.firstName ? user?.firstName[0] : "" ) + ( user?.lastName ? user?.lastName[0] : "" ) }}
  </label>
  <label *ngIf="personId && personId != user?.id && person"
    [matTooltip]="person.email ? person.email : ' '"
    [matTooltipPosition]="'after'"
  >
    {{ person.firstName ? person.firstName[0] : "" }}{{ person.lastName ? person.lastName[0] : "" }}
  </label>
</div>
