<div
  *ngIf="isMobile()"
  class="container__mobile-header"
>
  <div class="buttons__space-between">
      <h5 class="container__mobile-header--text">
        Filtry
      </h5>

      <button
        class="btn btn-sm mobile-btn-close"
        (click)="onCloseFilterDialog()"
      >
        <mat-icon>close</mat-icon>
      </button>
  </div>
</div>

<div class="filter">
  <hr class="m-0" />
  <div 
    class="container__main"
    [ngClass]="{
      'container__main--mobile' : isMobile(),
    }"
  >
    <div [ngClass]="!isMobile() ? 'container__left-buttons' : 'container__center-buttons custom-scrollbar'">
      <ng-container *ngFor="let button of buttons">
        <form 
          [formGroup]="button.form" 
          [ngClass]="{ 'more-btns' : (buttons.length > 7) }
        ">
          <ng-container *ngIf="(button.buttonType == buttonType.SingleSelection)">
            <div
              *appHasRole="button.permission"
              class="container__filter-button"
              [ngClass]="{'width__100 container__filter-button--mobile' : isMobile()}"
            >
              <mat-form-field [appearance]="formFieldAppearance()" *ngIf="button.optionList.length>0">
                <mat-label>{{ button.label }}</mat-label>
                <mat-select
                  disableOptionCentering
                  (selectionChange)="onChange(
                    $event, 
                    button.form, 
                    button.formControlName, 
                    clickAction.Change
                  )"
                  formControlName="{{button.formControlName}}"
                >
                  <mat-option
                    *ngFor="let option of button.optionList"
                    [value]="option?.value"
                    >{{ option?.displayName | translate }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container *ngIf="(button.buttonType == buttonType.MultipleSelection)">
            <div
              *appHasRole="button.permission"
              class="container__filter-button"
              [ngClass]="{'width__100 container__filter-button--mobile' : isMobile()}"
            >
              <mat-form-field [appearance]="formFieldAppearance()" *ngIf="button.optionList.length>0">
                <mat-label>{{ button.label }}</mat-label>
                <mat-select
                  (selectionChange)="onChange(
                    $event, 
                    button.form, 
                    button.formControlName, 
                    clickAction.Change
                  )"
                  multiple
                  disableOptionCentering
                  formControlName="{{button.formControlName}}"
                  [compareWith]="compareWithFn"
                >
                  <mat-select-trigger>
                    <span *ngIf="button.form.controls[button.formControlName].value?.length == 1">
                      {{ button.form.controls[button.formControlName].value[0].displayName }}
                    </span>
                    <span *ngIf="button.form.controls[button.formControlName].value?.length > 1">
                      {{ "Wybrano: " }} {{ button.form.controls[button.formControlName].value.length }}
                    </span>
                  </mat-select-trigger>
                  <mat-option
                    *ngFor="let option of button.optionList"
                    [value]="option"
                    >{{ option?.displayName }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container *ngIf="(button.buttonType == buttonType.AutocompleteSelection)">
            <div
              *appHasRole="button.permission"
              class="container__filter-button"
              [ngClass]="{'width__100 container__filter-button--mobile' : isMobile()}"
            >
              <mat-form-field [appearance]="formFieldAppearance()">
                <mat-label>{{ button.label }}</mat-label>
                <input
                  type="text"
                  placeholder="{{ button.placeholder}}"
                  aria-label="Number"
                  matInput
                  [matAutocomplete]="auto"
                  formControlName="{{button.formControlName}}"
                  (focus)="filterValues(null)"
                  (input)="filterValues(myInput.value)"
                  #myInput 
                />
                <mat-icon
                  *ngIf="button.form.controls[button.formControlName].value"
                  matSuffix
                  class="input-clear pointer"
                  (click)="onChange(
                    $event, 
                    button.form, 
                    button.formControlName, 
                    clickAction.Change, 
                    true
                  )"
                  >clear</mat-icon
                >
                <mat-autocomplete
                  #auto="matAutocomplete"
                  [displayWith]="displayFN"
                  (optionSelected)="onChange(
                    $event, 
                    button.form, 
                    button.formControlName, 
                    clickAction.Change
                  )"
                  [panelWidth]="auto"
                >
                  <mat-option
                    *ngFor="let option of button.filteredOptionList | async"
                    [value]="option"
                  >
                  {{ option?.displayName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container *ngIf="(button.buttonType == buttonType.DatePicker)">
            <div
              *appHasRole="button.permission"
              class="container__filter-button "
              [ngClass]="isMobile() 
                ? 'width__100 container__filter-button--mobile' 
                : 'container__filter-button--picker'"
            >
              <mat-form-field [appearance]="formFieldAppearance()">
                <mat-label>{{ button.label }}</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  placeholder="{{ button.placeholder }}"
                  (dateChange)="onChange(
                    $event, 
                    button.form, 
                    button.formControlName, 
                    clickAction.Change
                  )"
                  class="default-cursor"
                  [min]="(button.formControlName == 'dateTo') ? button.form.controls['dateFrom'].value : null"
                  [max]="(button.formControlName == 'dateFrom') ? button.form.controls['dateTo'].value : null"
                  readonly
                  formControlName="{{button.formControlName}}"
                >
                <mat-icon
                  *ngIf="button.form.controls[button.formControlName]?.value"
                  class="container__reset-button input-clear pointer"
                  matDatepickerToggleIcon 
                  (click)="onChange(
                    $event, 
                    button.form, 
                    button.formControlName,
                    clickAction.Clear
                  )"
                >clear
                </mat-icon>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
          </ng-container>
        </form>

      </ng-container>
    </div>
    <div
      *ngIf="!isMobile()"
      class="container__right-buttons"
    >
      <div class="container__filter-button--reset">
        <button
          class="btn clear-btn secondary-button"
          (click)="onResetFilters()"
        >
          <div class="clear">
            <mat-icon>filter_alt_off</mat-icon>
            <span>Wyczyść filtry</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="isMobile()"
  class="container__mobile-bottom"
>
  <button
    class="btn btn-sm secondary-button width__100 container__filter-button--mobile"
    (click)="onResetFilters()"
  >
    <span>Wyczyść</span>
  </button>
  <button
    class="btn btn-sm primary-button width__100 container__filter-button--mobile"
    (click)="onCloseFilterDialog()"
  >
    <ng-container *ngIf="!filterServ.filtering; else loader">
      Pokaż wyniki {{ (filterServ.filteredCount$ | async) != null ? '(' + (filterServ.filteredCount$ | async) + ')' : ''}} 
    </ng-container>
  </button>
</div>

<ng-template #loader>
  <app-loader [show]="true" [customCss]="'loader-in-btn'"></app-loader>
</ng-template>

