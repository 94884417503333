import SignaturePad from "signature_pad";
import { Attachment } from "./attachment.model";

export class Signature {
  idTableObject: number;
  tableName: SignatureTable;
  signerType: SignerType;
  personalData: string;
  signatureFile: Attachment;
}

export class CreateSignature {
  idTableObject: number;
  tableName: SignatureTable;
  signers: SignerData[] = [];
  files: File[] = [];
}

export enum SignatureTable {
  Attachment = 'attachment'
}

export enum SignerType {
  Executor = 'executor',
  Client = 'client'
}

export class SignerData {
  personalData: string;
  signerType: SignerType;
}

export class RequiredSignature {
  mainType: string;
  detailedType: number;
  signerType: SignerType;
}

export class SignaturePadData {
  signer: SignerData;
  signature: File;
}

export class Signatures {
  signaturePad?: SignaturePad;
  signerType: SignerType;
  signer?: SignerData;
}