import { PriceListProductPerioid } from './price-list-product-perioid.model';
import { PriceListProductProperty } from './price-list-product-property.model';

export class PriceListProduct {
  id: number;
  idProduct: number;

  productPerioids: PriceListProductPerioid[];
  productProperties: PriceListProductProperty[];

  basePriceModify: boolean;
  valorization: boolean;
}
