<a *ngIf="permissionBuildingEdit" class="click" routerLink="/building/{{building.id}}">
  {{ building.name }}
</a>

<span *ngIf="!permissionBuildingEdit">
  {{ building.name }}
</span>

<button *ngIf="permissionTechnicalConditionsViewBuildingDescription && building.description"
  [matTooltip]="building.description" mat-icon-button>
  <mat-icon>contact_support</mat-icon>
</button>
