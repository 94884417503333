<mat-sidenav-container 
  *ngIf="(translateConfigService.initTranslation$ | async)" 
  class="sidenav-container custom-scrollbar"
>
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <div class="nav-block">
      <mat-nav-list>
        <mat-toolbar class="header">
          <img
            class="logo pointer"
            [src]="appInstanceService.getAppAssetsUrl('logo')"
            routerLink="/home"
          />
        </mat-toolbar>

        <div *ngFor="let item of pages" class="menu">
          <mat-list-item
            [hidden]="!userServ.hasRoles([item.permission])"
            [routerLinkActive]="!item.header ? 'active-list-item' : ''"
            [routerLink]="item.url"
            (click)="
              item.module === 5
                ? (userServ.menuShowSubmenuLegal =
                    !userServ.menuShowSubmenuLegal)
                : null
            "
          >
            <div class="menu__icon">
             <img *ngIf="item.iconSrc" [src]="item.iconSrc" />
            </div>

            <span class="menu__text">
              {{ item.title | translate }}
            </span>
            
            <div
              *ngIf="item.module === 5"
              class="menu__submenu-chevron-container"
            >
              <img
                src="../../assets/icons/menu/chevron-up.svg"
                [ngClass]="{
                  'menu__submenu-chevron--rotate': userServ.menuShowSubmenuLegal
                }"
                alt=""
                class="menu__submenu-chevron"
              />
            </div>
            <div
              *ngIf="item.description"
              #tooltip="matTooltip"
              matTooltip="{{ item.description | translate }}"
              matTooltipPosition="right"
              aria-tooltip="Module tooltip"
            >
              <mat-icon class="pointer info-icon">info_outline</mat-icon>
            </div>
          </mat-list-item>

          <div
            *ngIf="item.submenu"
            class="submenu"
            [ngClass]="{ expanded: userServ.menuShowSubmenuLegal }"
          >
            <mat-list-item
              *ngFor="let sb of item.submenu"
              [routerLink]="sb.url"
              routerLinkActive="active-list-item"
            >
              <span class="menu__text">
                {{ sb.title }}
              </span>
            </mat-list-item>
          </div>
        </div>
      </mat-nav-list>

      <mat-nav-list class="link-menu">
        <mat-list-item
          routerLink="/legal/privacy-policy"
          routerLinkActive="active-list-item"
        >
          <span class="menu__text">
            Polityka prywatności
          </span>
        </mat-list-item>
        <mat-list-item
          *appHasRole="['help.button']"
          (click)="onOpenHelpDialog()"
          mat-icon-button
        >
          <span class="menu__text">
            Pomoc
          </span>
        </mat-list-item>
        <div class="footer__divider"></div>
        <div class="footer__container">
          <div class="footer__text">
            <span>© {{ currentYear }} by Space Technology Integrator</span>
          </div>
          <div class="footer__text"><span>v.{{ version }}</span></div>
          <div class="footer__text"><span>All rights reserved.</span></div>
        </div>
      </mat-nav-list>
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="custom-scrollbar">
    <mat-toolbar [ngClass] = "(
      (isHandset$ | async) || (config?.navSection?.length > 0)) ? 
      'justify-content-between' : 
      'justify-content-end'">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <div 
        *ngIf="(config?.navSection && config?.navSection?.length > 0)"
        [ngClass]="(p.isMobile$ | async) ? 'tab-select' : 'tab-nav'"
      >
      <!-- color="warn" potrzebny dla panel operatora -->
        <ng-container *ngIf="!(p.isMobile$ | async)">
          <nav mat-tab-nav-bar>
            <ng-container *ngFor="let section of config.navSection">
              <a *appHasRole="[section.permission]"
                mat-tab-link
                (click)="sectionChange(section)"
                [active]="defaultSelection.name === section.name"
              >
                {{ section.name | translate }} 
              </a>
            </ng-container>
          </nav>
        </ng-container>
        <ng-container *ngIf="(p.isMobile$ | async)">
          <mat-form-field>
            <mat-label>Zakładka</mat-label>
              <mat-select
                (selectionChange)="selectionChange($event)"
                [value]="defaultSelection"
                [compareWith]="compareWithFn"
              >
                <ng-container *ngFor="let section of config.navSection">
                  <mat-option 
                    *appHasRole="[section.permission]"
                    [value]="section"
                  >
                    {{ section.name | translate }}
                  </mat-option>
                </ng-container>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </div>

      <div>
        <app-user-settings></app-user-settings>
      </div>
    </mat-toolbar>

    <div
      class="page-content-container"
      [ngClass]="
      (config?.maxWidth ? 'max-' + config?.maxWidth : '') + ' ' +
      (config?.isStepper ? 'stepper-container' : '') + ' ' +
      ((isHandset$ | async) ? 'page-content-container--mobile' : '')
      "
    >
      <div class="content-content">
        <app-breadcrumb></app-breadcrumb>
        <ng-content></ng-content>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<app-loader 
  [show]="!(translateConfigService.initTranslation$ | async)"
></app-loader>
