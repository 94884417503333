import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LegalService } from '../legal.service';
import { Legal } from '../legal.model';
import { CommentsService } from '../../_components/comments/comments.service';
import { Attachment } from '../../_models/attachment.model';
import { AttachmentSettings } from '../../_components/upload-attachment/attachment-settings';
import { CommentsConfiguration } from '../../_models/comments.config.model';
@Component({
  selector: 'app-legal-details',
  templateUrl: './legal-details.component.html',
  styleUrls: ['./legal-details.component.scss']
})
export class LegalDetailsComponent implements OnInit {

  constructor(
    public legalServ: LegalService,
    private route: ActivatedRoute,
    private router: Router,
    private commentServ: CommentsService
  ) { }

  legalId: number;
  baseNaviage = 'legal/legal';

  attachmentSettings: AttachmentSettings = {
    ...new AttachmentSettings(),
    multiple: true,
    objectGroupName: 'legal'
  };

  commentsConfig: CommentsConfiguration = {
    addButtonEnabled: true,
    isHidden: false,
    tableName: 'legal',
  };

  ngOnInit() {

    this.route.paramMap.subscribe(paramMap => {
      if (!paramMap.has('legalId')) {
        this.router.navigateByUrl(this.baseNaviage);
        return;
      }
      this.legalId = Number(paramMap.get('legalId'));
      if (this.legalId > 0) {

        this.legalServ.getLegal(this.legalId).subscribe((legal: Legal) => {
          this.legalServ.legal = legal;
        });
      } else {
        this.legalServ.legal = new Legal();
      }
    });

  }


  Save() {
    if (this.legalId === 0) {
      this.legalServ.legal.id = this.legalServ.legals.length + 1;
      this.legalServ.legal.dateCreated = new Date();
      this.legalServ.legal.status = 'Nowe';
      this.legalServ.postLegal().subscribe(() => {
        this.router.navigateByUrl(this.baseNaviage);
      });
    } else {
      // this.legalServ.legals[this.legalId - 1] = this.legalServ.legal;
      this.updateLegal();
    }
  }
  Cancel() {
    this.router.navigateByUrl(this.baseNaviage);
  }


  updateLegal() {
    this.legalServ.updateLegal().subscribe(() => {
      this.router.navigateByUrl(this.baseNaviage);
    });
  }

  onClickPricing() {
    this.legalServ.legal.status = 'Wycena';
    this.updateLegal();
  }

  onClickSend() {
    this.legalServ.legal.status = 'W realizacji';
    this.updateLegal();
  }

  imageAdded(attachment: Attachment) {
  }

}
