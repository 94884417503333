export enum BuildingPerm {
  MANUAL_PRICE_VERIFICATION = 'building.manual_price_verification',
  REMOTE_ASSITANCE = 'building.remote_assistance',
  MANAGE_COLLOCATION = 'building.manage_collocation',
  NOTIFY = 'building.notify',
  MANGE_DEFAULT_BUILDING = 'building.manage_default_building',
}

export enum CompanyPerm {
  /** add companies */
  ADD = 'company.add',
  /** edit companies */
  EDIT = 'company.edit',
  /** edit own company */
  EDIT_OWN_COMPANY = 'company.edit-only-my-company',
  /** edit CRM sync properties*/
  SYNC_CRM_EDIT = 'companies.id-sync-crm.edit',
  /** can view assigned warehouses to company*/
  WAREHOUSE_VIEW = 'company.warehouse_view',
  /** can add/edit assignes of companies' warehouse */
  WAREHOUSE_EDIT = 'company.warehouse_edit',
  /** can manage default user of company */
  DEFAULT_USER = 'company.set_default_user',
  /** can manage default warehouse of company */
  DEFAULT_WAREHOUSE = 'company.set_default_warehouse',
  /** can see submenu section of companies */
  SUB_MENU_COMPANY = 'company.section-company',
  /** can see submenu section of operators */
  SUB_MENU_OPERATOR = 'company.section-operator',
  /** can see submenu section of services */
  SUB_MENU_SERVICE = 'company.section-service',
  /** can see submneu section of tenants */
  SUB_MENU_TENANT = 'company.section-tenant',
  /** can manage (e.g load) companyies of type company */
  TYPE_COMPANY = 'company.type-company',
  /** can manage (e.g load) companyies of type operator */
  TYPE_OPERATOR = 'company.type-operator',
  /** can manage (e.g load) companyies of type service */
  TYPE_SERVICE = 'company.type-service',
  /** can manage (e.g load) companyies of type tenant */
  TYPE_TENANT = 'company.type-tenant'
}

export enum DocumentPerm {
  /** show all companies */
  ALL_COMPANY = 'document.company_all',
  /** create new document */
  ADD = 'document.create',
  /** read document */
  READ = 'document.read',
  /** update document */
  EDIT = 'document.update',
  /** delete document */
  DELETE = 'document.can_delete',
  /** alert about missing acceptation of documents */
  ALERT = 'document.acceptation-alert',
  /** can accept document */
  ACCEPT = 'document.can_accept',
  /** show menu  */
  MENU = 'menu.document',
  /** manage always by admin */
  MANAGE_ADMIN = 'document.manage_admin'
}

export enum TaskPerm {
  /** show menu  */
  MENU = 'menu.task',
  /** can add task */
  ADD = 'task.can_add',
  /** can update task */
  EDIT = 'task.can_update',
  /** can delete task */
  DELETE = 'task.can_delete',
  /** can read all tasks */
  READ_ALL = 'task.read_all',
  /** can read own tasks */
  READ_OWN = 'task.read_own',
  /** can add time */
  TIME_ADD = 'task.time_can_add',
  /** can add time for different users */
  TIME_ADD_FOR_ALL = 'task.time_can_add_all',
  /** can delete time */
  TIME_DELETE = 'task.time_can_delete',
  /** can change executor of task */
  CHANGE_EXECUTOR = 'task.change-executor',
  /** send notification */
  SEND_NOTIFICATION = 'task.send_notification',
  /** can add simple task - send equipment to warehouse */
  SEND_EQUIPMENT = 'task.can_add_equipment_send',
  /** can simple manage status of task */
  STATUS_MANAGE = 'task.status_manage',
}

export enum UserPerm {
  /** can view companies */
  VIEW_COMPANY = 'user.view_company',
  /** select company during user adding */
  USER_ADD_SELECT_COMPANY = 'user.add_select_company',
}

export enum TechnicalInformationPerm {
  /** can view technical information */ 
  VIEW = 'technical-information.view',
  /** can add edit technical information */
  EDIT = 'technical-information.edit',
}

export enum WarehousePerm {
  /** can filter warehouse by company */
  FILTER_COMPANY = 'warehouse.filter_company',
  /** can export warhouse stock to csv file */
  EXPORT_CSV = 'warehouse.export_csv'
}