import { User } from '../../user/user.model';
import { TaskCommentType } from '../../task/enums/task-comment.enum';

export class Comment {
  id: number;
  content: string = '';
  createdAt: Date;
  userId: number;
  user: User;
  tableName: string;
  idTableObject: number;

  //W przypadku potrzeby dodania kolejnego typu do innej tabelki dodać or: OrderCommentType | xyz
  type: TaskCommentType;
}

export class CommentsIdentifier {
  tableName: string; 
  idTableObject: number;
  type?: TaskCommentType;
}
