import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThisPlatformService } from '../../../_services/this-platform.service';

@Component({
  selector: 'app-bottom-sheet-btn',
  templateUrl: './bottom-sheet-btn.component.html',
  styleUrls: ['./bottom-sheet-btn.component.scss']
})

export class BottomSheetBtnComponent {

  @Input()
  isMenuOpen: boolean = false;

  @Input()
  iconName: string = 'menu';

  @Output()
  openMenu = new EventEmitter<boolean>()

  constructor(
    public p: ThisPlatformService,
  ) { }

  ngOnInit() { }

  openBottomSheet() {
    this.openMenu.emit(true);
  }
}
