import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ConfirmDialogComponent } from "./_components/confirm-dialog/confirm-dialog.component";
import { MessageDialogComponent } from "./_components/message-dialog/message-dialog.component";
import { ProductComponent } from "./_components/product/product.component";
import { ComponentsModule } from "./_components/components.module";
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LayoutModule } from "@angular/cdk/layout";
import { JwtInterceptor } from "./_helpers/jwt.interceptor";
import { NgxMatNativeDateModule } from "@angular-material-components/datetime-picker";
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material/core";
import { DatePipe } from "@angular/common";
import { BuildingCompanyComponent } from "./building/building-company/building-company.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BuildingVerticalComponent } from "./building/building-vertical/building-vertical.component";
import { BuildingLocationComponent } from "./building/building-location/building-location.component";
import { HelpComponent } from "./help/help.component";
import { AddEditPriceListAssignmentComponent } from "./price-list/add-edit-price-list-assignment/add-edit-price-list-assignment.component";
import { MatChipsModule } from "@angular/material/chips";
import { MatSelectModule } from "@angular/material/select";
import { SwiperModule } from "swiper/angular";
import { BreadcrumbModule } from "xng-breadcrumb";
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PipesModule } from "./_pipes/pipes.module";
import { DisabledComponent } from './disabled/disabled.component';
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ArticleComponent } from './article/article.component';
import { RouterModule } from "@angular/router";
import { AuthModule, authRoutes } from "@panel/auth";
import { environment } from "../environments/environment";

@NgModule({
    declarations: [
        AppComponent,
        BuildingCompanyComponent,
        BuildingVerticalComponent,
        BuildingLocationComponent,
        HelpComponent,
        AddEditPriceListAssignmentComponent,
        ArticleComponent,
    ],
    entryComponents: [
        ConfirmDialogComponent,
        MessageDialogComponent,
        ProductComponent,
        BuildingCompanyComponent,
        BuildingVerticalComponent,
        BuildingLocationComponent,
        AddEditPriceListAssignmentComponent,
    ],
    exports: [],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: MAT_DATE_LOCALE, useValue: "pl-PL" },
        DatePipe,
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        LayoutModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ComponentsModule,
        AppRoutingModule,
        MatNativeDateModule,
        NgxMatNativeDateModule,
        FormsModule,
        ReactiveFormsModule,
        MatChipsModule,
        MatSelectModule,
        SwiperModule,
        BreadcrumbModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        PipesModule,
        ScrollingModule,
        RouterModule.forRoot([{path: 'auth', children: authRoutes}]),
        AuthModule.forRoot(environment)
    ]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/lang/', '.json');
}
