<div
  class="paginator__container" 
  [ngClass]="{'paginator__desktop' : !(p.isMobile$ | async)}"
>
  <hr/>
  <mat-paginator #paginator
    [ngClass]="{'paginator__mobile' : ((p.isMobile$ | async) && paginatorConfig.hidePageSize) }"
    (page)="handlePageEvent($event)"
    [length]="paginatorConfig.length"
    [pageSize]="paginatorConfig.pageSize"
    [disabled]="paginatorConfig.disabled"
    [showFirstLastButtons]="paginatorConfig.showFirstLastButtons"
    [pageSizeOptions]="paginatorConfig.showPageSizeOptions ? paginatorConfig.pageSizeOptions : []"
    [hidePageSize]="paginatorConfig.hidePageSize"
    [pageIndex]="paginatorConfig.pageIndex"
    aria-label="Wybierz stronę">
  </mat-paginator>
</div>

