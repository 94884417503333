import { Inject, Injectable } from '@angular/core';
import { EnvModel } from '@panel/models';

@Injectable({
  providedIn: 'root'
})

export class AppInstanceService {

  constructor(
    @Inject('env') private environment: EnvModel
  ) { }

  getAppName() {
    let host = window.location.host.split(':')[0];

    switch (host) {
      case 'panel.citimedia.pl':
        return 'citimedia';
      case 'demo-panel.citimedia.pl':
      case 'panel-demo.my-building.net':
      case 'panel-operator-web-stage.azurewebsites.net':
        return 'demo';
      case 'panel.my-building.net':
        return 'panel';
      case 'orange-panel.citimedia.pl':
        return 'demo_orange';
      case 'panel.space-tech.it':
      case 'panel-crm-web-prod.azurewebsites.net':
        return 'panelcrm';
      case 'panel.snt.net.pl':
        return 'PanelCRM'
      case 'myfm.it':
        return 'myfm';
      case 'localhost':
        return this.environment.localInstance;  
    }

  }

  getAppAssetsUrl(assetName: string) {
    const filesUrl = this.environment.filesUrl;
    let fileName: string = '';

    switch(assetName) {
      case 'logo':
        fileName = 'logo.png';
        break;
    }

    if (!fileName) {
      return '';
    }

    return `${filesUrl}${this.getAppName()}/${fileName}`;
  }
}
