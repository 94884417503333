import { AuthenticationService } from '@panel/auth';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Modules } from '../_helpers/module';
import { Page } from '../_models/page.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthenticationService,
  ) { }

  currentModule: Page;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const currentUser = this.authService.currentUserValue;

    this.currentModule = Modules.filter((p) => p.url === state.url)[0];
    
    if (currentUser) {

      if (route.data.roles && route.data.roles.indexOf(currentUser.role.name) === -1) {
        this.router.navigate(['/']);
        return false;
      }

      if(currentUser?.disabledModules) {
        if(!(currentUser?.disabledModules?.filter(
          (m) => m === this.currentModule?.module
        ).length === 0)
        ) {
          this.router.navigate(['/disabled' + this.currentModule.url]);
          return false
        }
      } else {
        this.router.navigate(['/']);
        return true;
      }
      
      return true;
    }
    else
    {
      this.authService.urlRefTo = state.url;
    }

    this.authService.logout();
    this.router.navigate(['/auth']);
    return false;
  }

}
