<h1 
  *ngIf="title" 
  mat-dialog-title
  [ngClass]="{ 
    'success-dialog__background' : (confirmDialogType == 'success'),
    'info-dialog__background' : (confirmDialogType == 'info'),
    'danger-dialog__background' : (confirmDialogType == 'danger')
  }"
>
  {{title}}
</h1>

<!-- color="warn" potrzebny dla panel operatora -->
<div class="dialog-content custom-scrollbar">
  <div *ngFor="let item of consentsRequired" class="info-text">
    <mat-checkbox *ngIf="item.type == consentType.Checkbox" required  [formControl]="item.fModel" [(ngModel)]="item.value">
      <span *ngIf="item.text" [innerHTML]="item.text"></span>
      <a *ngIf="item.url" routerLink="" (click)="linkClick(item.url)">{{item.textUrl}}</a>
    </mat-checkbox>
  
    <span *ngIf="item.type == consentType.Text && item.text" [innerHTML]="item.text"></span>
  
    <p>
      <mat-error *ngIf="!item.value && item.required && this.error">{{item.errorText}}</mat-error>
    </p>
  </div>
  
  <div *ngIf="message" mat-dialog-content custom-scrollbar class="message-container">
    <p [innerHTML]="message"></p>
  </div>
    <hr>
  <div 
    class="buttons-container" 
    [ngClass]="{'buttons-container--center' : !showNoButton}"
  >
    <button *ngIf="showNoButton" class="btn secondary-button-outline" (click)="onDismiss()" tabindex='1'>{{ noButtonText }}</button>
    <button 
      class="btn primary-button" 
      [ngClass]="{ 
        'success-dialog__button' : (confirmDialogType == 'success'),
        'info-dialog__button' : (confirmDialogType == 'info'),
        'danger-dialog__button' : (confirmDialogType == 'danger')
      }"
      (click)="onConfirm()" 
      tabindex='-1'
    >
    {{ yesButtonText }}</button>
  </div>
</div>
