
<div class="help-container">
  <div class="header">
    <h4>
      Kontakt
    </h4>
  </div>

  <app-loader
    [show]="loading"
  ></app-loader>
  
  <div *ngIf="!loading && config.value" [innerHTML]="config.value | sanitizeHtml"></div>
</div> 
