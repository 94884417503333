/**
 * Pagination config parameters
 * @param length count of items in array
 * @param pageSize count of displaying items per page
 * @param pageIndex number of currently displaying page
 * @param disabled boolean to disable pagination interaction | (default: false)
 * @param hidePageSize boolean to hide page size select option | (default: false)
 * @param showPageSizeOptions boolean to show page size options select option | (default: true)
 * @param pageSizeOptions array of page size number options | (default: [5, 10, 25])
 * @param showFirstLastButtons boolean to show navigation between first and last page | (default: true)
 */
export class TablePaginatorParameters {
  length: number;
  pageSize: number = 50;
  pageIndex: number = 0;
  disabled?: boolean = false;
  hidePageSize?: boolean = true;
  showPageSizeOptions?: boolean = true;
  pageSizeOptions?: number[] = [25, 50, 100];
  showFirstLastButtons?: boolean = true;
  defaultPageIndex?: number = 0; 
}