import { Component, Inject, Input } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { BottomSheetButtons } from './bottom-sheet-menu.model';

@Component({
  selector: 'app-bottom-sheet-menu',
  templateUrl: './bottom-sheet-menu.component.html',
  styleUrls: ['./bottom-sheet-menu.component.scss']
})
export class BottomSheetMenuComponent {
  
  constructor(
    private bottomSheetRef: MatBottomSheetRef<BottomSheetMenuComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) 
    public data: BottomSheetButtons[],
  ) { }

  ngOnInit() { }

  onClick(event: Event, action?: string) {
    this.bottomSheetRef.dismiss(action);
    event.preventDefault();
  }
}
