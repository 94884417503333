import { Inject, Injectable } from "@angular/core";
import { HttpBackend, HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { map, tap } from 'rxjs/operators';
import { Router } from "@angular/router";
import { EnvModel, SessionSettingsAction } from "@panel/models";
import { ChatbotService, Utils } from "@panel/helpers";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  public http: HttpClient;
  public urlRefToDefault = '/home';
  public urlRefTo = '';

  constructor(
    public handler: HttpBackend,
    public router: Router,
    @Inject('env') public environment: EnvModel,
    private chatbotService: ChatbotService
  ) {
    this.http = new HttpClient(handler);
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("currentUser")!)
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  setDefaultRef(url: string) {
    this.urlRefToDefault = url;
  }

  setUrlFromDefaultRef() {
    this.urlRefTo = this.urlRefToDefault;
  }

  login(login: string, password: string, appname: string) {
    return this.http
      .post<any>(`${this.environment.api}auth/login`, {
        login,
        password,
        appName: appname,
        refesh: 1
      })
      .pipe(
        map((user) => {
          if (user && user.token) {
            localStorage.setItem("currentUser", JSON.stringify(user));
            this.storeAccessToken(user.token);
            this.storeRefreshToken(user.refreshToken);
            Utils.manageSessionSetting(SessionSettingsAction.SET);
            this.currentUserSubject.next(user);
          }
          return user;
        })
      );
  }

  getUrlRefTo() {
    return this.urlRefTo ? this.urlRefTo : this.urlRefToDefault;
  }

  logout() {
    this.chatbotService.destroyChatbot();
    
    const refreshToken = this.getRefreshToken();

    if(refreshToken) {
      localStorage.removeItem("currentUser");
      this.currentUserSubject.next(null);
      this.removeTokens();

      this.http.post<any>(`${this.environment.api}auth/logout`, {
          refreshToken: refreshToken,
        }).subscribe(() => {
            this.router.navigate(["/auth"], { replaceUrl: true });
          },
          (err) => {}
        );
    } else {
      localStorage.removeItem("currentUser");
      sessionStorage.clear();
      this.currentUserSubject.next(null);
      this.router.navigate(["/auth"], { replaceUrl: true });
    }
  }

  refreshToken() {
    let refreshToken = this.getRefreshToken();
    return this.http
      .post<any>(`${this.environment.api}auth/refresh`, {
        refreshToken: refreshToken,
      })
      .pipe(
        tap(({ accessToken }) => {
          this.storeAccessToken(accessToken);
        })
      );
  }

  getAccessToken() {
    return localStorage.getItem("TOKEN");
  }

  setNewTokens(token: string, refreshToken: string) {
    this.storeAccessToken(token);
    this.storeRefreshToken(refreshToken);
  }

  private storeAccessToken(token: string) {
    localStorage.setItem("TOKEN", token);
  }

  private storeRefreshToken(refreshToken: string) {
    localStorage.setItem("REFRESH_TOKEN", refreshToken);
  }

  private getRefreshToken() {
    return localStorage.getItem("REFRESH_TOKEN");
  }

  private removeTokens() {
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("REFRESH_TOKEN");
    sessionStorage.clear();
    window.location.reload();
  }
}
