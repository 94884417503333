<div class="scrollable-view custom-scrollbar">
    <pdf-viewer *ngIf="file.fileType == 'application/pdf'"
        class="d-block"
        [src]="file.url" 
        [render-text]="true"
        [original-size]="false"
        [autoresize]="true"
        [zoom]="1"
        [zoom-scale]="'page-width'"
    >
    </pdf-viewer>
</div>


