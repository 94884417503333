import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BuildingCompany } from './building-company.model';
import { BuildingCompanyMany } from './building-company-many.model';

const url = environment.api;

@Injectable({
  providedIn: 'root'
})
export class BuildingCompanyService {
  constructor(
    private http: HttpClient,
  ) { }

  getBuildingCompany(id: string) {
    return this.http.get<BuildingCompany>(url + 'building-company/' + id);
  }

  create(buildingCompany: BuildingCompanyMany) {
    return this.http.post(url + 'building-company', buildingCompany);
  }

  update(buildingCompany: BuildingCompany) {
    return this.http.put(url + 'building-company/' + buildingCompany.id, buildingCompany);
  }

  delete(buildingCompany: BuildingCompany) {
    return this.http.delete(url + 'building-company/' + buildingCompany.id);
  }
}
