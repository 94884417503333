import { DictionaryValues, DictionaryValueSimple } from '../dictionary/dictionary.model';

export class ProductProperty {
  id: number;
  name: string; // Słownik / lista zamknięta
  placeholder?: string;
  quantity?: any;
  itemKey?: any;
  type: ProductPropertyType;
  typeOfCharge?: TypeOfCharge = TypeOfCharge.Monthly;
  availableValues?: string[];
  defaultQuantity: number;
  itemKeyType: string;
  priceListDefined: boolean;
  attachmentRequired: boolean;
  values?: DictionaryValues[];
  attachment?: any[] = [];
  attachFromIdProductProperty: number;
  itemKeyUnit: string;
  specialities: DictionaryValueSimple[];
  nameSimple: string;
}

export enum ProductPropertySpecialityType {
  RequiredBlockDocumentationNumber = 1
}

export enum ProductPropertyType {
  bit = 'bit',
  true = 'true',
  int = 'int',
  combobox = 'combobox',
  string = 'string',
}

export enum TypeOfCharge {
  Monthly = 1,
  OneTime = 2,
}
