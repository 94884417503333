import { ConsentRequired } from "./confirm-dialog.model";

export class ConfirmDialogParameters {
  public title?: string;
  public message?: string;
  public consentRequired?: ConsentRequired[];
  public noButtonDisabled?: boolean;
  public noButtonText?: string;
  public yesButtonText?: string;

  yesAction?: () => void;
  noAction?: () => void;

  public maxWidth?: string = "400px";
  public minWidth?: string = "250px";
  public disableClose?: boolean = false;

  public consentConfigKey?: string;
  public consentErrConfigKey?: string;
  public consentTemplateParameters?: any[];
  public consentUrl?: string;
  public confirmDialogType?: ConfirmDialogType;
}

export enum ConfirmDialogType {
  Success = "success",
  Info = "info",
  Danger = "danger",
}
