import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThisPlatformService } from '../../_services/this-platform.service';
import { ProfileChangeData } from './profile-change.model';

@Component({
  selector: 'app-profile-change',
  templateUrl: './profile-change.component.html',
  styleUrls: ['./profile-change.component.scss']
})
export class ProfileChangeComponent {
  
  constructor(
    public dialogRef: MatDialogRef<ProfileChangeComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ProfileChangeData,
    public p: ThisPlatformService,
  ) { }

  ngOnInit() { }

  onChangeProfile(idCompany: number) {
    this.dialogRef.close(idCompany);
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
