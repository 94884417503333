export class SessionSettings {
  homeShowDocs: boolean = true;
  homeShowTask: boolean = true;
  homeTaskKanbanBoard: boolean = true;
  taskKanbanBoard: boolean = false;
  taskMobileList: boolean = false;
}

export enum SessionSettingsAction {
  GET = 'get',
  SET = 'set',
  UPDATE = 'update',
}