import { SelectionChange } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AcceptationConfig, AcceptationStatus } from '../../_models/acceptation.model';

@Component({
  selector: 'app-acceptation',
  templateUrl: './acceptation.component.html',
  styleUrls: ['./acceptation.component.scss']
})
export class AcceptationComponent {

  @Input()
  config: AcceptationConfig = new AcceptationConfig()

  @Output()
  change: EventEmitter<any> = new EventEmitter<any>()

  acceptationStatus = AcceptationStatus;

  constructor( ) { }

  ngOnInit() { }

  ngOnDestroy() { }

}
