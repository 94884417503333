import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Utils } from '../_helpers/utils';
import { AssignedCompaniesUpdate, Company, CompanyBuilding, CompanyQueryParams } from './company.model';
import { CompanyType } from './enum/type.enum';

const url = environment.api;

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  // operator: Operator;
  companies: Company[] = [];
  company: Company;

  companyBuilding: CompanyBuilding[] = [];

  companiesLoaded: boolean = false;


  constructor(
    private http: HttpClient
  ) { }


  getCompanies() {
    return this.http.get<Company[]>(url + 'company');
  }

  // Company type: operator = 1 | tenant = 2
  getCompaniesType(queryParams: CompanyQueryParams) {
    return this.http.get<Company[]>(url + 'company?', {
      params: Utils.httpParams(queryParams)
    });
  }

  loadCompaniesType(type: CompanyType[]) {
    this.getCompaniesType({
      type: type
    }).subscribe((companies: Company[]) => {
      this.companiesLoaded = true;
      this.companies = companies.sort((a,b) => {
        return Utils.compareFn(a.name.toLowerCase(), b.name.toLowerCase());
      });
    });
  }

  getCompaniesTypeBuilding(queryParams: CompanyQueryParams, type?: CompanyType, buildingId?: number) {
    return this.http.get<Company[]>(url + 'company', {
      params: Utils.httpParams(queryParams)
    });
  }

  loadCompaniesTypeBuilding(type: CompanyType, buildingId: number) {
    const query: CompanyQueryParams = {
      type: [type],
      idBuilding: buildingId
    };

    this.getCompaniesTypeBuilding(query).subscribe((companies: Company[]) => {
      this.companiesLoaded = true;
      this.companies = companies;
    });
  }

  getCompaniesByIdBuilding(idBuilding: number) {
    return this.http.get<Company[]>(url + 'company?idBuilding=' + idBuilding);
  }

  getCompany(id: string) {
    return this.http.get<Company>(url + 'company/' + id);
  }

  // createCompany(fileData: any) {
  //   return this.http.post(url + 'company', fileData);
  // }


  createCompany() {
    return this.http.post(url + 'company', this.company);
  }

  updateCompany(formData: FormData) {
    return this.http.patch(url + 'company/' + this.company.id, formData);
  }

  deleteCompany() {
    return this.http.delete(url + 'company/' + this.company.id);
  }

  async loadCompaniesSync(type: CompanyType[]) {
    return this.getCompaniesType({
      type: type
    })
      .toPromise()
      .then(
        (companies) => {
          this.companiesLoaded = true;
          this.companies = companies;
        }
      )
  }

  updateCompanyAssign(assigne: AssignedCompaniesUpdate) {
    return this.http.patch<Company>(url + 'company/assign/' + this.company.id, assigne);
  }

}
