import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HeaderConfig } from './dialog-header.model';

@Component({
  selector: 'app-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss']
})
export class DialogHeaderComponent {

  @Input()
  config: HeaderConfig = new HeaderConfig();

  @Output()
  close: EventEmitter<boolean> = new EventEmitter<boolean>;

  constructor() { }

  ngOnInit() { }

  ngOnDestroy() { }

}
