<!-- main container to manage view -->
<div *ngIf="config; else noData">
  <ng-container 
    [ngTemplateOutlet]="(p.isMobile$ |async) ? mobile : desktop"
  ></ng-container>    
</div>

<!-- Main views -->

<!-- desktop view -->
<ng-template #desktop>
  <app-kanban-toggle
    *ngIf="config?.dekstopToggleConfig"
    [config]="config?.dekstopToggleConfig"
    (onToggle)="onToggle.emit($event)"
  ></app-kanban-toggle>

  <ng-container 
    [ngTemplateOutlet]="config?.dekstopToggleConfig?.value ? kanbanView : tableView"
  ></ng-container> 
</ng-template>

<!-- mobile view -->
<ng-template #mobile>
  <app-kanban-toggle
    *ngIf="config?.mobileToggleConfig"
    [config]="config?.mobileToggleConfig"
    (onToggle)="onToggle.emit($event)"
  ></app-kanban-toggle>

  <ng-container 
    [ngTemplateOutlet]="config?.mobileToggleConfig?.value ? tableView : cardView"
  ></ng-container> 
</ng-template>

<!-- Reusable components -->

<!-- table view component -->
<ng-template #tableView>
  <app-table-display
    class="custom-scrollbar__in-table"
    [ngClass]="{'table-container--pagination-more-filters': config?.tableView?.pagination}" 
    [rows]="config?.rows ?? []" 
    [columnsDisplay]="columnDisplayServ?.[config?.tableView?.displayProperty]" 
    [parameters]="columnDisplayServ?.[config?.tableView?.parametersProperty]"
    [searchText]="config?.tableView?.searchText"
  ></app-table-display>
</ng-template>

<!-- card view component -->
<ng-template #cardView>
  <app-mobile-card-view
    [rows]="config?.rows ?? []"
    [rowsDisplay]="rowDisplayServ?.[config?.cardView?.displayProperty]"
    [parameters]="rowDisplayServ?.[config?.cardView?.parametersProperty]"
  ></app-mobile-card-view>
</ng-template>

<!-- kanban view component -->
<ng-template #kanbanView>
  <ng-container *ngIf="(config?.board | async); else loader">
    <app-kanban-board
      [board]="config?.board ?? []"
      [rowsDisplay]="rowDisplayServ?.[config?.kanbanView?.displayProperty]"
      [parameters]="rowDisplayServ?.[config?.kanbanView?.parametersProperty]"
    ></app-kanban-board>
  </ng-container>
</ng-template>

<!-- no data component -->
<ng-template #noData>
  <div class="no-data-to-show">
    <mat-icon class="icon-display">search_off</mat-icon>
    <p>Brak wyników do wyświetlenia</p>
  </div>
</ng-template>

<!-- loader component -->
<ng-template #loader>
  <app-loader [show]="true"></app-loader>
</ng-template>

