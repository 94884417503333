import { DictionaryValues } from '../dictionary/dictionary.model';
import { PriceListAssignment } from './price-list-assignment.model';
import { PriceListProduct } from './price-list-product.model';

export class PriceList {
  id: number;
  name: string;
  idApp: number;;
  description: string;
  isDefault: boolean;

  idUserCreated: number;
  idUserConfirm: number;
  confirmPriceList: boolean;
  contractType: number;
  daysForVerification: number;
  daysForRealization: number;
  daysTechnicalConditionValidity: number;
  priority: number;
  products: PriceListProduct[] = [];

  assignments: PriceListAssignment[] = [];
}

export enum ContractType {
  /**
   * Oferta kosztowa
   */
  COST_OFFER = 1,
  /**
   * Umowa Ramowa
   */
  BLANKET_ORDER = 2,

}

export class ContractPriceList {
  priceList: PriceList;
  contractType: DictionaryValues;
}
