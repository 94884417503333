import { PriceList } from '../../price-list/price-list.model';
import { Company } from '../../company/company.model';
import { Building } from '../building.model';

export class BuildingCompany {
  id?: number;
  idBuilding: number;
  building?: Building;
  idCompany: number;
  company?: Company;
  companyType?: number;
  collocation: boolean = false;
  bts?: boolean
  notify?: boolean = false;
  default?: boolean = false;
}

export enum BuildingCompanyControl {
  ID = 'ID',
  ID_BUILDING = 'idBuilding',
  BUILDING = 'building',
  ID_COMPANY = 'idCompany',
  COMPANY = 'company',
  COMPANY_TYPE = 'companyType',
  COLLOCATION = 'collocation',
  BTS = 'bts',
  NOTIFY = 'notify',
}
