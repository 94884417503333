import { HttpParams, HttpResponse } from '@angular/common/http';
import { FormGroup, Validators } from '@angular/forms';
import * as fileSaver from 'file-saver';
import { addDays } from 'date-fns';
import { PrivacyPolicyComponent } from '../legal/privacy-policy/privacy-policy.component';
import { ListData } from '../_models/list-data.interface';
import { CompanyType } from '../company/enum/type.enum';
import { UserService } from '../user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, startWith } from 'rxjs/operators';
import { SessionSettings, SessionSettingsAction } from '../_models/session-settings.model';

export class Utils {
  public static countNames = {
    month: {
      one: 'Miesiąc',
      few: 'Miesiące',
      many: 'Miesięcy'
    }
  };

  private static readonly polishCharacters: Record<string, string> = {
    ą: 'a',
    ć: 'c',
    ę: 'e',
    ł: 'l',
    ń: 'n',
    ó: 'o',
    ś: 's',
    ż: 'z',
    ź: 'z',
    Ą: 'A',
    Ć: 'C',
    Ę: 'E',
    Ł: 'L',
    Ń: 'N',
    Ó: 'O',
    Ś: 'S',
    Ż: 'Z',
    Ź: 'Z',
  } as const;

  public static convertPropertyValue(type: string, value: number): any {
    if (type == 'bit') {
      return value ? true : false;
    }

    return value;
  }

  public static getDateFromDate(date) {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
  }

  public static dateAddDays(date, days: number) {
    date.setTime(date.getTime() +  (days * 24 * 60 * 60 * 1000))
  }

  public static dateAddHours(date, hours: number) {
    date.setTime(date.getTime() +  (hours * 60 * 60 * 1000))
  }

  public static getFileNameFromResponseContentDisposition(res: HttpResponse<Blob>) {
    const contentDisposition = res.headers.get('Content-Disposition') || '';

    const matches = /filename=.*\\([^;]+)/ig.exec(contentDisposition);
    const fileName = (matches[1] || 'untitled').trim();
    return fileName;
  };

  public static saveFile(blobResponse: HttpResponse<Blob>, filename) {
    //const filename = Utils.getFileNameFromResponseContentDisposition(blobResponse);
    const blob = new Blob([blobResponse.body], { type: 'application/octet-stream' });
    fileSaver.saveAs(blob, filename);
  };

  public static getFileExtension(filename: string) {
    let arr = filename?.split('.');
    return arr?.length > 0 ? arr.pop() : '';
  }

  public static getCountName(count, objectKey) {
    let object = Utils.countNames[objectKey];

    let name = object.one;

    if (count >= 5) {
      name = object.many;
    } else {
      name = object.few;
    }

    return count + ' ' + name;
  }

  public static emailValidationPattern() {
    return Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$");
  }

  public static httpParams<T>(httpParams: T) {

    let params = new HttpParams();

    Object.entries(httpParams).forEach(([key, value]) => {
      if (value === undefined || value === null ) return;
      
      params = params.set(key, value.toString());
    });

    return params
  }

  public static compareFn(a, b) {
    if(a < b) {
      return -1;
    }
    if(a > b) {
      return 1;
    }
    return 0;
  }

  public static filterWeekendDays(d: Date | null) {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  };

  public static isDayFallsOnWeekend(date: Date) {
    const dayOfWeek = date.getDay(); // get number of day
    const isWeekend = (dayOfWeek === 6) || (dayOfWeek  === 0); // 6 = Saturday, 0 = Sunday
    return isWeekend
  };

  /**
   * Check if date falls on weekend, if true add days to pass weekend day to workday 
   * @param date date to check if it falls on a weekend
   * @returns modified date to falling on work day after passing weekend days
   */
  public static async passWeekend(date: Date) {
    let passDays = 0; // default value for passing days on mindate assistance 
    if(date.getDay() === 0) { passDays = 2 }; // when assitance mindate falls on sunday add 2 days
    if(date.getDay() === 6) { passDays = 1 }; // when assitance mindate falls on saturday add 1 day

    while(Utils.isDayFallsOnWeekend(date)) {
      date = addDays(date, passDays);
    };

    return date;
  }

  public static setCompanyTypes(userServ: UserService) {
    const companyTypes: CompanyType[] = [];

    userServ.hasRoles(['company.type-company']) ? companyTypes.push(CompanyType.Company) : null;
    userServ.hasRoles(['company.type-operator']) ? companyTypes.push(CompanyType.Operator) : null;
    userServ.hasRoles(['company.type-tenant']) ? companyTypes.push(CompanyType.Tenant) : null;
    userServ.hasRoles(['company.type-service']) ? companyTypes.push(CompanyType.Service) : null;

    return companyTypes;
  }

  /**
   * Helper method to void matSnackBar
   * @param snackBar pass service to void matSnackBar
   * @param message pass message to display
   * @param className pass css class e.g. custom-snack-bar__success / custom-snack-bar__error
   */
  public static openSnack(snackBar: MatSnackBar, message: string, className: string) {
    snackBar.open(message, '', {
      duration: 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['custom-snack-bar', className]
    });
  }

  /**
   * Init filters for autocomplete inputs
   * @param form formGroup
   * @param controlName formControlName to check valueChanges
   * @param array default data to filtering
   * @param filterArray filtered out array 
   */
  public static async initFilter(
    form: FormGroup,
    controlName: string,
    array: any[]
  ) {
    return form?.controls[controlName]?.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', array)),
    );
  }

  /**
   * Helper filter for autocomplete inputs
   * @param value introduced value to input
   * @param array default values to filter
   * @returns filtered array
   */
  private static _filter(value: string, array: any[]): string[] | any[] {
    if (value?.length > 0) {
      const filterValue = value?.toLowerCase().replace(' ', '');

      return array?.filter(option => {
        if ((option?.name + option?.code + option?.internalNumber + option?.firstName + option?.lastName + option?.number)
          .toLowerCase()
          .includes(filterValue)
        ) { return option };
      });
    } else {
      return array;
    };
  }

  public static manageSessionSetting(action: SessionSettingsAction, updateSession?: SessionSettings) {
    let sessionSettings: SessionSettings = JSON.parse(localStorage.getItem('sessionSettings'));
    if(!sessionSettings) sessionSettings = new SessionSettings()

    switch (action) {
      case SessionSettingsAction.SET: {
        localStorage.setItem('sessionSettings', JSON.stringify(sessionSettings))
        break;
      };
      case SessionSettingsAction.GET: {
        localStorage.setItem('sessionSettings', JSON.stringify(sessionSettings))
        return sessionSettings;
      };
      case SessionSettingsAction.UPDATE: {
        sessionSettings = updateSession;
        localStorage.setItem('sessionSettings', JSON.stringify(sessionSettings) )
        break;
      };
      default: {
        break;
      }
    }
  }

  public static removePolishCharacters(text: string): string {
    return text.replace(
      /[ąćęłńóśżźĄĆĘŁŃÓŚŻŹ]/g,
      (match) => this.polishCharacters[match] ?? match
    );
  }
}
