import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { AuthenticationService } from '@panel/auth';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private authenticationService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let token = this.authenticationService.getAccessToken();
        if (token) {
            request = this.addToken(request, token);
        }

        return next.handle(request).pipe(catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
              return this.handle401Error(request, next);
          } else {
              return throwError(error);
          }
      }));;
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
      if (!this.isRefreshing) {
          this.isRefreshing = true;
          this.refreshTokenSubject.next(null);

          return this.authenticationService.refreshToken().pipe(
              catchError((error) => {
                  console.warn('Refresh token error', error);
                  this.authenticationService.logout();
                  return throwError(error);
              }),
              switchMap((token: any) => {
                  this.isRefreshing = false;
                  this.refreshTokenSubject.next(token.accessToken);
                  return next.handle(this.addToken(request, token.accessToken));
              }));
      } else {
          return this.refreshTokenSubject.pipe(
              filter(token => token != null),
              take(1),
              switchMap(jwt => {
                  return next.handle(this.addToken(request, jwt));
              }));
      }
    }

    private addToken(request: HttpRequest<any>, token: string) {
      return request.clone({
          setHeaders: {
              'Authorization': `Bearer ${token}`
          }
      });
    }
}