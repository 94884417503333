<mat-nav-list>
  <ng-container *ngFor="let btn of data">
    <a
      *ngIf="btn?.permission"
      class="menu-sheet__divider" 
      mat-list-item 
      (click)="onClick($event, btn.actionName)"
    >
      <span matListItemTitle>{{ btn.displayName }}</span>
      <!-- <span matLine>Add to a note</span> -->
    </a>
  </ng-container>
</mat-nav-list>
