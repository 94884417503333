import { Pipe, PipeTransform } from "@angular/core";
import { DictionaryService } from "../dictionary/dictionary.service";

@Pipe({
  name: "initial"
})
export class InitialPipe implements PipeTransform {
  constructor(
    private dictionaryService: DictionaryService
  ) {
  }

  transform(value: string, ...args: any[]): any {
    let maxlength = args.length > 0 ? args[0] : -1;
    let valuesArr = value.split(' ');
    let output = "";

    for (let i = 0; i < valuesArr.length; i++) {
      let word = valuesArr[i];

      if (output.length == maxlength && maxlength > 0) {
        break;
      }

      output += word.length > 0 ? word[0] : "";
    }

    return output;
  }
}
