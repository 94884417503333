import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { authRoutes } from './lib.routes';
import { TranslateModule } from '@ngx-translate/core';
import { EnvModel } from '@panel/models';

@NgModule({
  declarations: [],
  imports: [
    CommonModule, 
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    RouterModule.forChild(authRoutes),
    TranslateModule
  ],
  exports: []
})

export class AuthModule {
  public static forRoot(environment: EnvModel): ModuleWithProviders<any> {

    return {
      ngModule: AuthModule,
      providers: [
        {
          provide: 'env', // you can also use InjectionToken
          useValue: environment
        }
      ]
    };
  }
}
