import { 
  Component, 
  EventEmitter, 
  Inject, 
  Input, 
  OnInit, 
  Output 
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { FilterService } from './filter.service';
import { Utils } from '../../_helpers/utils';
import { 
  FilterAction, 
  FilterButton, 
  FilterButtonType, 
  FilterOptionValue, 
  onClickAction 
} from './models/filter-button.model';
import { of } from 'rxjs';
import { ThisPlatformService } from '../../_services/this-platform.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FilterConfiguration } from './models/filter-mobile.config';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  /**
   * Filter configuration e.g array of declared buttons for displaying in component
   */
  @Input()
  filterConfig: FilterConfiguration = new FilterConfiguration();

  /**
   * Emitted filter action after request parameters changed
   */
  @Output()
  filterRequest = new EventEmitter<string>();

  buttonType = FilterButtonType;
  clickAction = onClickAction;

  filteredOptionList: Observable<FilterOptionValue[]>;
  filterValue: string = undefined;

  buttons: FilterButton[] = [];

  constructor(
    public filterServ: FilterService,
    public p: ThisPlatformService,
    public dialogRef?: MatDialogRef<FilterComponent>,
    @Inject(MAT_DIALOG_DATA)
    public filterConfigModal?: FilterConfiguration,
  ) { }

  async ngOnInit() {
    if(this.filterConfigModal.displayMobile && this.p.renderMobile) {
      this.buttons = this.filterConfigModal.buttons;
      this.filterConfig.displayMobile = this.filterConfigModal.displayMobile;
    } else {
      this.buttons = this.filterConfig.buttons;
    }
  }

  /**
   * Function to change filter request parameters
   * @param event value of selection 
   * @param form passed formGroup in button configuration
   * @param formControlName passed formControlName in button configuration
   * @param clickAction enum to pass value of emitting filter action [ change | clear ]
   * @param autoComplete boolean to set filtered array back to default options in autocomplete input after cleaning value  
   * @returns 
   */
  onChange(
    event, 
    form: FormGroup, 
    formControlName: string,
    clickAction: onClickAction,
    autoComplete?: boolean
  ) {

    switch(clickAction) {
      case onClickAction.Change: {
        form.patchValue({
          [formControlName]: event?.value ? event?.value : event?.option?.value,
        });

        this.filterRequest.emit(FilterAction.Filter);
        if(this.p.renderMobile) this.filterServ.filterReqeuest$.next(FilterAction.Filter);

        return;
      }
      case onClickAction.Clear: {
        event.stopPropagation();
        form.patchValue({
          [formControlName]: null,
        });

        this.filterRequest.emit(FilterAction.Filter);
        if(this.p.renderMobile) this.filterServ.filterReqeuest$.next(FilterAction.Filter);

        if(autoComplete) {
          this.filterValues(null);
        }

        return;
      }
      default: {
        return;
      }
    }

  }

  /**
   * Function to reset filter request parameters
   */
  onResetFilters() {
    this.filterRequest.emit(FilterAction.Reset);
    if(this.p.renderMobile) this.filterServ.filterReqeuest$.next(FilterAction.Reset);
  }

  /**
   * Helper function for properly displaying name of selected option
   */
  displayFN(
    optionObject?: FilterOptionValue
  ): string | undefined {
    return optionObject ? optionObject.displayName : undefined;
  }


  /**
   * Helper function for properly displaying selected options in multiple selection, 
   * params are taken automatically from mat select option
   * @param listOfItems array of option passed in button configuration
   * @param selectedItem array of selected items 
   * @returns 
   */
  compareWithFn(
    listOfItems: FilterOptionValue, 
    selectedItem: FilterOptionValue
  ) {
    return listOfItems && selectedItem && listOfItems.value === selectedItem.value;
  }

  /**
   * Function for the correct filter working of autocomplete button 
   * @param value passed value from input (passed from input or focus event)
   */
  filterValues(value: string) {
    this.buttons?.filter(btn => {
      if(btn.buttonType == FilterButtonType.AutocompleteSelection) {  
        btn.filteredOptionList = of(this.filterObjects(value, btn.optionList))
      }
    })
  }

  /**
   * Function for filtering values for autocomplete button
   * @param value passed value from input (passed from input or focus event)
   * @param objects array of option values declared in button configuration
   * @returns 
   */
  private filterObjects(
    value: string, 
    objects: FilterOptionValue[]
  ): FilterOptionValue[] {
    if( value?.length > 0 ) {
      this.filterValue = value.toLowerCase();
      return objects.filter(
        b => b.displayName.toLowerCase().includes(this.filterValue))
    } 
    return objects.sort((a,b) => {
      return Utils.compareFn(a.displayName.toLowerCase(), b.displayName.toLowerCase())
    });
  }

  onCloseFilterDialog() {
    this.dialogRef.close(true);
  }

  isMobile() {
    return ((this.p.renderMobile) && this.filterConfig?.displayMobile)
  }

  formFieldAppearance() {
    return this.isMobile() ? '' : 'outline';
  }

  ngOnDestroy() { 
    this.filterServ.resetFilterCount(); // reset filtered count;
  }

}
