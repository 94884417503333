import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DocRelatedTask, DocumentModel, Kind, NewDocument } from '../models/document.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentFilterRequest } from '../../_components/filter/models/filter-request.model';
import { Utils } from '../../_helpers/utils';
import { ListData } from '../../_models/list-data.interface';
import { Relation } from '../models/document-position.model';

const url = environment.api + 'document';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  /** Helper observable for setting menu action button display when alert modal is opened */
  private _alertDisplay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
  ) { }

  create(newDocument: NewDocument): Observable<DocumentModel>{ 
    return this.http.post<DocumentModel>(url, newDocument);
  }

  getAll(): Observable<DocumentModel[]> { 
    return this.http.get<DocumentModel[]>(url);
  }

  getById(id: string): Observable<DocumentModel> {
    return this.http.get<DocumentModel>(url + `/${id}`);
  }

  getFiltered(queryParams: DocumentFilterRequest): Observable<ListData<DocumentModel>> { 
    return this.http.get<ListData<DocumentModel>>(url, {
      params: Utils.httpParams(queryParams)
    });
  }

  getDocsKind(): Observable<Kind[]> {
    return this.http.get<Kind[]>(url + '-kind');
  }

  update(id: string, updatedDocument: NewDocument): Observable<DocumentModel> {
    return this.http.patch<DocumentModel>(url + `/${id}`, updatedDocument);
  }

  /**
   * Helper method for setting observable value
   * @param display boolean to set if alert modal is showe or hide
   */
  setAlertDisplay(display: boolean) {
    this._alertDisplay$.next(display);
  }

  /**
   * Helper method for getting observable value
   * @returns observable value of alert displaying
   */
  getAlertDisplay() {
    return this._alertDisplay$;
  }

  getQuickAccept(): Observable<DocumentModel[]> {
    return this.http.get<DocumentModel[]>(url + '/quick-accept')
  }

  getRelations(idDoc: string): Observable<DocRelatedTask[]> {
    return this.http.get<DocRelatedTask[]>(url + `/${idDoc}/relation`)
  }

  createRelation(idDoc: string, relation: Relation): Observable<DocRelatedTask[]> { 
    return this.http.post<DocRelatedTask[]>(url + `/${idDoc}/relation`, relation);
  }

  delete(idDoc: string): Observable<DocumentModel> { 
    return this.http.delete<DocumentModel>(url + `/${idDoc}`)
  }

}
