import { Building } from '../building/building.model';
import { Company } from '../company/company.model';
import { PriceList } from './price-list.model';

export class PriceListAssignment {
  id: number;
  idCompany: number;
  allBuildings: boolean = false;
  dateFrom: Date;
  dateTo: Date;
  assignmentBuildings: PriceListAssignmentBuilding[] = [];
  company: Company;
  idPriceList: number;
  priceList: PriceList;

  //Only for simple display:
  building: Building;
}

export class PriceListAssignmentBuilding {
  id: number;
  idBuilding: number;
  building: Building;
}
