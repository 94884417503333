import {
  Component, EventEmitter, Input, OnInit, Output
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Utils } from "../../_helpers/utils";
import { Attachment, ProtocolAttachment } from "../../_models/attachment.model";
import { AttachmentService } from "../../_services/attachment.service";
import { AttachmentViever } from "../attachment-viewer/attachment-viewer";
import { AttachmentViewerComponent } from "../attachment-viewer/attachment-viewer.component";
import { ComponentsService } from "../components.service";
import { ConfirmDialogType } from "../confirm-dialog/confirm-dialog-parameters";
import { TableHeadConfiguration } from "../table-display/table-head-tool";
import { AttachmentSettings } from "./attachment-settings";
import { SignProtocolData } from "../../task/models/task.interface";
import { SignProtocolComponent } from "../sign-protocol/sign-protocol.component";
import { ThisPlatformService } from "../../_services/this-platform.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-upload-attachment",
  templateUrl: "./upload-attachment.component.html",
  styleUrls: ["./upload-attachment.component.scss"],
})
export class UploadAttachmentComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private attachmentService: AttachmentService,
    private componentsService: ComponentsService,
    public p: ThisPlatformService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {}

  @Input()
  settings: AttachmentSettings;

  /**
   * Array of Attachment [multiple == true] (multi elements) or Attachment [multiple == false] (max one element)
   */

  @Input()
  attachment: Attachment[] = [];

  @Input()
  tools: TableHeadConfiguration[] = [];

  @Output()
  attachmentAdded: EventEmitter<Attachment> = new EventEmitter<Attachment>();

  @Output()
  attachmentRemoved: EventEmitter<Attachment> = new EventEmitter<Attachment>();

  @Input()
  isModal: boolean = false;

  @Input()
  isStepper: boolean = false;

  @Input()
  isSummaryStepper: boolean = false;

  @Input()
  signProtocolData?: SignProtocolData;

  attachmentFresh = [];
  uploadingLoader = false;
  uploadingLock = false;

  fileChangeListener = (e) => {
    this.onFileChanged(e);
  };

  onFileChanged(event) {
    let file: File = event.target.files[0];

    if (!file || !this.isAccepted(file)) {
      return;
    }

    this.uploadingLock = this.uploadingLoader = true;

    let formData = new FormData();

    formData.append("file", file, Utils.removePolishCharacters(file.name));
    formData.append("objectGroupName", this.settings.objectGroupName);

    if (this.settings.type) {
      formData.append(
        "attachmentType",
        this.settings.type.attachmentType.toString()
      );
    }

    if(this.settings.tableName) {
      formData.append("tableName", this.settings.tableName);
    }

    if(this.settings.idTableObject) {
      formData.append("idTableObject", this.settings.idTableObject.toString());
    }

    if(this.settings.autoAssign) {
      this.createAndAssignAttachment(formData);
    } else {
      this.createAttachment(formData);
    }
    
  }

  private createAttachment(formData: FormData) {
    this.attachmentService.createAttachment(formData).subscribe(
      (res) => {
        this.uploadingLock = this.uploadingLoader = false;
        this.attachment.push(res);
        this.attachmentFresh.push(res);
        this.attachmentAdded.emit(res);
      },
      (error) => {
        this.uploadingLock = this.uploadingLoader = false;
        this.componentsService.ShowErrorMsg(error);
      }
    );
  }

  private createAndAssignAttachment(formData: FormData) {
    this.attachmentService.createAndAssigned(formData).subscribe(
      (res) => {
        this.uploadingLock = this.uploadingLoader = false;
        this.attachment.push(res);
        this.attachmentFresh.push(res);
        this.attachmentAdded.emit(res);
      },
      (error) => {
        this.uploadingLock = this.uploadingLoader = false;
        this.componentsService.ShowErrorMsg(error);
      }
    );
  }

  fileUpload(event) {
    this.uploadingLoader = true;
    this.attachmentService.isAttachmentDeleted = false;

    window.setTimeout(() => {
      if (!this.uploadingLock) this.uploadingLoader = false;
    }, 1500);
  }

  getFileExtensionForImg(fn) {
    let extensions = ["dwg", "pdf", "xls", "xlsx", "doc", "docx", "dwf", "dxf"];
    let extension = Utils.getFileExtension(fn);

    return extensions.find((x) => x == extension) ? extension : "file";
  }

  isAccepted(file: File): boolean {
    if (!this.settings || !this.settings.fileTypeAccept) {
      return true;
    }

    let acceptTypes = this.settings.fileTypeAccept.split(",");
    let extension = Utils.getFileExtension(file.name);

    for (let index in acceptTypes) {
      let type = acceptTypes[index].trim();

      if (type == file.type || (extension && type.includes(extension))) {
        return true;
      }
    }

    return false;
  }

  removeAttachment(item: Attachment) {
    let index = this.attachment.findIndex((x) => x == item);
    if (index >= 0) {
      this.attachment.splice(index, 1);
      this.attachmentRemoved.emit(item);
      this.attachmentService.isAttachmentDeleted = true;
    }
  }

  removeAttachementConfirm(item: Attachment) {
    this.componentsService.ShowDialog(
      "Usunięcie załącznika",
      "Czy na pewno checsz usunąć ten załącznik?",
      () => {
        this.removeAttachment(item);
      },
      ConfirmDialogType.Danger
    );
  }

  async modalPreview(attachment: Attachment) {
    this.attachmentService
      .getAttachment(attachment.key)
      .subscribe((response) => {
        let ct = response.headers.get("Content-Type");
        let blob = new Blob([response.body], { type: ct });
        let url = window.URL.createObjectURL(blob);

        const dialogRef = this.dialog.open(AttachmentViewerComponent, {
          data: {
            fileType: ct,
            url: url,
          },
          height: "90vh",
        });
      });
  }

  async download(attachment: Attachment) {
    this.uploadingLoader = true;

    this.attachmentService.getAttachment(attachment.key).subscribe(
      (response) => {
        this.uploadingLoader = false;
        Utils.saveFile(response, attachment.publicFileName);
      },
      (error) => {
        this.uploadingLoader = false;
        this.componentsService.ShowErrorMsg(error);
      }
    );
  }

  isFresh(attachment: Attachment) {
    return this.attachmentFresh.find((x) => attachment == x);
  }

  isSupported(attachment: Attachment) {
    return AttachmentViever.IsSupportedFileType(attachment.publicFileName);
  }

  filterAttachments() {
    if (this.settings.type && this.settings.type.filter) {
      return this.attachment?.filter(
        (x) => x.attachmentType == this.settings.type.attachmentType
      ).sort( (a,b) => {
        return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
      });
    }

    return this.attachment;
  }

  sign(protocol: ProtocolAttachment) {
    this.signProtocolData.protocol = protocol;

    const dialogRef = this.dialog.open(SignProtocolComponent, {
      maxWidth: '100vw',
      disableClose: true,
      data: this.signProtocolData,
    });

    dialogRef.afterClosed().subscribe( 
      (dialogResult) => {
        if(dialogResult) {
          this.attachmentAdded.emit(dialogResult);
        }
      },
    );
  }

  deleteAttachment(attachment: Attachment) {
    this.componentsService.ShowDialog(
      "Usuwanie załącznika", 
      "Czy na pewno usunąć załącznik?", 
      () => { 
        this.attachmentService.delete(attachment.key).subscribe({
          next: () => { this.attachmentRemoved.emit(attachment) },
          error: () => { Utils.openSnack(this.snackBar,'Błąd podczas usuwania załącznika','custom-snack-bar__error') },
          complete: () => { Utils.openSnack(this.snackBar,"Załącznik usunięto", 'custom-snack-bar__success'); }
        });
      },
      ConfirmDialogType.Danger
    );
  }
}
