import { Injectable } from '@angular/core';
import { FilterRequestParameters } from './models/filter-request.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { FilterAction } from './models/filter-button.model';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  filterRequestParameters: FilterRequestParameters = new FilterRequestParameters;
  filtering: boolean = false;

  filterReqeuest$: Subject<FilterAction> = new Subject<FilterAction>();
  filteredCount$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  today = new Date(
    new Date().setHours(23, 59, 59, 59)
  );

  /** varaible for limiting date from in filters */
  priorDate  = new Date(
    new Date(
      new Date().setDate(
        this.today.getDate() - 60)
      ).setHours(0, 0, 0, 0)
    );

  constructor( ) { }

  /**
   * Function for saving selected parameters of filter request in local storage
   */
  setFiltersParametersToLocalStorage() {
    localStorage.setItem(
      "FILTER_REQUEST_PARAMETERS", 
      JSON.stringify(this.filterRequestParameters)
    );
  }

  /**
   * Function for getting saved selected parameters of filter request in local storage
   * @returns 
   */
  getFiltersParametersFromLocalStorage(): FilterRequestParameters {
    return JSON.parse(localStorage.getItem('FILTER_REQUEST_PARAMETERS'))
  }

  async resetFilterCount() {
    this.filteredCount$ = new BehaviorSubject<number>(null);
  }
 
}
