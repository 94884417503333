<div>
  <h4 mat-dialog-title class="info-dialog__background">{{ data.id ? "Edycja lokalizacji" : "Nowa lokalizacja" }}</h4>

  <app-loader
    [show]="loading"
  ></app-loader>
  
  <div *ngIf="!loading" class="dialog-content custom-scrollbar">
    <mat-form-field>
      <mat-label>Nazwa</mat-label>
      <input
        [(ngModel)]="buildingServ.buildingLocation.name"
        type="text"
        matInput
      />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Piętro</mat-label>
      <input
        [(ngModel)]="buildingServ.buildingLocation.floor"
        type="number"
        matInput
      />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Pion</mat-label>
      <mat-select
        [(ngModel)]="buildingServ.buildingLocation.idBuildingVertical"
        name="idBuildingVertical"
      >
        <mat-option
          *ngFor="let item of buildingServ.buildingVerticals"
          [value]="item.id"
        >
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Typ</mat-label>
      <mat-select [(ngModel)]="buildingServ.buildingLocation.type" name="type">
        <mat-option
          *ngFor="let item of dictionaryServ.buildingLocationType"
          [value]="item.value"
        >
          {{ item.key }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Opis</mat-label>
      <textarea
        rows="4"
        [(ngModel)]="buildingServ.buildingLocation.description"
        type="text"
        matInput
      ></textarea>
    </mat-form-field>

    <div class="width__100 buttons__end">
      <button
        class="btn btn-sm btn-outline-warning button"
        (click)="dialogRef.close(false)"
      >
        Anuluj
      </button>
      <button class="btn btn-sm btn-outline-primary button" (click)="save()">
        {{ data.id ? "Zapisz" : "Dodaj" }}
      </button>
    </div>
  </div>
</div>
