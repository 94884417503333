<div class="info-dialog__background task-sign__header">
  <h4 class="task-sign__header--text header width__80">
    Podpisanie protokołu
  </h4>
  <button
    mat-icon-button
    class="task-sign__back-btn"
    (click)="onDismiss()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="dialog-content custom-scrollbar">
  <div 
    *ngIf="protocolLoaded && !(
      p.platform.BLINK && (p.isMobile$ | async)
    )"
    class="task-sign__protocol">
    <pdf-viewer
      [src]="protocolSrc" 
      [render-text]="true"
      [original-size]="false"
      [autoresize]="true"
      [zoom]="1"
      [zoom-scale]="'page-width'"
    ></pdf-viewer>
  </div>

  <div 
    *ngIf="protocolLoaded"
    class="task-sign__signature"
  >
    <app-signature-pad
      *ngIf="signProtocolData.signaturePadConfig"
      [signaturePadConfig]="signProtocolData.signaturePadConfig"
      [signaturesRequired]="signProtocolData?.protocol?.requiredSignatures"
      (signatureAdd)="addSignature($event)"
    ></app-signature-pad>
  </div>

  <app-loader
    [show]="loading"
  ></app-loader>

  <div *ngIf="!protocolLoaded && !loading">
    Nie odnaleziono protokołu do podpisania, spróbuj wygenerować nowy.
  </div>
 
</div>

<app-overlay-loader
  [show]="signingProtocol"
  [config]="overlayConfig"
></app-overlay-loader>

