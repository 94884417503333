import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateSignature, Signature } from '../_models/signature.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const url = environment.api;

@Injectable({
  providedIn: 'root'
})
export class SignatureService {

  validation$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    private http: HttpClient,
  ) { }

  createSignature(newSignature: any): Observable<Signature> {
    return this.http.post<Signature>(url + 'signature', newSignature);
  }
}
