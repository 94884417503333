import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HelpComponent } from "./help/help.component";
import { AuthGuard } from "./_guards/auth.guard";

const routes: Routes = [
  { 
    path: "", 
    redirectTo: "home", 
    pathMatch: "full", 
    data: {
      breadcrumb: "Strona główna",
    }, 
  },
  {
    path: "auth",
    loadChildren: () => import("@panel/auth").then((m) => m.AuthModule),
  },
  {
    path: "user",
    loadChildren: () => import("./user/user.module").then((m) => m.UserModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: "Użytkownicy",
    },
  },
  {
    path: "building",
    loadChildren: () =>
      import("./building/building.module").then((m) => m.BuildingModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: "Budynki",
    },
  },
  {
    path: "company",
    loadChildren: () =>
      import("./company/company.module").then((m) => m.CompanyModule),
    canActivate: [AuthGuard],
    // data: { roles: ['Admin', 'User']}
    data: {
      breadcrumb: "OPERATOR.MULTIPLE_TITLE",
    },
  },
  {
    path: "tenant",
    loadChildren: () =>
      import("./company/company.module").then((m) => m.CompanyModule),
    canActivate: [AuthGuard],
    // data: { roles: ['Admin', 'User']}
    data: {
      breadcrumb: "Najemcy",
    },
  },
  {
    path: "price-list",
    loadChildren: () =>
      import("./price-list/price-list.module").then((m) => m.PriceListModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: "Cenniki",
    },
  },
  {
    path: "legal",
    // component: LegalComponent
    loadChildren: () =>
      import("./legal/legal.module").then((m) => m.LegalModule),
    // canActivate: [AuthGuard],
    data: { 
      breadcrumb: "Megaustawa" 
    }
  },
  {
    path: "emergency-number",
    // component: LegalComponent
    loadChildren: () =>
      import("./emergency-number/emergency-number.module").then(
        (m) => m.EmergencyNumberModule
      ),
    // canActivate: [AuthGuard],
  },
  {
    path: "infrastructure",
    // component: LegalComponent
    loadChildren: () =>
      import("./infrastructure/infrastructure.module").then(
        (m) => m.InfrastructureModule
      ),
    // canActivate: [AuthGuard],
  },
  {
    path: "project",
    // component: LegalComponent
    loadChildren: () =>
      import("./project/project.module").then((m) => m.ProjectModule),
    // canActivate: [AuthGuard],
  },
  {
    path: "customer",
    // component: LegalComponent
    loadChildren: () =>
      import("./customer/customer.module").then((m) => m.CustomerModule),
    canActivate: [AuthGuard],
  },
  {
    path: "help",
    component: HelpComponent,
  },
  {
    path: "home",
    loadChildren: () =>
      import("./home/home.module").then((m) => m.HomeModule),
    // canActivate: [AuthGuard],
    data: {
      breadcrumb: "Strona główna",
    }, 
  },
  {
    path: "settings",
    loadChildren: () => import("./settings/settings.module").then((m) => m.SettingsModule),
    canActivate: [AuthGuard],
    data: { 
      breadcrumb: "Ustawienia" 
    }
  },
  {
    path: "task",
    loadChildren: () => import("./task/task.module").then((m) => m.TaskModule),
    canActivate: [AuthGuard],
    data: { 
      breadcrumb: "Zadania" 
    }
  },
  {
    path: 'disabled/:module',
    loadChildren: () =>
      import('./disabled/disabled.module').then((m) => m.DisabledModule),
    data: { 
      breadcrumb: "Moduł nieaktywny" 
    }
  },
  {
    path: "warehouse",
    loadChildren: () => import("./warehouse/warehouse.module").then((m) => m.WarehouseModule),
    canActivate: [AuthGuard],
    data: { 
      breadcrumb: "Magazyn" 
    }
  },
  {
    path: "document",
    loadChildren: () => import("./document/document.module").then((m) => m.DocumentModule),
    canActivate: [AuthGuard],
    data: { 
      breadcrumb: "Dokumenty" 
    }
  },
  {
    path: "article",
    loadChildren: () => import("./article/article.module").then((m) => m.ArticleModule),
    canActivate: [AuthGuard],
    data: { 
      breadcrumb: "Dokumenty" 
    }
  },
  { 
    path: '**', 
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
