export class Legal {
  id: number;
  idUserCreated?: number;
  dateCreated: Date;
  topic: string = '';
  description: string = '';
  status: string; // dictionary
  attachments: any[] = [];
  idParent?: number;
}

// export class LegalPrcing {
//   id: number;
//   idLegal: number;
//   idUserCreated: number;
//   dateCreated: number;
//   value: number;
//   decsription: number;
// }

// export class LegalAnswer {
//   id: number;
//   idUserCreated: number;
//   dateCreated: number;
//   topic: string;
//   description: string;
//   attachments: any[];

// }
