import { Building } from '../building/building.model';
import { Company } from '../company/company.model';
import { PriceList } from '../price-list/price-list.model';
import { Product } from '../product/product.model';
import { User } from '../user/user.model';
import { Attachment } from './attachment.model';
import { ConsentValueUpdateBody } from './consentValue.model';
import { ProductProperty } from './product-property.model';

class TechnicalConditions {

  id: number;
  number: string;
  name: string;

  contractRenewal: boolean;
  contractRenewalNumber: string;

  idBuilding: number;
  idCompany: number; //TODO Chnage to idCompany!!!

  dateCreated: Date; // powinno być date.
  dateExpiration: Date;
  verificationDate: Date;

  status: number; // powinien być słownik
  additionalDescription: string;
  contractPeriod: number = 36;
  contractPeriodOther ?: string;

  customerCompanyName: string;
  customerFullName: string;
  customerEmail: string;
  customerPhone: string;
  customerFloor: string = "";
  customerDescription: string;

  company?: Company;
  building?: Building;
  contractType: number;

  idUserCreated: number;
  userCreated: User;

  technicalConditionProducts?: TechnicalConditionsProduct[] = [];

  priceList: PriceList;

  attachments: Attachment[] = [];

  technicalConditionExtension: TechnicalConditionExtension;

  product: Product;

  data: any;
  consents?: ConsentValueUpdateBody[] = [];
}

class TechnicalConditionsProduct {

  id: number;
  idTechnicalCondition: number;
  idProduct: number;
  name: string;
  internalDescription: string;
  externalDescription: string;
  consentDescription: string;
  technicalConditionProductProperties: TechnicalConditionProductProperty[];
  floorRequired: boolean;
  product: Product;
  nameSimple: string;
}

class TechnicalConditionProductProperty {

  id: number;
  idTechnicalConditionProduct: number;
  idProductProperty: number;
  idProductGroup: number;
  name: string;
  placeHolder: string;
  typeOfCharge: TypeOfCharge;
  type: ProductPropertyType;
  quantity: number;
  baseQuantity: number;
  unitBasePrice: number;
  unitFinalPrice: number;
  priceListDefined: boolean;
  itemKey: string;
  attachmentRequired: boolean;
  itemKeyUnit: string;
  productProperty: ProductProperty;
  attachFromIdProductProperty: number;
  nameSimple: string;

  canEditBasePrice: boolean;
  basePriceMultipler: number;
}

class TechnicalConditionExtension {
  autoStatusChange: TechnicalConditionStatus;
  autoStatusChangeDate: Date;
  additionalInformation: string;
  additionalInformationConfidental?: string;
}


enum TypeOfCharge {
  Undefined = 0,
  Monthly = 1,
  OneTime = 2,
}

enum ProductPropertyType {
  bit = 'bit',
  true = 'true',
  int = 'int',
  combobox = 'combobox',
  string = 'string',
}

enum TechnicalConditionStatus {
  New = 1,
  InVeryfication = 2,
  Confirmed = 3,
  InRealisation = 4,
  Rejected = 5,
  Closed = 99
}

export { TechnicalConditionProductProperty, TechnicalConditions, TechnicalConditionsProduct, TypeOfCharge, ProductPropertyType, TechnicalConditionStatus, TechnicalConditionExtension }

