export enum TaskStatus {
  New = 1,
  Assigned,
  InProgress,
  Finished,
  Closed,
  Rejected,
  Waiting,
  AcceptedToRealization,
}

export enum TaskStatusManage { 
  None = 0,
  Open = 1,
  Closed,
}