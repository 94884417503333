import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";

@Component({
  selector: "app-person-add",
  templateUrl: "./person-add.component.html",
  styleUrls: ["./person-add.component.scss"],
})
export class PersonAddComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PersonAddComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      readonly: boolean;
    }
  ) {}

  personAdd: FormGroup;

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.personAdd = this.formBuilder.group({
      name: new FormControl(
        {
          value: '',
          disabled: this.data.readonly,
        },
        [Validators.required, Validators.minLength(3)]
      ),
      company: new FormControl({
        value: '',
        disabled: this.data.readonly,
      }),
      contact: new FormControl(
        {
          value: null,
          disabled: this.data.readonly,
        },
        [
          Validators.required,
          Validators.pattern(
            "((([+][(]?[0-9]{1,3}[)][ ]?)s*[)]?([-s.]?[0-9]{9}))|([-s.]?[0-9]{9}))"
          ),
        ]
      ),
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  save() {
    this.personAdd.markAllAsTouched();
    if (!this.personAdd.valid) { return };

    this.dialogRef.close(this.personAdd.value);
  }
}
