export class MobileCardDisplayOption {
  name: string;
  displayName: string;
  displayValueTransform: (value) => any;
  rowType: CardRowType;
  permission?: string[] = [];
  iconName?: string;
  iconNameF?: (value) => string;
  iconStyle?: (value) => string;
  textDisplay?: string;
}

export class MobileCardRowDisplay {
  rowType: RowType;
  data: MobileCardDisplayOption[];
}

export class MobileCardParameters {
  isInside: boolean = false;
  showDeleteBtn?: boolean = false;
  canDelete?: string[];
  title?: (value) => any;
  subtitle?: (value) => any;
  handleClick? (row: any): any;
}

export enum RowType {
  SINGLE = 1,
  DOUBLE = 2
}

export enum CardRowType {
  ICON = 1,
  TEXT = 2,
  STATUS,
  DOUBLE_ROW_LEFT,
  DOUBLE_ROW_RIGHT
}

export class DeleteEvent {
  idObject: number;
  event: Event;
}