<h1 mat-dialog-title class="info-dialog__background" [ngClass]="{'no-title' : title.length <= 0}">
  {{title}}
</h1>

<div class="dialog-content custom-scrollbar">
  <div mat-dialog-content custom-scrollbar>
    <p [innerHTML]="message"></p>
  </div>
  
  <div class="center">
    <button class="btn primary-button" (click)="onConfirm()">Ok</button>
  </div>
</div>

