<div *ngIf="!loading" class="main-container">
  <div>

    <h1 mat-dialog-title class="info-dialog__background">
      {{tmpAssignment.id ? 'Edycja przypisania' : 'Przypisanie operatora i budynków do cennika'}}
    </h1>


    <div class="dialog-content custom-scrollbar">

    <mat-form-field>
      <mat-label>Wybierz Operatora</mat-label>
      <mat-select 
        [(ngModel)]="tmpAssignment.idCompany" 
        name="idCompany" 
        [disabled]="tmpAssignment.id"
      >
        <mat-option 
          *ngFor="let company of companiesAll" 
          [value]="company.id"
        >
          {{ company.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="data.idPriceList == null && showPriceLists">
      <mat-label>Wybierz Cennik</mat-label>
      <mat-select 
        [(ngModel)]="tmpAssignment.idPriceList" 
        name="idPriceList"
      >
        <mat-option 
          *ngFor="let price of priceLists" 
          [value]="price.id"
        >
          {{ price.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="checkbox-with-label">
      <div class="all-buildings-box">
        <mat-checkbox 
          [(ngModel)]="tmpAssignment.allBuildings" 
          name="allBuildings" 
          (change)="onAllBuildingsChange($event)"
          color="warn"
        >
          Wszystkie budynki
        </mat-checkbox>
      </div>

      <div *ngIf="!tmpAssignment.allBuildings" class="search-box-sm icon">
        <input
          size="100"
          class="form-control"
          type="text"
          name="search_filter"
          [(ngModel)]="assignedBuildingFilter"
          autocomplete="off"
          [placeholder]="'Wyszukaj podpięty budynek'"
        />
      </div>
    </div>

    <!-- <mat-form-field>
      <mat-label>Wybierz budynki</mat-label>
      <mat-select [compareWith]="compareBuildingAssignment" [(ngModel)]="tmpAssignment.assignmentBuildings" name="idBuiding" multiple="1" [disabled]="tmpAssignment.allBuildings">
        <mat-option *ngFor="let assignmentBuilding of buildingsAllForAssign" [value]="assignmentBuilding">
          {{buildingService.getBuildingDisplayName(assignmentBuilding.building)}}
        </mat-option>
      </mat-select>
    </mat-form-field> -->

    <mat-form-field appearance="fill" *ngIf="!tmpAssignment.allBuildings">
      <mat-label>Wybrane budynki ({{ tmpAssignment.assignmentBuildings.length }})</mat-label>
      <mat-chip-grid #chipList aria-label="Przypisane budynki" [formControl]="fBuildingIds">
        <div class="chip-list-style">
          <mat-chip-row
            *ngFor="let assignmentBuilding of tmpAssignment?.assignmentBuildings | customFilterPipe: assignedBuildingFilter"
            (removed)="removeAssignedBuilding(assignmentBuilding)">
            {{assignmentBuilding.building.name}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-row>
        </div>
      </mat-chip-grid>

      <hr 
        *ngIf="!tmpAssignment.allBuildings" 
        class="range-divider__bottom"
      >

      <div *ngIf="!tmpAssignment.allBuildings">
      <input *ngIf="!tmpAssignment.allBuildings" 
        class="find-building"
        placeholder="Wyszukaj budynek do przypisania"
        [formControl]="fBuildingSelect"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        #buildingSelectInput
      >
      <mat-autocomplete 
        #auto="matAutocomplete" 
        (optionSelected)="selected($event)" 
        [displayWith]="displayFn"
      >
        <mat-option *ngFor="let plBuilding of buildingsAllForAssignFiltered | async" [value]="plBuilding">
          {{plBuilding.building.name}}
        </mat-option>
      </mat-autocomplete></div>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Data obowiązywania od</mat-label>
      <input 
        name="dataExpected"
        [(ngModel)]="dateFromChange" 
        matInput
        [matDatepicker]="dateFromChangePicker"
      >
      <mat-datepicker-toggle 
        matIconSuffix 
        [for]="dateFromChangePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker 
        #dateFromChangePicker
      ></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Data obowiązywania do</mat-label>
      <input 
        name="dataExpected"  
        [(ngModel)]="dateToChange" 
        matInput
        [matDatepicker]="dateToChangePicker"
      >
      <mat-datepicker-toggle 
        matIconSuffix 
        [for]="dateToChangePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker 
        #dateToChangePicker
      ></mat-datepicker>
    </mat-form-field>

    <div *ngIf="!saveOperationLoader" class="buttons-container">
      <button *ngIf="tmpAssignment.id" class="btn btn-sm btn-secondary" (click)="remove()">Usuń</button>
      <button class="btn btn-sm btn-outline-secondary" (click)="dialogRef.close(false)">Anuluj</button>
      <button class="btn btn-sm btn-primary" (click)="save()">{{tmpAssignment.id ? 'Zapisz' : 'Dodaj'}}</button>
    </div>
  </div>

  <app-loader
    [show]="saveOperationLoader"
  ></app-loader>
    
 </div>

</div>

<app-loader
  [show]="loading"
  customCss="loader__position loader__padding"
></app-loader>
