import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppInstanceService, AuthService } from '@panel/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedModule } from '@panel/shared';

@Component({
  selector: 'lib-confirm-register',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule
  ],
  templateUrl: './confirm-register.component.html',
  styleUrls: ['./confirm-register.component.scss'],
})
export class ConfirmRegisterComponent implements OnInit {
  form: FormGroup;
  validationError: boolean = false;
  errorMessage: string;
  loading: boolean = false;
  sended: boolean = false;
  key: string;

  hide: boolean;
  hideConfirm: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    public appInstanceService: AppInstanceService,

  ) {
    this.route.queryParams.subscribe(params => {
      this.key = params['key'];

      if (!this.key) {
        this.router.navigate(['/']);
      }
    })
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      password: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required, Validators.minLength(4)],
      }),
      rePassword: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required, Validators.minLength(4)],
      }),
    });
  }

  activateAccount() {

    if (!this.form.valid) {
      this.validationError = true;
      return;
    }

    const password = this.form.controls['password'].value;
    const rePassword = this.form.controls['rePassword'].value;

    if (password !== rePassword) {
      this.errorMessage = 'Hasło musi być takie samo.';
      return;
    }

    // this.authService.confirmRegistration(this.key, password).subscribe(
    //   res => { this.sended = true; },
    //   error => { console.log(error) }
    // )

  }
}
