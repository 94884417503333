<h1 mat-dialog-title class="info-dialog__background">
   {{ data.header | translate }}
</h1>

<app-loader [show]="loading"></app-loader>

<div 
  *ngIf="!loading"
  class="dialog-content custom-scrollbar"
>
  <div 
    *ngIf="data.currentUser"
    class="change-user__current"
  >
    <app-avatar 
      [user]="data.currentUser"
      class="change-user__avatar"
    ></app-avatar>
    <div class="change-user__details">
      {{ data.currentUser | userDetails }}
    </div>
  </div>

  <div 
    class="change-user__new"
    [ngClass]="{'change-user__new--error' : changeUser.invalid}"
  >
    <mat-form-field 
      appearance="outline"
      class="change-user__new--field"
      [ngClass]="{'change-user__new--disabled' : changeUser.disabled}"  
    >
      <mat-label>{{ data.formLabel | translate }}</mat-label>
      <mat-select 
        [formControl]="changeUser"
      >
        <mat-option 
          *ngFor="let user of usersList" 
          [value]="user"
          class="change-user__option"
        >
          <span class="change-user__option--text">
            {{ user | userDetails }}
          </span>  
        </mat-option>

      </mat-select>
      <mat-hint *ngIf="changeUser.disabled">
        Brak użytkowników do wyboru
      </mat-hint>
      <mat-error>
        Wybierz użytkownika do zmiany
      </mat-error>
    </mat-form-field>
  </div>

  <div class="buttons__space-between">
    <button 
      class="btn tertiary-button" 
      (click)="cancel()"
    >
      Anuluj
    </button>
    <button 
      class="btn primary-button" 
      (click)="save()"
      [disabled]="changeUser.disabled"
    >
      {{ data.buttonLabel | translate }}
    </button>
  </div>
  
</div>