import { User } from "../user/user.model";

export class RealizationTimeConfig {
  currentUser: boolean;
  description?: string;
  idTableObject: number;
  tableName: string;
  typeCategory?: number;
  timeForAllUsers?: boolean;
  requireBlameOn?: boolean;
  idTime?: number;
  readonly?: boolean;
}

export class RealizationTime {
  id: number;
  dateCreated: Date;
  date: Date;
  // time format HH:mm
  timeStart: string;
  timeEnd: string;
  description: string;
  idUser: number;
  idTableObject: number;
  tableName: string;
  type: number;
  user: User; 
  blameOn?: BlameOn;
  drive?: boolean;
  timeDrive?: string;
}

export class CreateRealizationTime {
  date: Date;
  // time format HH:mm
  timeStart: string;
  timeEnd: string;
  description: string;
  idTableObject: number;
  tableName: string;
  type: string;
  idUser?: number;
  blameOn?: BlameOn;
  drive?: boolean;
  timeDrive?: string;
}

export class UpateRealizationTime {
  // time format HH:mm
  timeStart: string;
  timeEnd: string;
  description?: string;
  type?: string;
  blameOn?: BlameOn;
  drive?: boolean;
  timeDrive?: string;
}

export class TimeQueryParameters {
  idUser?: number;
  tableName?: string;
  idTableObject?: number;
  blameOn?: BlameOn;
}

export class TimeDisplayConfiguration {
  tableName: string = '';
  idObject: string = '';
  getAll: boolean = false;
  addTimePermission: boolean = false;
  addTimeAllPermission: boolean = false;
  canDelete: boolean = false;
  readonly: boolean = false;
  requireBlameOn: boolean = false;
}

export enum BlameOn {
  NONE = 0,
  CUSTOMER,
  WE
}

export enum FTimeControls {
  USER = 'user',
  DATE = 'date',
  TIME_START = 'timeStart',
  TIME_END = 'timeEnd',
  TYPE = 'type',
  DESCRIPTION = 'description',
  DIFF_TIME = 'diffTime',
  DRIVE = 'drive',
  TIME_DRIVE = 'timeDrive',
  BLAME_ON = 'blameOn',
}