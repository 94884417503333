import { Component, OnInit } from "@angular/core";
import { UserService } from "./user/user.service";
// import { AppInstanceService } from './_services/app-instance.service';
import { ConfigService } from "./_services/config.service";
import { Router } from "@angular/router";
import { AppInstanceService, TranslateConfigService } from "@panel/auth";
import { ChatbotService, Utils } from "@panel/helpers";
import { SessionSettingsAction } from "@panel/models";
import { AuthenticationService } from "@panel/auth";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {

  title = "panelapp";
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(
    private userServ: UserService,
    private appInstanceService: AppInstanceService,
    private configService: ConfigService,
    private router: Router,
    private translateConfigService: TranslateConfigService,
    private authServ: AuthenticationService,
    private chatbotService: ChatbotService
  ) {}

  async ngOnInit() {
    this.favIcon.href = this.appInstanceService.getAppAssetsUrl('favicon');
    
    if (this.userServ.userLogged) {
      await this.userServ.getPermision();
      await this.configService.loadConfigs();
      Utils.manageSessionSetting(SessionSettingsAction.GET);
      
      //Needed for setting translation when strapi loading is taking too long
      this.translateConfigService.setLanguage('pl');
      
      //Kamil Cz 2023.06.06 Poprawka - wywala błąd jeśli nie jest zalogowany uzytkownik
      //Przeniosłem pobieranie translate do sekcji po wykryciu zalogowanego uzytkownika
      await this.translateConfigService.setTranslation();

      if (!this.userServ.userLogged.informationClauseAccepted) {
        this.router.navigate(['/legal/information-claue-accept']);
      }

      await this.userServ.checkDocumentsAcceptation();
    }
  }
}
