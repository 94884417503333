import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppInstanceService } from '@panel/auth';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  url = this.environment.api;

  constructor(
    @Inject('env') private environment: any,
    private http: HttpClient,
    private appInstanceService: AppInstanceService,
  ) { }


  forgetPassword(email: string) {
    // return this.http.post(this.url + 'auth/forget-password', { email: email, appName: 'panelcrm' });
    return this.http.post(this.url + 'auth/forget-password', { email: email, appName: this.appInstanceService.getAppName() });
  }

  checkResetPassword(key: string) {
    return this.http.get(this.url + `auth/reset-password?key=${encodeURI(key)}&salt=${Math.random()}`);
  }

  newPassword(action: string, key: string, password: string ) {
    return this.http.post(this.url + 'auth/new-password', { password, key, action });
  }
}
