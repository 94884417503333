import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@panel/shared';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppInstanceService, AuthService } from '@panel/auth';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-new-password',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule
  ],
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent implements OnInit {
  form: FormGroup;
  validationError: boolean = false;
  errorMessage: string;
  loading: boolean = false;
  sended: boolean = false;
  pageContent: {
    header: string,
    description?: string,
    confirmButton?: string,
    responseCommunicate?: string,
    descriptionSmall?: string,
  }

  action: string;
  key: string;
  keyVaild = false;

  hide: boolean;
  hideConfirm: boolean;

  constructor(
    public appInstanceService: AppInstanceService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private translate: TranslateService,
  ) {
    this.form = new FormGroup({
      password: new FormControl('', {
        updateOn: 'blur',
        validators: [Validators.required, Validators.minLength(8), Validators.maxLength(30)]
      }),
      rePassword: new FormControl('', {
        updateOn: 'blur',
        validators: [Validators.required, Validators.minLength(8), Validators.maxLength(30)]
      }),
    }, {updateOn:'change', validators: [this.checkPasswords]});
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe(paramMap => {
      this.action = paramMap.get('action')!;
      this.key = paramMap.get('key')!;

      this.authService.checkResetPassword(this.key).subscribe(res => {
        this.keyVaild = true;
        this.setPageContent();
      }, error => {
        this.setPageContent();
      });
    });
  }

  setPageContent() {
    if (!this.keyVaild) {
      switch (this.action) {
        case 'activate': {
          this.pageContent = {
            header: 'Aktywacja konta',
            description: 'Link aktywacyjny wygasł.',
            descriptionSmall: 'Twoje konto zostało prawdopodobnie już aktywowane.'
          }
        }
        break;
        case 'reset': {
          this.pageContent = {
            header: 'Zmiana hasła',
            description: 'Link do zmiany hasła wygasł.',
          }
        }
      }
      return;
    }

    switch (this.action) {
      case 'activate': {
        this.pageContent = {
          header: 'Aktywacja konta',
          description: 'Proszę wprowadzić hasło.',
          confirmButton: 'Aktywuj',
          responseCommunicate: 'Twoje konto zostało aktywowane.'
        }
      }
      break;
      case 'reset': {
        this.pageContent = {
          header: 'Zmiana hasła',
          description: 'Proszę podać nowe hasło.',
          confirmButton: 'Zapisz',
          responseCommunicate: 'Hasło do konta zostało zmienione.',
        }
      }
    }
  }

  sendNewPassword() {

    let password = this.form.controls['password'].value;
    let rePassword = this.form.controls['rePassword'].value;

    if (this.form.invalid) {
      this.validationError = true;

      if (password !== rePassword) {
        this.errorMessage = 'Hasła muszą do siebie pasować';
      }

      return;
    }

    //TODO reset password or create new password.
    this.authService.newPassword(this.action, this.key, password).subscribe(
      (res) => {
      this.sended = true;
      },
      (err) => { 
        // this.errorMessage = "Aktywacja konta niepowiodła się, prosimy o kontakt na adres email: panel.operatorow@citimedia.pl" 
        this.errorMessage = this.translate.instant(err.error.code)
      }
    );
  }

  checkPasswords(group: FormGroup) {
    let pass = group.value.password;
    let confirmPass = group.value.rePassword;
    
    return pass === confirmPass ? null : { notSame: true }
  }
}
