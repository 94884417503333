import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { AttachmentVieverFile } from './attachment-viewer-file';

@Component({
  selector: 'app-attachment-viewer',
  templateUrl: './attachment-viewer.component.html',
  styleUrls: ['./attachment-viewer.component.scss']
})
export class AttachmentViewerComponent {

  url = environment.api;

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public file: AttachmentVieverFile,
  ) { }

  ngOnInit() {
    (<any>window).pdfWorkerSrc = 'assets/pdf.worker.js';
  }
}
