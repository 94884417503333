import { Component, Input, OnInit } from '@angular/core';
import { Building } from '../../building/building.model';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'app-building-link',
  templateUrl: './building-link.component.html',
  styleUrls: ['./building-link.component.scss']
})
export class BuildingLinkComponent implements OnInit {
  @Input()
  building: Building;

  permissionTechnicalConditionsViewBuildingDescription = false;
  permissionBuildingEdit = false;

  constructor(
    public userServ: UserService
  ) { }

  async ngOnInit() {
    this.permissionTechnicalConditionsViewBuildingDescription = await this.userServ.hasRolesAsync(
      ["buildings.view-description"]
    );
    this.permissionBuildingEdit = await this.userServ.hasRolesAsync(
      ["buildings.edit"]
    );
  }

}
