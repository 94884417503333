export interface ListMetaData {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  itemCount: number;
  page: number;
  pageCount: number;
  take: number;
}

export interface ListData<T> {
  data: T[];
  meta: ListMetaData;
}

/**
 * @param order Table order (ASC | DESC)
 * @param page number of page
 * @param take count of items per page (min: 1, max: 50, default: 10)
 * @param startIndex start page count index for pagination, e.g. for a paginator from angular materials is 0
 */
export class MetaDataParameters {
  order?: TableOrder;
  page?: number;
  take?: number;
  startIndex?: number;
}

export enum TableOrder {
  ASC = "ASC",
  DESC = "DESC",
}