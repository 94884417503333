import { Role } from './role.model';
import { Company } from '../company/company.model';
import { AppModule } from '../_helpers/module';

export class User {
  id: number;
  username: string;
  idRole: number;
  email: string;
  firstName: string;
  lastName: string;
  mobilePhone?: string;
  telephone: string;
  idCompany?: number;
  idCompanyOrigin?: number;
  role?: Role;
  operator?: Company;
  company?: Company;
  companyOrigin?: Company;
  token: string;
  modules: [{idModule: number}];
  informationClauseAccepted: boolean;
  activated: boolean;
  refreshToken?: string;
  status: string;
  disabledModules?: AppModule[] = [];
  disclaimerAccepted?: boolean;
  visibleOnList?: boolean;
}

export class UserRole {
  id: number;
  name: string;
  weight: number;
}

export interface UserChangeCompany {
  idCompany: number;
}

export interface UserQueryParams {
  selectLimit?: number;
  idCompany?: number;
}

