import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Legal } from './legal.model';

const url = environment.apiJsonDemo;

@Injectable({
  providedIn: 'root'
})
export class LegalService {




  legal: Legal = new Legal();
  legals: Legal[] = [];
  legalsSubject: Subject<Legal[]> = new Subject<Legal[]>();



  constructor(private http: HttpClient) { }


  getLegals() {
    return this.http.get(url + 'legal');
  }
  postLegal() {
    return this.http.post(url + 'legal', this.legal);
    // this.legals.push(this.legal);
  }

  updateLegal() {
    return this.http.put(url + 'legal/' + this.legal.id, this.legal);
  }

  getLegal(id: number) {
    return this.http.get(url + 'legal/' + id);
    // this.legal = this.legals.find(a => a.id === id);
  }
  legalNumber(id: number) {
    return 'LEG/' + ('000000' + id).substr(('000000' + id).length - 6);
  }

}
