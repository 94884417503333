import { Pipe, PipeTransform } from "@angular/core";
import { ConfigService } from "../_services/config.service";

@Pipe({
  name: "config"
})
export class ConfigPipe implements PipeTransform {
  constructor(
    private configService: ConfigService
  ) {
  }

  async transform(value: string, ...args: any[]): Promise<any> {
    return await this.configService.getConfigValue(value);

    //return this.configService.findConfigValue(value);
  }
}
