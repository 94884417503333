import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Dictionary, DictionaryValues } from './dictionary.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Language } from '../_enums/language.enum';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {

  //private dictionary = new Subject<Dictionary[]>();
  private dictionary: Dictionary[] = [];
  private init = false;
  public dictionaryLoaded: boolean = false;

  assistanceStatusValues: DictionaryValues[] = [];
  assitanceDuration: DictionaryValues[] = [];
  technicalConditionsStatusValues: DictionaryValues[] = [];
  ordersStatusValues: DictionaryValues[] = [];
  buildingLocationType: DictionaryValues[] = [];
  cableType: DictionaryValues[] = [];
  projectStatus: DictionaryValues[] = [];
  buildingType: DictionaryValues[] = [];
  contractTypes: DictionaryValues[] = [];
  chargeTypes: DictionaryValues[] = [];
  taskPriority: DictionaryValues[] = [];
  taskStatus: DictionaryValues[] = [];
  taskTopic: DictionaryValues[] = [];
  companyType: DictionaryValues[] = []; 
  timeType: DictionaryValues[] = [];
  articleStockStatus: DictionaryValues[] = [];
  installationPlace: DictionaryValues[] = [];
  connectorType: DictionaryValues[] = [];
  realizationTimeBlameOn: DictionaryValues[] = [];

  constructor(
    private http: HttpClient,
  ) { }

  getDictionary(): Observable<Dictionary[]> {
    return this.http.get<Dictionary[]>(environment.api + 'dictionary', {
      params: { language: Language.PL }
    });
  }

  async loadDictionary() {
    this.assistanceStatusValues = (await this.getDictionaryByName('assistance_status'))?.dictionaryValues;
    this.assitanceDuration = (await this.getDictionaryByName('assitance_duration'))?.dictionaryValues;
    this.technicalConditionsStatusValues = (await this.getDictionaryByName('technical_condition.status'))?.dictionaryValues;
    this.ordersStatusValues = (await this.getDictionaryByName('order_status'))?.dictionaryValues;
    this.buildingLocationType = (await this.getDictionaryByName('building_location_type'))?.dictionaryValues;
    this.cableType = (await this.getDictionaryByName('cable.type'))?.dictionaryValues;
    this.projectStatus = (await this.getDictionaryByName('project.status'))?.dictionaryValues;
    this.buildingType = (await this.getDictionaryByName('building_type'))?.dictionaryValues;
    this.contractTypes = (await this.getDictionaryByName('contract_types'))?.dictionaryValues;
    this.chargeTypes = (await this.getDictionaryByName('type_of_charge'))?.dictionaryValues;
    this.taskPriority = (await this.getDictionaryByName('task_priority'))?.dictionaryValues;
    this.taskStatus = (await this.getDictionaryByName('task_status'))?.dictionaryValues;
    this.taskTopic = (await this.getDictionaryByName('task_topic'))?.dictionaryValues;
    this.companyType = (await this.getDictionaryByName('company_type'))?.dictionaryValues;
    this.timeType = (await this.getDictionaryByName('time_type'))?.dictionaryValues;
    this.articleStockStatus = (await this.getDictionaryByName('article_stock_status'))?.dictionaryValues;
    this.installationPlace = (await this.getDictionaryByName('installation_place'))?.dictionaryValues;
    this.connectorType = (await this.getDictionaryByName('connector_type'))?.dictionaryValues;
    this.realizationTimeBlameOn = (await this.getDictionaryByName('realization_time_blame'))?.dictionaryValues;
  }

  getName(dictionaryValues: DictionaryValues[], value: number) {
    return dictionaryValues?.find(a => a.value === value)?.key ?
      dictionaryValues?.find(a => a.value === value).key : '';
  }

  async initDictionary() {
    if (this.init) {
      return;
    }

    this.dictionary = await this.getDictionary().toPromise();
    this.init = true;
  }

  findValue(val: DictionaryValues[], key: number) {
    let dv: DictionaryValues = val?.find(x => x.value == key);
    return dv ? dv.key : "";
  }

  findColor(val: DictionaryValues[], key: number) {
    let dv: DictionaryValues = val?.find(x => x.value == key);
    return dv ? dv.color : "";
  }

  findTechnicalConditionValue(key: number) {
    let dv: DictionaryValues = this.technicalConditionsStatusValues?.find(x => x.value == key);
    return dv ? dv.key : "";
  }

  findTypeBuildingValue(key: number) {
    let dv: DictionaryValues = this.buildingType?.find(x => x.value == key);
    return dv ? dv.key : "";
  }

  findOrderValue(key: string): string
  findOrderValue(key: number): string
  findOrderValue(key: any): string {
    let dv: DictionaryValues = this.ordersStatusValues?.find(x => x.value == key);
    return dv ? dv.key : "";
  }

  async getDictionaryByName(name: string): Promise<Dictionary> {
    await this.initDictionary();

    return this.dictionary?.find((dict: Dictionary) => dict.name === name);
  }

  async getDictionaryForObjectAsync(name: string, table: string, idObject: number) {
    await this.initDictionary();
    return this.getDictionaryForObject(name, table, idObject);
  }

  getDictionaryForObject(name: string, table: string, idObject: number) {
    this.initDictionary();
    let dict = this.dictionary ? this.dictionary.find((dict: Dictionary) => dict.name === name && dict.table === table && dict.idObject == idObject) : null;
    return dict ? dict.dictionaryValues : [];
  }

  private findDictionaryValues(name: string) {
    let dictionary = this.dictionary?.find((dict: Dictionary) => dict.name === name);
    return dictionary ? dictionary.dictionaryValues : [];
  }

  async getDictionaryValuesAsync(name: string) {
    await this.initDictionary();
    return this.findDictionaryValues(name);
  }

  getDictionaryValues(name: string) {
    this.initDictionary();
    return this.findDictionaryValues(name);
  }

  getPropertyValueName(idProperty: number, value: any): string {
    const dv = this.dictionary?.find(dic => dic.name === 'product_property_values' && dic.table === 'product_property' && dic.idObject === idProperty);

    if (!dv || !dv.dictionaryValues) {
      return '';
    }

    let key = dv.dictionaryValues?.find(x => x.value === value).key;

    return key;
  }

  getPriceListContractType(key: number) {
    let dv: DictionaryValues = this.contractTypes?.find(x => x?.value == key);
    return dv ? dv.key : "";
  }
}
