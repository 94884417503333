import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "yesNo"
})
export class YesNoPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let bold = args.includes(YesNoPipeParams.BoldYesOption);
    return value ? `${bold ? `<b>` : ''}TAK${bold ? `</b>` : ''}` : (args.includes(YesNoPipeParams.HideNoOption) ? "" : "NIE");
  }
}

export enum YesNoPipeParams {
  HideNoOption = 'hideNoOption',
  BoldYesOption = 'boldYesOption',
}
