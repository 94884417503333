import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../../user/user.service';
import { AuthenticationService } from '@panel/auth';
import { ThisPlatformService } from '../../_services/this-platform.service';
import { MatDialog } from '@angular/material/dialog';
import { ProfileChangeComponent } from '../profile-change/profile-change.component';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  menuActive: boolean = false;

  constructor(
    public authService: AuthenticationService,
    public userServ: UserService,
    public router: Router,
    private elementRef: ElementRef,
    public p: ThisPlatformService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() { }

  onToggleMenu() {
    this.menuActive = !this.menuActive
  }

  @HostListener("document:click", ["$event"])
  closeList(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.menuActive = false;
    }
  }

  onLogout() {
    this.authService.logout();
    this.menuActive = false;
    this.userServ.permission = new BehaviorSubject([]);
    this.router.navigate(['/auth']);
  }

  onSettings() {
    this.router.navigate(['/settings']);
  }

  async onAccountChange() {
    let userCompanies = await this.onSetAvailableCompanies();

    const dialogRef = this.dialog.open(ProfileChangeComponent, {
      maxWidth: '100vw',
      disableClose: false,
      data: {
        companies: userCompanies
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if(res) {
        this.userServ.switchCompany({idCompany: res}).subscribe(
          (updateUser) => {
            const updatedUser = {...this.authService.currentUserValue,...updateUser}
            localStorage.setItem("currentUser", JSON.stringify(updatedUser));
          },
          () => { },
          () => { 
            window.location.reload();
          }
        );
      }
    });
  }

  private async onSetAvailableCompanies() {
    let companies = [this.authService.currentUserValue.companyOrigin];

    this.authService.currentUserValue.companyOrigin.assignedCompanies.forEach(company => {
      companies.push(company.assignedCompany)
    });

    const availableCompanies = companies.filter(company => company.id != this.authService.currentUserValue.idCompany)

    return availableCompanies;
  }

}
