<div class="container__center">
  <div class="content">
    <div class="company-logo-container">
      <img [src]="appInstanceService.getAppAssetsUrl('logo')">
    </div>

    <div class="divider"></div>

    <div class="header">
      <p>Resetowanie hasła</p>
    </div>

    <form [formGroup]="form" (ngSubmit)="sendRecoveryMessagge()" *ngIf="!sended">
      <!-- <div class="login-error-message">
        <span>{{errorMessage}}</span>
      </div> -->

      <mat-form-field appearance="outline" color="primary">
        <mat-label>Email</mat-label>
        <input 
          matInput 
          formControlName="email" 
          placeholder="Podaj email"
        >
        <mat-error>Email jest wymagany</mat-error>
      </mat-form-field>

      <div class="buttons buttons__end">
        <button mat-button color="primary" [routerLink]="'/auth/login'">Powrót</button>
        <button mat-raised-button color="primary"
          (submit)="sendRecoveryMessagge()"
          [disabled]="loading">  
          Zresetuj
        </button>
      </div>
    </form>

    <div *ngIf="sended">
      <span>
        Wiadomośc z linkiem do zresetowania hasła została wysłana na podany adres email.<br> Prosze postępować zgodnie z instrukcją zawartą w wiadomości.
      </span>

      <div class="buttons__end">
        <button mat-button color="primary" [routerLink]="'/auth/login'">Powrót</button>
      </div>
    </div>

  </div>
</div>
