<h1 mat-dialog-title class="info-dialog__background">
  Dodawanie osoby do {{ 'ASSISTANCE.MULTIPLE_TEXT' | translate }}
</h1>

<div class="dialog-content custom-scrollbar">
  <form [formGroup]="personAdd">
    <table class="mytable">
      <tr>
        <mat-form-field>
          <mat-label>Imię i nazwisko</mat-label>
          <input 
            formControlName="name"
            autocomplete="off" 
            type="text" 
            matInput
          >
          <mat-error *ngIf="personAdd.get('name').hasError('required')">
            Imię i nazwisko jest wymagane.
          </mat-error>
          <mat-error *ngIf="personAdd.get('name').hasError('minlength')">
            Imie i nazwisko musi zawierać minimum 3 znaki.
          </mat-error>
        </mat-form-field>
      </tr>
      
      <tr>
        <mat-form-field>
          <mat-label>Firma (jeśli podwykonawca)</mat-label>
          <input 
            formControlName="company"
            autocomplete="off" 
            type="text" 
            matInput
          >
        </mat-form-field>
      </tr>
      
      <tr>
        <mat-form-field>
          <mat-label>Numer telefonu kontaktowego</mat-label>
          <input 
            formControlName="contact"
            autocomplete="off" 
            type="text" 
            matInput
          >
          <mat-error *ngIf="personAdd.get('contact').hasError('required')">
            Kontakt jest wymagany.
          </mat-error>
          <mat-error *ngIf="personAdd.get('contact').hasError('pattern')">
            Błędny format: XXXXXXXXX lub +(XX) XXXXXXXXX
          </mat-error>
        </mat-form-field>
      </tr>
    </table>
  </form>
  
  <div class="stepper-btn stepper-btn__space-between">
    <button class="btn secondary-button-outline" (click)="cancel()">Anuluj</button>
    <button class="btn primary-button" (click)="save()">+ Dodaj</button>
  </div>
</div>
