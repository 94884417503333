import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Perioid } from './perioid.model';

const url = environment.api;

@Injectable({
  providedIn: 'root'
})
export class PerioidService {
  constructor(
    private http: HttpClient) {}

  public getPerioids() {
    return this.http.get<Perioid[]>(`${url}perioid`);
  }
}
