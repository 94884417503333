import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
  // viewProviders: [
  //   {
  //     provide: ControlContainer,
  //     useExisting: FormGroupDirective
  //   }
  // ]
})
export class AutoCompleteComponent implements OnInit {

  @Input()
  label: string = '';
  @Input()
  items: any[];
  @Input()
  searchProperty: string = 'name';
  @Input()
  additionalFields: string[] = [];
  @Input()
  limit: number = 3;
  @Input()
  controlName: string = '';
  @Input()
  control: FormControl = null;
  @Input()
  model: string;

  @Output()
  itemSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  modelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  inputClear: EventEmitter<void> = new EventEmitter<void>();

  inputValue: string = '';
  isListActive: boolean = false;
  isSelected: boolean = false;
  /** List of matching elements */
  filteredItems: any[] = [];

  /** Selected item */
  selected: any;
  /** Selected item id from filteredItems array */
  selectedId: number = -1;

  constructor(
    private elementRef: ElementRef,
  ) { }

  ngOnInit() {
    if (!this.items) { this.items = []; }
  }

  /**
   * onInput value change
   * @param value - input value
   */
  onInputChnage(value) {
    //FIlter items
    if (this.isSelected) {
      this.itemSelected.emit(null);
      this.isSelected = false;
    }

    this.modelChange.emit(value);
    if (value.length > 0) {
      this.filteredItems = this.items.filter(element => 
        element[this.searchProperty].toLowerCase().includes(value.toLowerCase())
      ).slice(0, this.limit);

      this.isListActive = true;
    }
    else {
      this.filteredItems = [];
    }

  }

  /**
   * Focus on input
   */
  focus() {
    this.isListActive = true;
  }

  /**
   * On item selected from list
   * @param item - selected item from filtered list
   */
  elementSelected(item: any) {
    if (!item) return;

    this.isSelected = true;
    const tmp = item[this.searchProperty];

    this.model = tmp;
    this.modelChange.emit(this.model);
    this.itemSelected.emit(item);

    this.isListActive = false;
  }

  changeSelecteditem(increment: boolean = false) {
    let number = increment ? 1 : -1;
    
    if (this.filteredItems.length < 1) return;

    let newValue = (this.selectedId + number) < 0 ? this.filteredItems.length-1 : (this.selectedId + number);
    this.selectedId = newValue % this.filteredItems.length;

    if (this.selectedId < 0) return;

    this.selected = this.filteredItems[this.selectedId];
  }

  onClear() {
    this.isListActive = false;
    this.filteredItems = [];
    this.model = '';
    this.inputClear.emit();
    // this.itemSelected.emit(null);
  }

  @HostListener('document:click', ['$event'])
  closeList(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isListActive = false;
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    switch(event.key) {
      case 'ArrowDown': 
        this.changeSelecteditem(true);
      break;
      case 'ArrowUp': 
        this.changeSelecteditem(false);
      break;
      case 'Enter': 
        this.elementSelected(this.selected);
      break;
      case 'Escape': 
        this.isListActive = false;
      break;
    }

  }

}
