import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { ProductProperty } from "../_models/product-property.model";
import { Product } from "./product.model";


const url = environment.api;

@Injectable({
  providedIn: "root",
})
export class ProductService {
  products: Product[];

  newProduct: Product;
  selectedProducts: Product[];
  availableProducts: Product[]

  constructor(
    private http: HttpClient,
  ) {}

  public getProducts() {
    return this.http.get<Product[]>(`${url}product`);
  }

  public getProductsByIdBuilding(idBuilding: number) {
    return this.http.get<Product[]>(`${url}product?idBuilding=${idBuilding}`);
  }

  public getProductById(id: number) {
    return this.http.get<Product>(`${url}product/${id}`);
  }

  public getProductPropertyUnique(product: Product): ProductProperty[] {
    return [].concat(
      ...product.propertyGroups.map(pg => pg.productProperties.filter(pp => pp.priceListDefined))
    );
  }
}
