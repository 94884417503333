import { Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ConfirmRegisterComponent } from './components/confirm-register/confirm-register.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';

export const authRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'confirm-register', component: ConfirmRegisterComponent },
  { path: 'new-password', component: NewPasswordComponent,}
];
