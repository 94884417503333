export class TemplateConverter {
  public static ConvertTemplateParameters(template: string, object: any, options: TemplateConverterOptions = new TemplateConverterOptions()): string {
    //Zamiana pozostałych tagów, które nie wystąpiły w danym obiekcie.
    let str = TemplateConverter.ReplaceTemplateParameters(template, object);

    //Zamiana pozostałych tagów, które nie wystąpiły w danym obiekcie.
    if (options.removeUnassigned) {
      str = TemplateConverter.RemoveTemplateParameters(str);
    }

    if (options.removeLastComma) {
      str = this.RemoveLastComma(str);
    }

    return str;
  }

  public static ConvertTemplateParametersMultiple(template: string, objects: any[], options: TemplateConverterOptions = new TemplateConverterOptions()) {
    objects.forEach(object => {
      template = TemplateConverter.ReplaceTemplateParameters(template, object)
    });

    let str = template;

    //Zamiana pozostałych tagów, które nie wystąpiły w danym obiekcie.
    if (options.removeUnassigned) {
      str = TemplateConverter.RemoveTemplateParameters(str);
    }

    if (options.removeLastComma) {
      str = this.RemoveLastComma(str);
    }

    return str;
  }

  private static ReplaceTemplateParameters(template: string, object: any, nestedKeys: string[] = []): string {
    if (!object) {
      return template;
    }

    for (const [key, value] of Object.entries<any>(object)) {
      if (value instanceof Object) {

        nestedKeys.push(key);
        template = TemplateConverter.ReplaceTemplateParameters(template, value, nestedKeys);
        nestedKeys.pop();
      }

      let keyName = (nestedKeys.length > 0 ? (nestedKeys.join('.') + '.') : '') + key;
      let r = new RegExp('{{' + keyName + '}}', 'g');

      // Wyłączono dynamiczne wywołanie funkcji - wywalała się uniwersalna wysyłka
      // if (value instanceof Function)
      // {
      //   template = template.replace(r, value.length == 0 ? value() : '');
      // }
      // else
      // {
      //   template = template.replace(r, value ? value : '');
      // }

      template = template.replace(r, value ? value : '');
    };

    return template;
  }

  private static RemoveTemplateParameters(template: string): string {
    let r = new RegExp('{{[\\w\.]*}}', 'g');
    return template.replace(r, '');
  }

  private static RemoveLastComma(str: string) {
    if (!str) {
      return '';
    }

    if (str.slice(-1) == ',') {
      str = str.substring(0, str.length - 1);
    }

    return str;
  }
}

export class TemplateConverterOptions {
  removeLastComma: boolean = false;
  removeUnassigned: boolean = true;
}
