import { Component, Input, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { User } from '../../user/user.model';
import { Consent, consentConfig, consentQueryParams } from '../../_models/consent.model';
import { ConsentService } from '../../_services/consent.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConsentValueService } from '../../_services/consent-value.service';
import { ConsentValueUpdateBody } from '../../_models/consentValue.model';
import { ButtonType } from '../../_enums/button-type.enum';
import { EventEmitter } from "@angular/core";
import { TemplateConverter } from '../../_helpers/template-converter';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {

  @Input()
  query?: consentQueryParams;

  @Input()
  config?: consentConfig;

  @Output() 
  requiredConsent?: EventEmitter<Consent[]> = new EventEmitter<Consent[]>();

  consents: Consent[] = [];

  currnetUser: User = new User();

  ButtonType = ButtonType;

  constructor(
    public consentServ: ConsentService,
    private snackBar: MatSnackBar,
    public consentValueServ: ConsentValueService,
  ) { }

  async ngOnInit() {
    this.consentServ.getConsents(this.query).subscribe((res) => {
      this.consents = this.convertConsenst(res);
      if(this.config.requiredConsents) {
        this.requiredConsent.emit(res.filter(
          el => el.require == true
        ));
      }
    })

    this.currnetUser = JSON.parse(localStorage.getItem('currentUser'));

    if(this.config.object && !this.config.autoSave) {
      this.config.object.consents = [];
    }

  }


  consentToggle(event: MatSlideToggleChange, consent: Consent) {

    let consentValueUpdate: ConsentValueUpdateBody = {
      idUser: this.currnetUser.id,
      idConsent: consent.id,
      accepted: event.checked,
      tableName: this.query.tableName,
      idTableObject: this.query.idTableObject
    }

    if(consent.require) {
      consentValueUpdate.require = consent.require;
    }

    if( this.config.autoSave ) {
      this.consentValueServ.patchConsentValues(consentValueUpdate).subscribe({
        next: () => {},
        complete: () => this.openSnack('Zapisano zmiany', 'custom-snack-bar__success'),
        error: () => this.openSnack('Nie zapisano zmian', 'custom-snack-bar__error'),
      })
    } else {
      if( event.checked ) {
        this.config.object.consents.push(consentValueUpdate);
      } else {
        this.config.object.consents = this.config.object.consents.filter( 
          cons => cons.idConsent != consent.id
        );
      }

    }

  }

  openSnack(message, className) {
    this.snackBar.open(message, '', {
      duration: 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['custom-snack-bar', className]
    });
  }

  convertConsenst(consents: Consent[]) {
    consents.forEach((consent) => {
      consent.content = TemplateConverter.ConvertTemplateParametersMultiple(consent.content, [this.currnetUser])
    })

    return consents
  }
}
