import { Data } from '@angular/router';
import { BuildingCompany } from './building-company/building-company.model';

export class Building {
  id: number;
  name: string;
  street: string;
  postCode: string;
  city: string;
  // image: string;
  manualPriceVerification: boolean;
  remoteAssistance: boolean;
  buildingCompanies: BuildingCompany;
  description: string;
  type: number;
  idSyncCrm: number;
  additionalInfoForTechnicalCondition?: string;
  buildingExtension: BuildingExtension;
}

// Piony
export class BuildingVertical {
  id: number;
  name: string;
  idBuilding: number;
  description: string;
}

export class BuildingLocation {
  // node
  id: number;
  name: string;
  type: number;
  idBuildingVertical: number;
  buildingVertical: BuildingVertical;
  floor: number;
  description: string;
  idBuilding: number;

  data: any;
}

export class BuildingExtension {
  //idBuilding: number;
  idSyncCrm: number;
  markClientsAsSynchronized: boolean;
  url?: string;
}


