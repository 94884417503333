<div class="container__center">
  <div class="content">
    <div class="company-logo-container">
      <img [src]="appInstanceService.getAppAssetsUrl('logo')">
    </div>

    <div class="divider"></div>

    <div class="header">
      <p>Ustaw hasło</p>
    </div>

    <form [formGroup]="form" (ngSubmit)="activateAccount()" *ngIf="!sended">
      <div *ngIf="errorMessage" class="error-container">
        <span>{{errorMessage}}</span>
      </div>

      <mat-form-field appearance="outline" class="width__100">
        <mat-label>Hasło</mat-label>
        <input 
          matInput 
          formControlName="password"
          [type]="hide ? 'text' : 'password'"
        >
        <mat-icon matSuffix (click)="hide=!hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="form.get('password')?.hasError('required')">
          Podanie hasła jest wymagane.
        </mat-error>
        <mat-error *ngIf="form.get('password')?.hasError('minlength')">
          Hasło powinno mieć minimum 4 znaki.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="width__100">
        <mat-label>Potwierdź hasło</mat-label>
        <input 
          matInput 
          formControlName="rePassword"
          [type]="hideConfirm ? 'text' : 'password'"
        >
        <mat-icon matSuffix (click)="hideConfirm=!hideConfirm">{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="form.get('rePassword')?.hasError('required')">
          Podanie hasła jest wymagane.
        </mat-error>
        <mat-error *ngIf="form.get('rePassword')?.hasError('minlength')">
          Hasło powinno mieć minimum 4 znaki.
        </mat-error>
      </mat-form-field>

      <div class="buttons__space-between btn-margin-top">
        <button
          class="btn btn-outline-secondary"
          [routerLink]="'/auth/login'">
          Powrót
        </button>
        <button
          class="btn primary-button"
          (submit)="activateAccount()"
          [disabled]="loading">
          Aktywuj konto
        </button>
      </div>
      
    </form>

    <div *ngIf="sended">
      <span>
        Wiadomośc z linkiem do zresetowania hasła została wysłana na podany adres email. Prosze postępować zgodnie z instrukcją zawartą w wiadomości.
      </span>

      <button
        class="btn btn-outline-secondary btn-block btn-lg"
        [routerLink]="'/auth/login'"
      >Powrót</button>
    </div>

  </div>
</div>
