import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableHeadConfiguration, TableHeadTool } from '../table-display/table-head-tool';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ThisPlatformService } from '../../_services/this-platform.service';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {

  @Input()
  config: TableHeadConfiguration;

  @Output()
  onSearchText = new EventEmitter<string>();

  searchText$ = new Subject<string>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public p: ThisPlatformService,
  ) { }

  ngOnInit() {
    if(this.config.searchTool) {
      this.config.searchTool.text = ( this.config.searchTool.text || '' );
      this.searchText$.next(this.config.searchTool.text);
    }

    // setting delay for emitting text to filter  
    this.searchText$.pipe(
      debounceTime(600),
      distinctUntilChanged()
    )
    .subscribe(
      () => {
        this.onSearchText.emit() 
      }
    );
  }

  resolveToolClick($event, tht: TableHeadTool) {
    if (tht.click) {
      tht.click();
    } else {
      this.router.navigate([tht.link], { queryParams: tht.queryParams, relativeTo: this.route });
    }
  }

  getTrim(tool: TableHeadTool) {
    tool.text = ( tool?.text ? tool.text.trim() : '' );
    this.searchText$.next(tool?.text.trim()) 
  }

  ngOnDestroy() {
    this.searchText$.unsubscribe();
  }

}
