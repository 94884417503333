<div *ngIf="config && config.form; else noConfig">
  <form [formGroup]="config?.form">
    <mat-form-field appearance="outline">
      <mat-label>{{ config.label }}</mat-label>
      <mat-select
        formControlName="{{config.acceptFormControl}}"
        disableOptionCentering
        (selectionChange)="change.emit($event)"
      >
        <mat-option [value]="acceptationStatus.Accepted">Tak</mat-option>
        <mat-option [value]="acceptationStatus.Rejected">Nie</mat-option>
      </mat-select>
    </mat-form-field>
  
    <mat-form-field 
      *ngIf="(config.form.controls[config.acceptFormControl].value === acceptationStatus.Rejected)"
      appearance="outline"
    >
      <mat-label>{{ config.descriptionLabel }}</mat-label>
      <textarea
        matInput
        formControlName="{{config.descriptionFormControl}}"
        rows="1"
      ></textarea>
      <mat-error>Pole wymagane.</mat-error>
  
    </mat-form-field>
  </form>

  <div
    *ngIf="config.acceptation?.user"
    class="acceptation__accepted"  
  >
    <span *ngIf="config.acceptation?.status === acceptationStatus.Accepted">
      <b>{{ config.acceptLabel }}</b>
    </span>
    <span *ngIf="config.acceptation?.status === acceptationStatus.Rejected">
      <b>{{ config.rejectLabel }}</b>
    </span>
    <span 
      class="document__accepted--details"
    >{{ config.acceptation?.user | userDetails }}</span>
  </div>
</div>

<ng-template #noConfig>
  <div class="width__100">
    <span>Problem z załadowaniem akceptacji</span>
  </div>
</ng-template>
