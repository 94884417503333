<div 
  class="dialog__header"
  [ngClass]="config.backgroundCss"
>
  <h4 
    class="dialog__header--text header"
    [ngClass]="config.cancelButton ? 'width__80' : 'width__100'"
  >
    {{ config.headerText | translate }}
  </h4> 
  <button
    *ngIf="config.cancelButton"
    mat-icon-button
    class="dialog__back-btn"
    (click)="close.emit(true)"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
