import { Injectable } from "@angular/core";
import { ContractType, PriceList } from "./price-list.model";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { DictionaryService } from "../dictionary/dictionary.service";
import { DictionaryValues } from "../dictionary/dictionary.model";
import { UserService } from "../user/user.service";
import { ProductService } from "../product/product.service";
import { PerioidService } from "./perioid-service";
import { Product } from "../product/product.model";
import { Perioid } from "./perioid.model";
import { PriceListProductService } from "./price-list-product.service";
import { PriceListAssignment } from "./price-list-assignment.model";
import { MatDialog } from "@angular/material/dialog";
import { Company } from "../company/company.model";
import { Building } from "../building/building.model";

const url = environment.api;

@Injectable({
  providedIn: "root",
})
export class PriceListService {
  currentPriceList: PriceList;
  currentPriceListAssignments: PriceListAssignment[];

  products: Product[];
  contractPerioids: Perioid[];
  contractTypes: DictionaryValues[];
  public initState: boolean = false;

  public getPriceListName(priceList: PriceList): string {
    return priceList.name;
  }

  public searchPriceLists(idBuilding: number) {
    return this.http.get<PriceList[]>(`${url}price-list/search/${idBuilding}`);
  }

  public getPriceLists(idCompany: number = null, idBuilding: number = null) {
    return this.http.get<PriceList[]>(
      `${url}price-list/?idCompany=${idCompany ? idCompany : ""}&idBuilding=${
        idBuilding ? idBuilding : ""
      }`
    );
  }

  public getPriceListAssignment(id: number) {
    return this.http.get<PriceListAssignment>(
      `${url}price-list-assignment/${id}`
    );
  }

  public getPriceListAssignments(params?: {
    idPriceList?: number;
    idBuilding?: number;
    idCompany?: number;
    joinPriceList?: boolean;
  }) {
    let _url = `${url}price-list-assignment/`;

    let paramsArr = [];

    if (params) {
      if (params.idPriceList) {
        paramsArr.push(`idPriceList=${params.idPriceList}`);
      }

      if (params.idBuilding) {
        paramsArr.push(`idBuilding=${params.idBuilding}`);
      }

      if (params.idCompany) {
        paramsArr.push(`idCompany=${params.idCompany}`);
      }

      if (params.joinPriceList) {
        paramsArr.push(`joinPriceList=${params.joinPriceList}`);
      }
    }

    if (paramsArr.length > 0) {
      _url += "?";
    }

    _url += paramsArr.join("&");

    return this.http.get<PriceListAssignment[]>(_url);
  }

  public getPriceListById(id: number) {
    return this.http.get<PriceList>(`${url}price-list/${id}`);
  }

  createPriceList(priceList: PriceList) {
    return this.http.post(url + "price-list", priceList);
  }

  updatePriceList(priceList: PriceList) {
    return this.http.put(url + "price-list/" + priceList.id, priceList);
  }

  deletePriceList(id: number) {
    return this.http.delete(url + "price-list/" + id);
  }

  createPriceListAssignment(priceListAssignment: PriceListAssignment) {
    return this.http.post(url + "price-list-assignment", priceListAssignment);
  }

  updatePriceListAssignment(priceListAssignment: PriceListAssignment) {
    return this.http.put(
      url + "price-list-assignment/" + priceListAssignment.id,
      priceListAssignment
    );
  }

  deletePriceListAssignment(id: number) {
    return this.http.delete(url + "price-list-assignment/" + id);
  }

  public getPriceListContractTypeName(priceList: PriceList): string {
    let values: DictionaryValues[] =
      this.dictionaryService.getDictionaryValues("contract_types");

    if (!values) {
      return "";
    }

    let value = values.find((x) => x.value == priceList.contractType);
    return value ? value.key : "";
  }

  public async getPriceListsOneForAllContractTypes(idBuilding: number) {
    let contractTypes = await this.dictionaryService.getDictionaryValuesAsync(
      "contract_types"
    );

    let priceLists = await this.searchPriceLists(idBuilding).toPromise();

    let fn = (contractType) =>
      priceLists.find((pl) => pl.contractType == contractType.value);

    return contractTypes.filter(fn).map((ct) => {
      return {
        priceList: fn(ct),
        contractType: ct,
      };
    });
  }

  public async loadPriceList(id: number) {
    this.currentPriceList = await this.getPriceListById(id).toPromise();
    await this.initPriceList();
  }

  public async createNewPriceList() {
    this.currentPriceList = new PriceList();
    await this.initPriceList();
  }

  public async initPriceList() {
    await this.loadProducts();
    await this.loadDictionaries();
    await this.loadContractTypes();
    this.checkPriceList();

    this.initState = true;
  }

  public async loadPriceListAssignments(idPriceList: number) {
    this.currentPriceListAssignments = await this.getPriceListAssignments({
      idPriceList: idPriceList,
    }).toPromise();
  }

  async loadProducts() {
    if (!this.products)
      this.products = await this.productService.getProducts().toPromise();
  }

  async loadDictionaries() {
    if (!this.contractPerioids)
      this.contractPerioids = await this.perioidService
        .getPerioids()
        .toPromise();
  }

  async loadContractTypes() {
    if (!this.contractTypes)
      this.contractTypes =
        await this.dictionaryService.getDictionaryValuesAsync("contract_types");
  }

  checkPriceList() {
    for (let plProduct of this.currentPriceList.products) {
      let product = this.products.find((x) => x.id == plProduct.idProduct);

      for (let productProperty of this.productService.getProductPropertyUnique(
        product
      )) {
        if (
          !plProduct.productProperties.find(
            (x) => x.idProductProperty == productProperty.id
          )
        ) {
          plProduct.productProperties.push(
            this.priceListProductService.CreateProductProperty(
              productProperty,
              this.contractPerioids
            )
          );
        }
      }
    }
  }

  copyPriceList(id: number) {
    return this.http.post(url + `price-list/${id}`, {});
  }

  findPriceListAssignment(
    assignments: PriceListAssignment[],
    company: Company,
    building: Building,
    ct: ContractType
  ) {
    if (!assignments) {
      return;
    }

    let current = new Date().getTime();

    return assignments.find(
      (x) =>
        x.idCompany == company.id &&
        (x.allBuildings ||
          x.assignmentBuildings.find((a) => a.idBuilding == building.id)) &&
        x.priceList.contractType == ct &&
        current > new Date(x.dateFrom).getTime() &&
        current < new Date(x.dateTo).getTime()
    );
  }

  constructor(
    public productService: ProductService,
    public dictionaryService: DictionaryService,
    public perioidService: PerioidService,
    public priceListProductService: PriceListProductService,
    public userServ: UserService,
    private http: HttpClient,
    public dialog: MatDialog
  ) {}
}
