import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThisPlatformService } from '../../_services/this-platform.service';
import { ContentViewConfig } from './content-view.model';
import { ColumnDisplayService } from '../table-display/column-display.service';
import { RowDisplayService } from '../mobile-card-view/row-display.service';

@Component({
  selector: 'app-content-view',
  templateUrl: './content-view.component.html',
  styleUrls: ['./content-view.component.scss']
})
export class ContentViewComponent {

  @Input() 
  config: ContentViewConfig;

  @Output()
  onToggle: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public p: ThisPlatformService,
    public columnDisplayServ: ColumnDisplayService,
    public rowDisplayServ: RowDisplayService,
  ) { }

  ngOnInit() { }

  onToggleChange(event: any) {
    this.onToggle.emit(event.checked);
  }

  ngOnDestroy() { 
  }

}
