import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  /**
   * variable responsible for conditional display of the component content
   */
  @Input()
  show: boolean

  /**
   * variable supplies adding custom css properties 
   * for loader container for better displaying
   */
  @Input()
  customCss?: string = ""

  constructor() { }

  ngOnInit() {
    
  }

}
