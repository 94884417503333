<div *ngIf="!loading">
  <h1 mat-dialog-title class="info-dialog__background">
    {{
      buildingCompany.id
        ? "Edycja przypisania"
        : ("OPERATOR.ASSIGN" | translate)
    }}
  </h1>

  <div class="dialog-content custom-scrollbar">
    <mat-form-field>
      <mat-label>{{(buildings?.length > 0) ? 'Wybierz budynek' : 'Dodano wszystkie budynki'}}</mat-label>
      <mat-select
        [(ngModel)]="buildingCompany.idBuilding"
        name="idBuiding"
        [disabled]="buildingsSelectLock || buildings?.length == 0"
      >
        <mat-option *ngFor="let building of buildings" [value]="building.id">
          {{ buildingService.getBuildingDisplayName(building) }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="companyType === 1">
      <mat-label>{{ textChooseCompany }}</mat-label>
      <mat-select
        [(ngModel)]="buildingCompany.idCompany"
        name="idCompany"
        [disabled]="companySelectLock"
      >
        <mat-option *ngFor="let company of companies" [value]="company.id">
          {{ company.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="checkbox__container">
      <mat-checkbox
        *ngIf="(companyType === 1) && permissionManageCollocation"
        name="buildingCompany.collocation"
        [(ngModel)]="buildingCompany.collocation"
        class="checkbox__padding"
      >
        Kolokacja
      </mat-checkbox>

      <mat-checkbox
        *ngIf="(companyType === 1 && !buildingCompany.id) && permissionNotify"
        name="buildingCompany.notify"
        [(ngModel)]="buildingCompany.notify"
        class="checkbox__padding"
      >
        {{ "OPERATOR.NOTIFY" | translate }}
      </mat-checkbox>

      <mat-checkbox
        *ngIf="permissionManageDefaultBuilding"
        name="buildingCompany.default"
        [(ngModel)]="buildingCompany.default"
        class="checkbox__padding"
        color="primary"
      >Domyślny budynek</mat-checkbox>
    </div>

    <div *ngIf="!saveLock" class="buttons-container">
      <div class="buttons__space-between width__30">
        <button 
          class="btn btn-sm btn-outline-secondary" 
          (click)="dialogRef.close(false)"
        >Anuluj</button>

        <button
          *ngIf="buildingCompany.id && this.permissionEditBuildings"
          class="btn btn-sm btn-danger"
          (click)="remove()"
        >Usuń</button>
      </div>

      <div class="buttons__end width__70">
        <button 
          *ngIf="permissionEditBuildings"
          class="btn btn-sm primary-button" 
          (click)="save()"
        >
          {{buildingCompany.id ? 'Zapisz' : 'Dodaj'}}
        </button>
      </div>
    </div>
        
  </div>
</div>

<app-loader 
  [show]="saveLock || loading"
  customCss="loader__position loader__padding"
></app-loader>
