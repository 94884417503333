<app-main-nav maxWidth="800">

  <div *ngIf="legalId === 0" class="header">
    Zamówienie pomocy prawnej

  </div>
  <div *ngIf="legalId !== 0" class="header">
    Pomoc prawna {{legalServ.legalNumber(legalId)}}
  </div>

  <table class="width__100">
    <tr>
      <td colspan="2">
        <mat-form-field>
          <mat-label>Temat</mat-label>
          <input [(ngModel)]="legalServ.legal.topic" type="text" matInput>
        </mat-form-field>
      </td>
    </tr>

    <tr>
      <td colspan="2">
        <mat-form-field>
          <mat-label>Opis</mat-label>
          <textarea rows="2" [(ngModel)]="legalServ.legal.description" type="text" matInput></textarea>
        </mat-form-field>
      </td>
    </tr>

    <tr>
      <td colspan="2">
        <app-upload-attachment
        [settings]="attachmentSettings"
        [attachment]="legalServ.legal ? legalServ.legal.attachments : []"
        (attachmentAdded)="imageAdded($event)">
      </app-upload-attachment>
      </td>
    </tr>

    <tr>
      <td>
        <button class="btn btn-success" (click)="onClickPricing()">Zleć wycenę</button>
      </td>
      <td>
        <button class="btn btn-success" (click)="onClickSend()">Wyślij do działu prawnego</button>
      </td>
    </tr>

  </table>

  <div class="width__100 buttons__space-between space">
    <button class="btn secondary-button-outline" (click)="Cancel()">Anuluj</button>
    <button class="btn primary-button" (click)="Save()">Zapisz</button>
  </div>

  <app-comments 
    *ngIf="legalId > 0"  
    [commentsConfig]="commentsConfig"
    [idTableObject]="legalId"  
  ></app-comments>

</app-main-nav>
