import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {

  initTranslation: boolean = false;
  initTranslation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    ) { }

  getLanguageList() {
    return [
      {
        value: 'pl',
        name: 'Polski',
      },
    ];
  }

  getDefaultLanguage() {
    let language = this.translate.getBrowserLang()!;
    this.translate.setDefaultLang(language);

    this.translate.currentLang;

    return language;
  }

  setLanguage(setLang: string) {
    if (setLang !== null) {
      this.translate.use(setLang);
      return setLang;
    } else {
      return this.getDefaultLanguage();
    }
  }

  async setTranslation() {
    this.setInitLanguage();
  }

  private setInitLanguage() {
    this.setLanguage('pl');
    this.initTranslation = true;
    this.initTranslation$.next(true);
  }
}
