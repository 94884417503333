import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PriceList } from "../../price-list/price-list.model";
import { Product, PropertyGroup } from "../../product/product.model";
import { ProductService } from "../../product/product.service";
import { TemplateConverter, TemplateConverterOptions } from "../../_helpers/template-converter";
import { TechnicalConditionProductProperty } from "../../_models";
import { ProductProperty, ProductPropertyType } from "../../_models/product-property.model";
import { ProductSelectDisplayMode } from './product-select-display-mode';

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
})
export class ProductComponent implements OnInit {
  // @Input()
  // product: Product;
  fProduct = new FormControl("", [Validators.required]);
  fItem = new FormControl(null, [Validators.required]);
  fNewProduct = new FormControl("", [Validators.required, Validators.min(1)]);
  fItemKey = new FormControl("", [Validators.required, Validators.min(1), Validators.max(1024), Validators.pattern("^[0-9]*$")]);
  product: Product = new Product();
  products: Product[] = [];
  propertyGroups: PropertyGroup[] = [];

  validationError: boolean = false;

  // attachmentSettings: AttachmentSettings = {
  //   ...new AttachmentSettings(),
  //   multiple: false,
  //   objectGroupName: "technical_condition",
  //   uploadButtonText: 'Dodaj projekt do uzgodnienia',
  //   fileTypeFilter: '.pdf',
  //   inputId: 'filesProductProperty_'
  // }

  constructor(
    public dialogRef: MatDialogRef<ProductComponent>,
    public productServ: ProductService,
    @Inject(MAT_DIALOG_DATA) public data: {
      idProduct: number
      idBuilding: number,
      requirementDescription: string,
      priceList: PriceList,
      displayMode: ProductSelectDisplayMode
    }
  ) {}

  async ngOnInit() {
    this.productServ.newProduct = new Product();

    this.productServ
      .getProductsByIdBuilding(this.data.idBuilding)
      .subscribe((res) => {
        let prod = this.data.idProduct
          ? res.filter((p) => p.id === this.data.idProduct)
          : res;

        this.products = prod.filter(p => this.data.priceList.products.find(x => x.idProduct == p.id));

      });

      //await this.loadAttachmentAccept();
  }

  // async loadAttachmentAccept() {
  //   this.attachmentSettings.fileTypeFilter = await this.configServ.getConfigValue('technicalCondition.attachmentAccept');
  // }

  canSelectProperty() {
    return this.productServ.newProduct && this.data.displayMode != ProductSelectDisplayMode.AddOnlyProduct;
  }

  //TODO do wywalenia
  getInputType(type: ProductPropertyType): string {
    switch (type) {
      case ProductPropertyType.int:
        return "number";
      case ProductPropertyType.string:
        return "";
      case ProductPropertyType.bit:
        return "checkbox";
      case ProductPropertyType.combobox:
        return;
    }
  }

  isCheckbox(type: ProductPropertyType) {
    return ProductPropertyType.bit === type;
  }

  isCombobox(type: ProductPropertyType) {
    return ProductPropertyType.combobox === type;
  }

  isNumber(type: ProductPropertyType) {
    return ProductPropertyType.int === type;
  }

  // getPropertyById(group: PropertyGroup) {
  //   return group.productProperties.find(c => c.id === group.selectedProduct);
  // }

  onPropertySelect(item: PropertyGroup) {
    this.validationError = false;

    if (item.selectedProduct.itemKeyType == "int") {
      let range = this.priceListKeyRange(item.selectedProduct);
      this.fItemKey = new FormControl("", [Validators.required, Validators.min(range.min), Validators.max(range.max)]);
    }
  }

  /**
   * Event fired on prduct changed
   */
  onProductChange(event) {
    this.validationError = false;
    this.productServ.newProduct = Object.assign(new Product(), this.product);

    this.propertyGroups = this.product.propertyGroups.filter((x) => !x.hidden);

    this.productServ.newProduct.propertyGroups.forEach((group) => {
      if (group.productProperties.length === 1) {
        group.selectedProduct = group.productProperties[0];
      }
    });
  }

  cancel() {
    this.productServ.newProduct = null;
    this.dialogRef.close();
  }

  checkGroup(group: PropertyGroup) {
    if (group) {
      if (group.selectedProduct) {
        return 1;
      }

      if (!group.productProperties || group.productProperties.length == 0) {
        return 0;
      }
    }

    return -1;
  }

  save() {
    this.validationError = false;
    //Add new product or catch it on close dialog
    this.productServ.newProduct.properties = [];

    if (!this.productServ.newProduct) {
      this.validationError = true;
      return;
    }

    if(this.fItem.touched && this.fItem.invalid && this.fItem.hasError('required')) {
      this.validationError = true;
      return;
    }

    if (this.data.displayMode === ProductSelectDisplayMode.AddOnlyProduct) {
      this.validationError = true;
      this.dialogRef.close();
      return;
    }

    if (!this.productServ.newProduct.propertyGroups) {
      this.validationError = true;
      return;
    }

    let groups: PropertyGroup[] = [];

    for (let group of this.productServ.newProduct.propertyGroups) {
      switch(this.checkGroup(group)) {
        case -1:
          return;
        case 0:
          continue;
      }

      if (
        (
          (
            this.fNewProduct.errors && (
              group.selectedProduct.type == ProductPropertyType.combobox
              || group.selectedProduct.itemKeyType == ProductPropertyType.combobox)
          )
            ||
          (
            this.fItemKey.errors && (
              group.selectedProduct.itemKeyType == ProductPropertyType.int
            )
          )

        ) && !group.hidden
      ) {
        this.validationError = true;
        return;
      }

      groups.push(group);
    }



    for (let group of groups) {
      let {
        id,
        name,
        quantity,
        type,
        placeholder,
        typeOfCharge,
        defaultQuantity,
        priceListDefined,
        itemKey,
        attachmentRequired,
        attachFromIdProductProperty,
        itemKeyUnit,
        nameSimple
      } = group.selectedProduct;

      if (attachFromIdProductProperty) {
        itemKey = this.productServ.newProduct.propertyGroups.find(
          x => x.selectedProduct && x.selectedProduct.id == attachFromIdProductProperty
        ).selectedProduct.itemKey;
      }

      let property = new TechnicalConditionProductProperty();

      if (defaultQuantity) {
        quantity = defaultQuantity;
      }

      property.idProductProperty = id;
      property.idProductGroup = group.id;
      property.placeHolder = placeholder;
      property.type = type;
      property.quantity = quantity ? quantity : 0;
      property.typeOfCharge = typeOfCharge;
      property.priceListDefined = priceListDefined;
      property.itemKey = itemKey ? itemKey : "";
      property.attachmentRequired = attachmentRequired;
      property.attachFromIdProductProperty = attachFromIdProductProperty;

      if (itemKeyUnit) {
        property.itemKeyUnit = TemplateConverter.ConvertTemplateParameters(itemKeyUnit, property);
      }

      property.name =  TemplateConverter.ConvertTemplateParameters(name, property, {
        ...new TemplateConverterOptions(),
        removeUnassigned: false
      });

      property.nameSimple =  TemplateConverter.ConvertTemplateParameters(nameSimple, property, {
        ...new TemplateConverterOptions(),
        removeUnassigned: false
      });

      this.productServ.newProduct.properties.push(property);
    }

    this.dialogRef.close();
  }

  getItems(productProperty: ProductProperty) {
    let attachFrom: any;

    for (let pg of this.product.propertyGroups) {
      attachFrom = pg.productProperties.find(y => y.attachFromIdProductProperty == productProperty.id);

      if (attachFrom) {
        attachFrom = attachFrom.id;
        break;
      }
    }

    return this.data.priceList.products.find(x => x.idProduct == this.product.id)
      .productProperties.find(x => x.idProductProperty == productProperty.id || x.idProductProperty == attachFrom).productPropertyItems;
  }

  priceListKeyRange(productProperty: ProductProperty) {
    let items = this.getItems(productProperty);
    let min = Math.min(...items.map(x => x.keyFrom));
    let max = Math.max(...items.map(x => x.keyTo));

    return {
      min: min,
      max: max
    }
  }

  priceListKeyRangeName(productProperty: ProductProperty) {
    let range = this.priceListKeyRange(productProperty);
    return range.min + ' - ' + range.max;
  }

  getFC(productProperty: ProductProperty) {
    let range = this.priceListKeyRange(productProperty);
    return new FormControl("", [Validators.required, Validators.min(range.min), Validators.max(range.max)]);
  }

  // isDisabled(item: PropertyGroup) {
  //   return item.selectedProduct.attachment && item.selectedProduct.attachment.length > 0
  // }

  // getAttachmentSettings(item: PropertyGroup) {
  //   return {
  //     ...this.attachmentSettings,
  //     //Generate unique input id
  //     inputId: this.attachmentSettings.inputId + this.product.id + '_' + item.selectedProduct.id
  //   }
  // }

  isProductCheckbox(item: PropertyGroup) {
    return item.selectedProduct && this.isCheckbox(item.selectedProduct.type) && item.productProperties.length == 1;
  }
}
