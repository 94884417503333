import { FormControl } from '@angular/forms';
import { ConfirmDialogType } from './confirm-dialog-parameters';

export class ConfirmDialogModel {
  constructor(
    public title: string, 
    public message: string, 
    public consentRequired?: ConsentRequired[], 
    public noButtonDisabled?: boolean, 
    public noButtonText?: string, 
    public yesButtonText?: string,   
    // public success?: boolean,
    // public info?: boolean,
    // public danger?: boolean,
    public confirmDialogType?: ConfirmDialogType ) {
  }
}

export class ConsentRequired{
  text: string;
  type: ConsentType;
  required: boolean;
  value? : any;
  fModel? : FormControl;
  errorText?: string;
  url?: string;
  textUrl?: string;
}


export enum ConsentType {
  Checkbox = 1,
  Text = 2,
}