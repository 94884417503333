import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Comment, CommentsIdentifier } from './comments.module';

const url = environment.api;

@Injectable({
  providedIn: 'root'
})
export class CommentsService {
  //comments: Comment[] = [];

  public newCommentsSubject = new Subject<CommentsIdentifier>();

  constructor(private http: HttpClient) { }

  getComments(tableName: string, idTableObject: number) {
    return this.http.get<Comment[]>(url + 'comment?tableName=' + tableName + '&idTableObject=' + idTableObject);
  }

  createComment(comment: Comment) {
    comment.idTableObject = Number(comment.idTableObject);
    return this.http.post(url + 'comment', comment);
  }

  deleteComment(comment: Comment) {
    return this.http.delete(url + 'comment/' + comment.id);
  }

  async loadComments(tableName: string, idTableObject: number): Promise<Comment[]> {
    return await this.getComments(tableName, idTableObject).toPromise();
  }

  getNewComments(): Observable<CommentsIdentifier> {
    return this.newCommentsSubject.asObservable();
  }
}
