export class AttachmentViever {
  public static IsSupportedFileType(fileName: string) {
    if (!fileName) {
      return false;
    }

    let nameArr = fileName.split('.');
    let ext = nameArr.length > 0 ? nameArr[nameArr.length - 1].toLowerCase() : '';

    return ext == 'pdf';
  }
}