import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayLength'
})
export class ArrayLengthPipe implements PipeTransform {

  constructor() { }

  transform(array: any[]) {
    if(array?.length) {
      return `(${array.length})`
    } else {
      return null;
    }
  }

}
