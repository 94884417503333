<ng-container *ngIf="(p.isMobile$ | async)">
  <!-- color="warn" potrzebny dla panel operatora -->
  <button
    color="primary" 
    *ngIf="!isMenuOpen"
    mat-mini-fab 
    aria-label="Example icon button with a menu icon"
    class="action-sheet-menu"
    (click)="openBottomSheet()"
  >
    <mat-icon>{{ iconName }}</mat-icon>
  </button>
</ng-container>
