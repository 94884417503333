import { DatePipe } from "@angular/common";
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Observable, Subject } from "rxjs";
import { ComponentsService } from "../../_components/components.service";
import { ConfirmDialogType } from "../../_components/confirm-dialog/confirm-dialog-parameters";
import { BuildingService } from "../../building/building.service";
import { Company } from "../../company/company.model";
import { CompanyService } from "../../company/company.service";
import {
  PriceListAssignment,
  PriceListAssignmentBuilding,
} from "../price-list-assignment.model";
import { PriceListService } from "../price-list.service";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { FormControl, Validators } from "@angular/forms";
import { map, startWith } from "rxjs/operators";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { PriceList } from "../price-list.model";
import { CompanyType } from "../../company/enum/type.enum";

@Component({
  selector: "app-add-edit-price-list-assignment",
  templateUrl: "./add-edit-price-list-assignment.component.html",
  styleUrls: ["./add-edit-price-list-assignment.component.scss"],
})
export class AddEditPriceListAssignmentComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddEditPriceListAssignmentComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: PriceListAssignmentData,
    public buildingService: BuildingService,
    public companyService: CompanyService,
    public componentsServ: ComponentsService,
    public priceListService: PriceListService,
    private datePipe: DatePipe
  ) {}

  buildingsAllForAssign: PriceListAssignmentBuilding[] = [];
  companiesAll: Company[] = [];

  dateFromChange: string;
  dateToChange: string;

  buildingsChange: number[] = [];

  saveOperationLoader = false;

  tmpAssignment: PriceListAssignment = new PriceListAssignment();

  loading = false;

  assignedBuildingFilter = "";

  separatorKeysCodes: number[] = [ENTER, COMMA];
  fBuildingSelect: FormControl;
  fBuildingIds: FormControl;
  @ViewChild("buildingSelectInput")
  buildingSelectInput: ElementRef<HTMLInputElement>;
  buildingsAllForAssignFiltered: Observable<PriceListAssignmentBuilding[]>;

  priceLists: PriceList[] = [];
  showPriceLists: boolean = false;
  buildingAssignment: PriceListAssignmentBuilding = new PriceListAssignmentBuilding();

  ngOnInit() {
    if (this.data.idAssignment) {
      this.loading = true;

      this.priceListService
        .getPriceListAssignment(this.data.idAssignment)
        .subscribe(
          (assignment) => {
            this.dateFromChange = this.datePipe.transform(
              new Date(assignment.dateFrom),
              "yyyy-MM-dd"
            );
            this.dateToChange = this.datePipe.transform(
              new Date(assignment.dateTo),
              "yyyy-MM-dd"
            );
            this.tmpAssignment.allBuildings = assignment.allBuildings;
            this.tmpAssignment.idCompany = assignment.idCompany;
            this.tmpAssignment.id = assignment.id;
            this.tmpAssignment.assignmentBuildings = [
              ...assignment.assignmentBuildings,
            ];
        },
        (err) => {
          console.log(err);
          this.loading = false;
        },
        () => { 
          this.loading = false;
          this.initForm(); 
        }
      );
    } else {
      let currDate = new Date();
      this.dateFromChange = this.datePipe.transform(
        new Date(currDate.getFullYear(), currDate.getMonth(), 1),
        "yyyy-MM-dd"
      );
      this.dateToChange = this.datePipe.transform(
        new Date(2099, 0, 1),
        "yyyy-MM-dd"
      );

      if(this.data.idBuilding && this.data.idAssignment == null) {
        this.buildingService.getBuilding(this.data.idBuilding).subscribe( (building) => {
          this.buildingAssignment.idBuilding = building.id;
          this.buildingAssignment.building = building;
          this.tmpAssignment.assignmentBuildings.push(this.buildingAssignment);
        })
      }

      this.initForm();
    }

    this.tmpAssignment.idPriceList = this.data.idPriceList;

    this.buildingService.getBuildings().subscribe((list) => {
      this.buildingsAllForAssign = list.map((building) => {
        return {
          ...new PriceListAssignmentBuilding(),
          idBuilding: building.id,
          building: building,
        };
      });
    });

    this.companyService.getCompaniesType({
      type: [CompanyType.Operator]
    }).subscribe((list) => {
      this.companiesAll = list;
    });

    if(this.data.idPriceList == null) {
      this.loading = true;
      this.priceListService.getPriceLists().subscribe(
        priceLists => {
          this.priceLists = priceLists;
          this.showPriceLists = true;
          this.loading = false;
        }
      );
    }

  }

  save() {
    if (this.dateFromChange)
      this.tmpAssignment.dateFrom = new Date(this.dateFromChange);

    if (this.dateToChange)
      this.tmpAssignment.dateTo = new Date(this.dateToChange);

    if (!this.tmpAssignment.idCompany) {
      this.componentsServ.ShowMessage({
        title: "",
        message: "Należy wybrać operatora",
      });
      return;
    }

    let observableObj: Observable<object>;
    this.saveOperationLoader = true;

    if (this.tmpAssignment.id) {
      observableObj = this.priceListService.updatePriceListAssignment(
        this.tmpAssignment
      );
    } else {
      observableObj = this.priceListService.createPriceListAssignment(
        this.tmpAssignment
      );
    }

    this.operationResult(observableObj);
  }

  remove() {
    this.componentsServ.ShowDialog(
      "Potwierdzenie usunięcia",
      "Czy na pewno chcesz usunąć CAŁE przypisanie cennika?",
      () =>
        this.operationResult(
          this.priceListService.deletePriceListAssignment(this.tmpAssignment.id)
        ),
      ConfirmDialogType.Danger
    );
  }

  operationResult(obj: Observable<Object>) {
    obj.subscribe(
      (result) => {
        this.saveOperationLoader = false;
        this.dialogRef.close(true);
      },
      (error) => this.errorMsg(error)
    );
  }

  errorMsg(error) {
    this.saveOperationLoader = false;
    this.componentsServ.ShowErrorMsg(error);
  }

  compareBuildingAssignment(
    item1: PriceListAssignmentBuilding,
    item2: PriceListAssignmentBuilding
  ) {
    return item1 && item2
      ? item1.idBuilding === item2.idBuilding
      : item1 === item2;
  }

  displayFn(plBuilding: PriceListAssignmentBuilding): string {
    return plBuilding && plBuilding.building ? plBuilding.building.name : "";
  }

  removeAssignedBuilding(
    assignmentBuilding: PriceListAssignmentBuilding
  ): void {
    const index =
      this.tmpAssignment.assignmentBuildings.indexOf(assignmentBuilding);

    if (index >= 0) {
      this.componentsServ.ShowDialog(
        "Modyfikacja przypisania",
        "Potwierdzasz usunięcie przypisania budynku " +
          assignmentBuilding.building.name +
          "?",
        () => this.tmpAssignment.assignmentBuildings.splice(index, 1),
        ConfirmDialogType.Danger
      );
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let plab: PriceListAssignmentBuilding = event.option.value;

    if (
      !this.tmpAssignment.assignmentBuildings.find(
        (x) => x.idBuilding == plab.idBuilding
      )
    ) {
      this.tmpAssignment.assignmentBuildings.push(event.option.value);
    }

    this.buildingSelectInput.nativeElement.value = "";
    this.fBuildingSelect.setValue(null);
  }

  private _filter(value: string): PriceListAssignmentBuilding[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.buildingsAllForAssign.filter(
        (plb) =>
          plb &&
          plb.building &&
          !this.tmpAssignment.assignmentBuildings.find(
            (plbAssigned) => plbAssigned.idBuilding == plb.idBuilding
          ) &&
          plb.building.name.toLowerCase().includes(filterValue)
      );
    } else {
      return this.buildingsAllForAssign.filter(
        (plb) =>
          !this.tmpAssignment.assignmentBuildings.find(
            (plbAssigned) => plbAssigned.idBuilding == plb.idBuilding
          )
      );
    }
  }

  onAllBuildingsChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.fBuildingSelect.disable();
      this.fBuildingIds.disable();
    } else {
      this.fBuildingSelect.enable();
      this.fBuildingIds.enable();
    }
  }

  initForm() {
    this.fBuildingSelect = new FormControl(
      { value: null, disabled: this.tmpAssignment.allBuildings },
      Validators.required
    );
    this.fBuildingIds = new FormControl(
      { value: [], disabled: this.tmpAssignment.allBuildings },
      Validators.required
    );
    this.buildingsAllForAssignFiltered = this.fBuildingSelect.valueChanges.pipe(
      startWith(""),
      map((value) =>
        typeof value === "string" ? value : value ? value.building.name : ""
      ),
      // map((value) => {
      //   if (typeof value === "string") {
      //     return value;
      //   }else {
      //     console.log(value);
      //     return "value.building.name";
      //   }
      // }),
      map((name) => this._filter(name))
    );
  }
}

export class PriceListAssignmentData {
  assignment: PriceListAssignment;
  idPriceList: number;
  idAssignment: number;
  idBuilding?: number;
  companies?: Subject<Company[]>;
}
