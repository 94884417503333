import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TablePaginatorParameters } from './table-paginator-parameters';
import { MetaDataParameters } from '../../_models/list-data.interface';
import { ThisPlatformService } from '../../_services/this-platform.service';

@Component({
  selector: 'app-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.scss']
})
export class TablePaginatorComponent {

  /** Initialization MatPaginator for setting custom labels without additional services */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /** Object with pagination config parameters based on TablePaginatorParameters */
  @Input()
  paginatorConfig: TablePaginatorParameters = new TablePaginatorParameters();

  /** Emitted event of pagination change in table based on MetaDataParameters */
  @Output()
  paginationChange: EventEmitter<MetaDataParameters> = new EventEmitter<MetaDataParameters>();

  /** Helper object for emitting pagination changes */
  paginationParameters: MetaDataParameters = new MetaDataParameters();

  constructor(
    public p: ThisPlatformService,
  ) { }

  ngOnInit() {
    this.setMatPaginatorLabels();
  }

  /** Method for handling pagination changes and emitting values to parent component */
  handlePageEvent(e: PageEvent) {
    this.paginatorConfig.length = e.length;
    this.paginatorConfig.pageSize = this.paginationParameters.take = e.pageSize;
    this.paginatorConfig.pageIndex = this.paginationParameters.page = e.pageIndex;

    this.paginationChange.emit(this.paginationParameters);
  }

  /** Method for setting custom labels in mat paginator */
  setMatPaginatorLabels() {
    this.paginator._intl.itemsPerPageLabel = 'Ilość wierszy na stronę';
    this.paginator._intl.nextPageLabel = 'Następna strona';
    this.paginator._intl.firstPageLabel = 'Pierwsza strona';
    this.paginator._intl.lastPageLabel = 'Ostatnia strona';
    this.paginator._intl.previousPageLabel = 'Poprzednia strona';

    // method needed for changing range label from 'of' to own translate
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      const start = page + 1;
      const end = Math.floor(length / pageSize) + 1;
      return `Strona ${start} z ${end}`;
    };
  }

}

