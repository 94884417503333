import { Company } from "../../company/company.model";
import { User } from "../../user/user.model";

export class UserChangeConfig {
  currentUser: User;
  header?: string = 'Zmiana osoby';
  currentUserLabel?: string = 'Obecny użytkownik';
  buttonLabel?: string = "Zmień osobę";
  formLabel?: string = "Nowa osoba";
  onlyOwnCompany: boolean = false;
  currentCompany?: Company;
}