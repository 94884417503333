import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productValue'
})
export class ProductValuePipe implements PipeTransform {

  transform(productProp: any, ...args: any[]): any {
    return productProp.quantity;
  }

}
