import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CanvasConfig } from './canvas-config.model';
import SignaturePad from 'signature_pad';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SignerType } from '../../_models/signature.model';
import { TranslateService } from '@ngx-translate/core';
import { SignatureService } from '../../_services/signature.service';
import { SignaturePadConfiguration } from '../../_models/signaturePadConfig.model';
import { ThisPlatformService } from '../../_services/this-platform.service';

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss']
})
export class CanvasComponent {
  
  signaturePad!: SignaturePad;
  signatureImg!: string;
  @ViewChild('canvas') canvasEl!: ElementRef;

  @Input()
  signaturePadConfig: SignaturePadConfiguration;

  @Input()
  type: SignerType;

  @Output()
  signature: EventEmitter<SignaturePad> = new EventEmitter<SignaturePad>;

  @Output()
  personalData: EventEmitter<FormGroup> = new EventEmitter<FormGroup>;

  signerForm: FormGroup;

  signatureNeeded: boolean = false;
  subscrpiton: Subscription = new Subscription();

  config: CanvasConfig = new CanvasConfig();

  constructor(
    private translate: TranslateService,
    private signatureServ: SignatureService,
    private p: ThisPlatformService,
  ) {}

  ngOnInit() {
    this.initForm();
    this.setSignaturePadDimensions();

    this.subscrpiton.add(
      this.signerForm.valueChanges.subscribe(() => {
        this.personalData.emit(this.signerForm);
      })
    );
    
    this.subscrpiton.add(
      this.signatureServ.validation$.subscribe(validate => {
        if(!validate) {
          this.signerForm.markAllAsTouched();
        }

        if(this.signaturePad?.isEmpty()) {
          this.signatureNeeded = !validate;
        } else {
          this.signatureNeeded = false;
        }
      })
    );
    
  }

  ngAfterViewInit() {
    document.getElementById('person').style.width = this.config.width + "px";

    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
    this.signaturePad.addEventListener("beginStroke", () => {
      this.signature.emit(this.signaturePad);
      this.signatureNeeded = false;
    }, { once: false });
  }

  startDrawing(event: Event) {
    // works in device not in browser
 }

  moved(event: Event) {
    // works in device not in browser
  }

  clearPad() {
    this.signaturePad.clear();
  }

  private initForm() {
    this.signerForm = new FormGroup({
      personalData: new FormControl('',[Validators.required]),
      signerType: new FormControl(this.type,[Validators.required])
    });
  }

  toUppercase(text: string) {
    return this.translate.instant(text.toUpperCase());
  }

  setSignaturePadDimensions() {
    this.subscrpiton.add(
      this.p.isMobile$.subscribe((mobile) => {
        if(mobile) {
          this.config = {
            width: "280",
            height: "210",
          };
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscrpiton.unsubscribe();
  }

}
