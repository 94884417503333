import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../user/user.model';

@Pipe({
  name: 'userDetails'
})
export class UserDetailsPipe implements PipeTransform {

  /**
   * Transform user object to string with details 
   * @param user object based on user model
   * @returns string with user details
   */
  transform(user: User): unknown {
    const none = '---';
    const suffix = '\n';

    if(user) {
      let details = [];
      
      details.push((user?.firstName ?? none));
      details.push((user?.lastName ?? none) + suffix);
      details.push((user?.email ?? none) + suffix);
      details.push(user?.company?.name);

      return details.join(' ');
    }
    return none;
  }

}
