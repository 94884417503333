import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Utils } from '../_helpers/utils';
import { Consent, consentQueryParams} from '../_models/consent.model';

const url = environment.api;

@Injectable({
  providedIn: 'root'
})
export class ConsentService {

  constructor(
    private http: HttpClient,
  ) { }


  getConsents(_params: consentQueryParams) {
    return this.http.get<Consent[]>(url + 'consent', { params: Utils.httpParams(_params) });
  }

}


