import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../user/user.model';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

  @Input()
  user: User;

  @Input()
  personId: number;

  @Input()
  big: boolean;

  @Input()
  attachment;

  backgroundColor: string;
  person: User;

  constructor(
    private userServ: UserService
  ) { }

  async ngOnInit() {

    if(this.user) {
      this.backgroundColor = this.setUserBackgroundColor(this.user.email);
    }

    if( this.personId ) {
      this.userServ.users.filter( p => {
        if(p.id == this.personId) {
          this.person = p;
          return this.backgroundColor = this.setUserBackgroundColor(p.email)
        }
      })
    }
  }

  setUserBackgroundColor(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var color = '#';
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xFF;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  }

}
