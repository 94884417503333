<div class="thumbnail__container">
  <div 
    *ngIf="settings.edit"  
    class="thumbnail__input"
  >
    <label [for]="settings.inputId">
      <div *ngIf="!uploadingLoader"
        class="btn primary-button-flat margin-bottom"
        (click)="fileUpload($event)"
      >
        {{ 
          ((uploadedThumbnailSrc.length > 0) || thumbnail?.filePath) ? 
          settings.changeButtonText : 
          settings.uploadButtonText 
        }}
      </div>
    
      <div 
        *ngIf="settings.uploadDescription" 
        class="thumbnail__info"
      >
        {{ settings.uploadDescription }}
      </div>
    </label>
  </div>

  <div 
    *ngIf="!settings.edit && thumbnail?.filePath" 
    class="thumbnail__info"
  >Logo</div>
  
  <div class="thumbnail__img">
    <div 
      [ngClass]="(p.isMobile$ | async) ? 'thumbnail__img--mobile' : 'thumbnail__img--desktop'"  
    >
      <img 
        *ngIf="uploadedThumbnailSrc.length > 0"
        [src]="uploadedThumbnailSrc" 
        alt="Current uploaded logo"
      />

      <img 
        *ngIf="thumbnail?.filePath"
        [src]="thumbnail?.filePath" 
        alt="Uploaded logo"
      />
    </div>

    <div 
      *ngIf="settings.edit" 
      class="thumbnail__delete"
    >
      <button 
        *ngIf="thumbnail?.filePath || uploadedThumbnailSrc.length > 0"
        mat-icon-button
        (click)="onRemoveFile()"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
</div>

<input
  #imageInput  
  [name]="settings.inputName"
  [id]="settings.inputId"
  class="d-sm-none d-none invisible" 
  type="file" 
  (change)="onAddFile($event)"
  [accept]="settings.fileTypeAccept"
>

<app-loader
  [show]="uploadingLoader"
></app-loader>