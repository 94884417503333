// import { BuildingCompany } from '../building/building-company/building-company.model';
// import { Building } from '../building/building.model';
// import { PriceListAssignment } from '../price-list/price-list-assignment.model';
// import { ContractType, PriceList } from '../price-list/price-list.model';
// import { Attachment } from '../_models/attachment.model';
// import { CompanyType } from './enum/type.enum';

export class Company {
  id: number;
  name: string;
  street: string;
  postCode: string;
  city: string;
  nip: string;
  email: string;
  telephone: string;
  status: number;
  isOperator: boolean;
  type: number;
  description: string;
  whitelist: string;
  companyBuildings: any;
  logo: any;

  companyExtension: CompanyExtension;
  attachmentsList: any[];

  //Pola wyliczne w locie:
  priceListTypesActive: {
    active: boolean;
    type: any;
    assignment: any;
  }[];

  assignedCompanies: AssignedCompanies[];
}

export class CompanyExtension {
  idSyncCrm: number;
  idSyncCrmClientRenewal: number;
  orderRealizationAttachmentRequired: boolean;
  orderProjectNumberRequired: boolean;
  idSyncCrmClientOrderContract: number;
  escallationEmailAddress: string;
  idUserDefault?: number;
  idWarehouseDefault?: number;
}

export class CompanyBuilding {
  id: number;
  idBuilding: number;
  idCompany: number;
  collocation: boolean;
  building: any;
  company: Company;
}

export enum AttachmentTypeCompany {
  BuildingsList = 1,
  Logo = 2,
}

export class AssignedCompanies {
  assignedCompany: Company;
  dateCreated: Date
  idCompany:number;
  idCompanyAssigned: number;
}

export class AssignedCompaniesUpdate {
  idCompanyAssigned: number;
  deleted?: boolean;
}

export interface CompanyQueryParams {
  user?: number;
  provider?: boolean;
  type?: any[];
  idBuilding?: number;
}
