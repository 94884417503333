

<ng-container *ngIf="!refresh">
  <div 
    *ngIf="(
      config.addTimePermission || config.addTimeAllPermission) && 
      !(p.isMobile$ | async) &&
      !(config.readonly)
    "
    class="time__add"  
  >
    <button 
      (click)="onClickAddRealizationTime(null)"
      class="btn btn-sm primary-button"
    >+ Dodaj czas realizacji</button>
  </div>
  <table [ngClass]="{'margin__top--8': config.readonly}">
    <ng-container *ngIf="timeRealization.length > 0; else noData">
      <ng-container *ngIf="!(p.isMobile$ | async); else mobileView">
        <tr>
          <td class="width__10">Data</td>
          <td class="width__10">Czas od</td>
          <td class="width__10">Czas do</td>
          <td class="width__20">Opis</td>
          <td class="width__20">Użytkownik</td>
          <td class="width__10">Kategoria</td>
          <td *ngIf="config.canDelete && !config.readonly" class="width__10"></td>
        </tr>
        <tr 
          *ngFor="let time of timeRealization" 
          (click)="onClickAddRealizationTime(time.id, $event)"
          class="pointer"  
        >
          <td class="width__10">{{ time?.date | date: "dd.MM.yyyy" }}</td>
          <td class="width__10">{{ time?.timeStart }}</td>
          <td class="width__10">{{ time?.timeEnd }}</td>
          <td class="width__20">{{ time?.description?.length>0 ? time.description : '---' }}</td>
          <td class="width__20">{{ time?.user | userDetails }}</td>
          <td class="width__10">{{ dictionaryServ.getName(this.dictionaryServ.timeType,time.type) | translate }}</td>
          <td 
            *ngIf="config.canDelete && !config.readonly" 
            class="width__10 time__delete"
          >
            <mat-icon 
              class="time__delete time__delete--icon" 
              (click)="onDeleteTime({idObject: time.id, event: $event})"
            >delete_outline</mat-icon>
          </td>
        </tr>
      </ng-container>
      <ng-template #mobileView>
        <div class="time__mobile">
          <app-mobile-card-view
            [rows]="of(timeRealization)"
            [rowsDisplay]="rowServ.realizationTimeRows"
            [parameters]="rowServ.realizationTimeParameters"
            [customCss]="'custom-card'"
            (deleteItem)="onDeleteTime($event)"
          ></app-mobile-card-view>
        </div>
      </ng-template>
    </ng-container> 
    <ng-template #noData>
      <tr>
        <td colspan="4">
          <div class="time__no-data">
            <span>Brak wyników do wyświetlenia</span>
            <mat-icon class="time__no-data--icon">search_off</mat-icon>
          </div>
        </td>
      </tr>
    </ng-template>
  </table>
</ng-container>
<app-loader [show]="refresh"></app-loader>