import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ThisPlatformService {

  renderMobile: boolean = false;

  constructor(
    public platform: Platform,
    public breakpointObserver: BreakpointObserver
  ) { }

  isMobile$: Observable<boolean> = this.breakpointObserver
    .observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      // Breakpoints.Medium
    ])
    .pipe(
      map((result) => {
        this.renderMobile = result.matches
        return result.matches
      }),
      shareReplay()
    );

}
