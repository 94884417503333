<div class="container__center">
  <div class="content">
    <div class="company-logo-container">
      <img [src]="appInstanceService.getAppAssetsUrl('logo')">
    </div>

    <!-- <div class="divider"></div> -->

    <div class="header">
      <p>Zaloguj się do panelu</p>
    </div>

    <form [formGroup]="form" (ngSubmit)="onLogin()">

      <div *ngIf="errorMessage" class="error-container">
        <span>{{errorMessage}}</span>
      </div>

      <mat-form-field appearance="outline" color="primary" >
        <mat-label>Login</mat-label>
        <input
          matInput
          formControlName="email"
          placeholder="Podaj email"
        >
      </mat-form-field>

      <mat-form-field appearance="outline" color="primary">
        <mat-label>Hasło</mat-label>
        <input
          (keyup.enter)="onLogin()"
          matInput type="password"
          formControlName="password"
          [type]="hide ? 'text' : 'password'"
          placeholder="Podaj hasło"
        >
        <mat-icon matSuffix (click)="hide=!hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>

      <button
        class="btn primary-button btn-lg width__100"
        (submit)="onLogin()"
        [disabled]="loading">
        Zaloguj się
      </button>
    </form>

    <div class="forgot-password">
      <a [routerLink]="'/auth/reset-password'">Zapomniałem hasła</a>
    </div>

    <div class="footer">
      Copyright © {{ currentYear }} by Space Technology Integrator 
      <br>
      v. {{ version }}
    </div>
  </div>
</div>

