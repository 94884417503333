<div class="kanban-btn">
  <mat-label 
    class="kanban-btn__label-before"
    ngClass="labelBeforeSlide"
  >
    {{config?.labelBefore | translate}}
  </mat-label>
  
  <mat-slide-toggle
    [color]="'primary'"
    [checked]="config?.value"
    (change)="onClickToggle($event)"
  ></mat-slide-toggle>
  
  <mat-label
    class="kanban-btn__label-after"
    ngClass="labelAfterSlide"
  >
    {{config?.labelAfter | translate}}
  </mat-label>
</div>