import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';import { PriceList } from './price-list.model';
import { Product } from '../product/product.model';
import { PriceListProduct } from './price-list-product.model';
import { Perioid } from './perioid.model';
import { ProductProperty } from '../_models/product-property.model';
import { PriceListProductPerioid } from './price-list-product-perioid.model';
import { PriceListProductProperty } from './price-list-product-property.model';
import { PriceListProductPropertyItemPerioid } from './price-list-product-property-item-perioid.model';
import { PriceListProductPropertyItem } from './price-list-product-property-item.model';
import { ProductService } from '../product/product.service';

const url = environment.api;

@Injectable({
  providedIn: 'root'
})
export class PriceListProductService {
  constructor(
    private http: HttpClient,
    private productService: ProductService
  ) {}

  public CreateProductPropertyItem(perioids: Perioid[]): PriceListProductPropertyItem {
    return {
      ...new PriceListProductPropertyItem(),
      key: "",
      keyFrom: 0,
      keyTo: 0,
      productPropertyPerioids: perioids.map(perioid => {
        return {
          ...new PriceListProductPropertyItemPerioid(),
          idPerioid: perioid.id,
          price: 0,
          basePrice: 0,
          finalPrice: 0
        }
      }),
    };
  }

  public CreateProductProperty(productProperty: ProductProperty, perioids: Perioid[]) {
    return {
      ...new PriceListProductProperty(),
      idProductProperty: productProperty.id,
      enabled: true,
      productPropertyItems: [
        this.CreateProductPropertyItem(perioids)
      ]
    }
  }

  public AddNewProductForPriceList(priceList: PriceList, product: Product, perioids: Perioid[]) {
    if (priceList.products.find(x => x.idProduct == product.id)) {
      return;
    }

    let plProduct: PriceListProduct = {
      ...new PriceListProduct(),
      idProduct: product.id,
      basePriceModify: false
    };

    plProduct.productPerioids = perioids.map(perioid => {
      return {
        ...new PriceListProductPerioid(),
        enabled: true,
        idPerioid: perioid.id,
      }
    });

    plProduct.productProperties = this.productService.getProductPropertyUnique(product).map(
      pp => this.CreateProductProperty(pp, perioids)
    );

    priceList.products.push(plProduct);
  }
}
