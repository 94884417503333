import { MetaDataParameters } from "../../../_models/list-data.interface";
import { FilterOptionValue } from "./filter-button.model";
import { TaskPriority } from "../../../task/enums/task-priority.enum";
import { TaskTopic } from "../../../task/enums/task-topic.enum";
import { Language } from "../../../_enums/language.enum";

/**
 * Model for storing filter parameters in localstorage
 */
export class FilterRequestParameters {
  technicalConditionsFilterRequest: TechnicalConditionsFilterRequest;
  orderFilterRequest: OrderFilterRequest;
  assistanceFilterRequest: AssistanceFilterRequest;
  taskFilterRequest: TaskFilterRequest;
  warehouseStockFilterRequest: WarehouseStockFilterRequest;
  documentFilterRequest: DocumentFilterRequest;
  articleFilterRequest: ArticleFilterRequest;
}


/**
 * Base request model for filter conditions
 */
export class FilterRequest extends MetaDataParameters {
  idBuilding?: number | FilterOptionValue;
  idCompany?: number;
  status?: number[] | string[] | FilterOptionValue[];
  search?: string;
}

export class TechnicalConditionsFilterRequest extends FilterRequest {
  dateCreatedGreaterThan?: string;
  dateCreatedLessThan?: string;
  contractType?: number;
}

export class OrderFilterRequest extends FilterRequest {
  contractType?: number;
  idUserResponsible?: number;
  datePlacingGreaterThan?: string;
  datePlacingLessThan?: string;
}

export class AssistanceFilterRequest extends FilterRequest {
  dateExpectedGreaterThan?: string;
  dateExpectedLessThan?: string;
}

export class TaskFilterRequest extends FilterRequest {
  dateCreatedGreaterThan?: string;
  dateCreatedLessThan?: string;
  priority?: TaskPriority[] | FilterOptionValue[];
  idTopic?: TaskTopic[] | FilterOptionValue[];
  idExecutor?: number;
  idCompanyExecutor?: number;
  idCompanyTenant?: number;
  language: string = Language.PL;
  statusManage?: number[] | FilterOptionValue[];
}

export class WarehouseStockFilterRequest extends FilterRequest {
  dateCreatedGreaterThan?: string;
  dateCreatedLessThan?: string;
  id?: number[] | FilterOptionValue[];
  storageStatus?: any[] | FilterOptionValue[];
}

export class DocumentFilterRequest extends FilterRequest {
  dateCreatedGreaterThan?: Date;
  dateCreatedLessThan?: Date;
  // dateOperationGreaterThan?: Date;
  // dateOperationLessThan?: Date;
  idKind?: number[];
  tableName?: string;
  idTableObject?: number;
}

export class ArticleFilterRequest extends FilterRequest {
  idArticle?: number;
}
