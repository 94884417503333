import { Pipe, PipeTransform } from "@angular/core";
import { FilterOptionValue } from "../_components/filter/models/filter-button.model";

@Pipe({
  name: 'filterValue'
})
export class FilterValuePipe implements PipeTransform {
  constructor() {}

  transform(array: FilterOptionValue[], isString: boolean = false) {
    let valueArray = [];
    if(isString) {
      valueArray = array?.map(el => {
        return String(el.value);
      });
    } else {
      valueArray = array?.map(el => {
        return Number(el.value);
      });
    }
    
    return valueArray
  }
}