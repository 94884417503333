import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { BehaviorSubject, Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { AppModule, Modules } from "../../_helpers/module";
import { MatDialog } from "@angular/material/dialog";
import { HelpComponent } from "../../help/help.component";
import { Page } from "../../_models/page.model";
import { environment } from "../../../environments/environment";
import { AppInstanceService, TranslateConfigService } from "@panel/auth";
import { MainNavConfig, sectionValue } from "./main-nav.model";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { ThisPlatformService } from "../../_services/this-platform.service";
import { UserService } from "../../user/user.service";
import { AuthenticationService } from "@panel/auth"

@Component({
  selector: "app-main-nav",
  templateUrl: "./main-nav.component.html",
  styleUrls: ["./main-nav.component.scss"],
})
export class MainNavComponent implements OnInit {
  
  /**
   * The configuration object for setting the main navigation component based on the MainNavConfig model
   */
  @Input()
  config?: MainNavConfig;

  /**
   * Emit index value of section tab in main nav 
   */
  @Output()
  sectionChanged = new EventEmitter<string>() 

  pages: Page[] = [];
  accessPages: AppModule[] = [];

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
    ])
    .pipe(
      map((result) => {
        return result.matches
      }),
      shareReplay()
    );

  currentYear: number;
  version = environment.version;
  defaultLogo: string = '../../assets/sti-logo.jpg';

  defaultSelection: sectionValue;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public userServ: UserService,
    public authService: AuthenticationService,
    public router: Router,
    public appInstanceService: AppInstanceService,
    public dialog: MatDialog,
    public translateConfigService: TranslateConfigService,
    public p: ThisPlatformService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    if (this.userServ.userLogged) {
      this.initModules();
    }

    this.config?.navSection ? await this.getRouteParams() : null;

    const date = new Date();
    this.currentYear = date.getFullYear();
  }

  async initModules() {
    this.pages = Modules ? Modules.filter((p) => (p.module >= 0 && p.isMenu)) : null;

    if (this.userServ.userLogged.modules.length > 0) {
      this.pages = this.pages.filter((array) =>
        this.userServ.userLogged.modules.some(
          (filter) => array.module === filter.idModule || array.module === 0
        )
      );
    } else {
      this.pages = this.pages.filter((array) => array.module === 0);
    }

    await this.checkDisabledModules();
  }

  onLogout() {
    this.authService.logout();
    this.userServ.permission = new BehaviorSubject([]);
    this.router.navigate(["/auth"]);
  }

  onOpenHelpDialog() {
    const dialogRef = this.dialog.open(HelpComponent, {});
  }

  sectionChange(event) {
    this.setRouteParams(event.anchor);
    this.userServ.lastSection = event;
    this.sectionChanged.emit(event.name);
  }

  selectionChange(event) {
    this.setRouteParams(event.value.anchor);
    this.userServ.lastSection = event.value;
    this.sectionChanged.emit(event.value.name);
  }

  async checkDisabledModules() {

    if(this.userServ.permissionValue?.length == 0 ) {
      await this.userServ.getPermision().then( 
        () => this.checkDisabledModules()
      );
    }

    /** checking disabled modules in application */
    const disabledAppModules = Modules
    .filter(
      (el) => !this.userServ.userLogged.modules
      .some(
        (filter) => el.module === filter.idModule
      )
    ).map(el => el.module)

    /** checking disabled modules for user by permissions */
    const disabledUserModules = Modules
    .filter(
      (p) => !this.userServ.hasRoles([p.permission])
    ).map( 
      (el) => el.module 
    );

    /** creating complete disabled modules array whitout duplications */
    this.userServ.userLogged.disabledModules = [
      ...new Set([ 
        ...disabledAppModules,
        ...disabledUserModules
      ])
    ]
  
    /** updating currentUser in localstorage to ensure proper application operation */
    localStorage.setItem("currentUser", JSON.stringify(this.userServ.userLogged));
   
  }

  compareWithFn(selectedObj, listOfObj): boolean {
    return selectedObj && listOfObj && selectedObj.name === listOfObj.name;
  }

  private async getRouteParams() {
    this.route.queryParamMap.subscribe(params => {
      const section = params.get("section");
      // check if param exsist
      if (section) {
        const found = this.config?.navSection.find(section => section?.name === this.userServ?.lastSection?.name);
        // check if the section parameter is bound for the current module 
        if(found?.anchor === section) {
          this.defaultSelection = this.userServ.lastSection;
        } else {
          this.defaultSelection = this.config?.navSection[0];
        }
      } else {
        this.defaultSelection = this.config?.navSection[0];
      }
    });
    this.setRouteParams(this.defaultSelection.anchor);
    this.sectionChanged.emit(this.defaultSelection?.name);
  }

  private setRouteParams(value: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { section: value},
      queryParamsHandling: "merge",
    });
  }
}
