import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogModel, ConsentRequired, ConsentType } from './confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { ConfirmDialogParameters, ConfirmDialogType } from './confirm-dialog/confirm-dialog-parameters';
import { ConfigService } from '../_services/config.service';
import { TemplateConverter } from '../_helpers/template-converter';
import { FormControl, Validators } from '@angular/forms';
import { Config } from '../_models/config.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ComponentsService {
  constructor(
    public dialog: MatDialog,
    public configServ: ConfigService,
    private translate: TranslateService
  ) {}

  /**
   * @param dialogParameters.title - dialog title
   * @param dialogParameters.message - dialog message
   * @param dialogParameters.consentRequired - array of consents for check
   * @param dialogParameters.maxWidth - dialog modal max width
   * @param dialogParameters.minWidth - dialog modal min width
   * @param dialogParameters.consentConfigKey - config key that contains consents (rows will be added to consentRequired)
   * @param dialogParameters.consentErrConfigKey - config key that contains consent not checked error text
   * @param dialogParameters.consentTemplateParameters - objects used when template parameters replacing
   */

  public async ShowDialogAsync(dialogParameters: ConfirmDialogParameters): Promise<boolean> {
    await this.PrepereConsentsFromConfig(dialogParameters);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: dialogParameters.maxWidth ? dialogParameters.maxWidth : '400px',
      minWidth: dialogParameters.minWidth ? dialogParameters.minWidth : '250px',
      disableClose: dialogParameters.disableClose ? dialogParameters.disableClose : false,
      data: dialogParameters,
    });

    return await dialogRef.afterClosed().toPromise();
  }

  public ShowMessage(dialogParameters: ConfirmDialogParameters) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      maxWidth: dialogParameters.maxWidth ? dialogParameters.maxWidth : '400px',
      disableClose: dialogParameters.disableClose ? dialogParameters.disableClose : false,
      data: dialogParameters
    });
  }

  public ShowErrorMsg(error) {
    this.ShowMessage({
      title: "Błąd",
      maxWidth: '600px',
      message: "Błąd podczas wykonywania operacji " + 
        (error.status ? " - kod " + error.status : "") + "<br>" +
        (error?.error ? this.translate.instant(error.error.code) : "")
    });
  }

  public ShowDetailedErrorMsg(operationName, error, header: string = "Błąd") {
    let msg = '';

    if (error.error && error.error.code) {
      msg += this.translate.instant(error.error.code) + "<br>";
    } else {
      msg = "Błąd podczas wykonywania operacji" + "<br>";
    }

    this.ShowMessage({
      title: header + " - " + operationName,
      maxWidth: '600px',
      message: msg
    });
  }

  //Todo - funkcja do zaorania - jest zastępiona przez ShowDialogAsync (wersja asynchronicna, parametry lecą jako jeden obiekt, umożliwia pobieranie są zgód z configów)

  public ShowDialog(title: string, message: string, yesAction: () => void = null, confirmDialogType?: ConfirmDialogType, consentRequired? : ConsentRequired[], noAction: () => void = null ) {
    const dialogData = new ConfirmDialogModel(title, message, consentRequired, false, 'Nie', 'Tak', confirmDialogType );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      minWidth: "250px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult)
      {
        yesAction && yesAction();
      }
      else
      {
        noAction && noAction();
      }
    });
  }

  public async PrepereConsentsFromConfig(dialogParameters: ConfirmDialogParameters) {
    if (!dialogParameters.consentRequired) {
      dialogParameters.consentRequired = [];
    }

    if (!dialogParameters.consentConfigKey || !dialogParameters.consentErrConfigKey) {
      return;
    }

    let consentErrorText = await this.configServ.getConfigValue(dialogParameters.consentErrConfigKey);

    let usedConfigs: Config[] = [];

    (await this.configServ.getConfigsByKey(dialogParameters.consentConfigKey)).forEach(config => {
      let text = config.value;

      if (dialogParameters.consentTemplateParameters) {
        text = TemplateConverter.ConvertTemplateParametersMultiple(config.value, dialogParameters.consentTemplateParameters);
      }

      if (usedConfigs.find(x => x.table == config.table)) {
        return;
      }


      let type: ConsentType = config.idObject ? config.idObject : ConsentType.Checkbox;

      dialogParameters.consentRequired.push({
        text: text,
        required: type == ConsentType.Checkbox,
        value: false,
        fModel: new FormControl("", [Validators.required]),
        errorText: consentErrorText,
        textUrl: dialogParameters.consentUrl,
        type: type
      });

      usedConfigs.push(config);
    })
  }
}
