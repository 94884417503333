<div>
  <h4 mat-dialog-title class="info-dialog__background">{{ data.id ? "Edycja Pionu" : "Nowy pion" }}</h4>

  <div class="dialog-content custom-scrollbar">
    <mat-form-field>
      <mat-label>Nazwa</mat-label>
      <input
        [(ngModel)]="buildingServ.buildingVertical.name"
        type="text"
        matInput
      />
    </mat-form-field>
  
    <mat-form-field>
      <mat-label>Opis</mat-label>
      <textarea
        rows="4"
        [(ngModel)]="buildingServ.buildingVertical.description"
        type="text"
        matInput
      ></textarea>
    </mat-form-field>
  
    <div class="width__100 buttons__end">
      <button
        class="btn btn-sm btn-outline-warning button"
        (click)="dialogRef.close(false)"
      >
        Anuluj
      </button>
      <button class="btn btn-sm btn-outline-primary button" (click)="save()">
        {{ data.id ? "Zapisz" : "Dodaj" }}
      </button>
    </div>
  </div>
</div>
