
<div [ngClass]="config?.buttonType == ButtonType.Switch ? 'consent__container' : ''">

  <ng-container *ngIf="consents.length > 0; else noContent">
    <div *ngIf="config.buttonType; else noContent">
      <ng-container *ngIf="config?.buttonType == ButtonType.Switch">
        <div class="consent__button" *ngFor="let c of consents">
          <div class="consent__header"><b>{{ c.title }}</b></div>
          <!-- color="warn" potrzebny dla panel operatora -->
          <mat-slide-toggle 
            color="primary"
            labelPosition="after"
            [checked]="c.values?.accepted"
            (change)="consentToggle($event, c)"
            [disabled]="!config.edit"
          >
            <div class="consent__slider">
              <div class="consent__text">{{ c.content }}</div>
            </div>
          </mat-slide-toggle>
        </div>
      </ng-container>
  
      <ng-container *ngIf="config?.buttonType == ButtonType.Checkbox">
        <div class="consent__button" *ngFor="let c of consents">
          <!-- color="warn" potrzebny dla panel operatora -->
          <mat-checkbox
            color="primary" 
            [checked]="c.values?.accepted"
            (change)="consentToggle($event, c)"
            [disabled]="!config.edit"
          >
            {{c.content}}
          </mat-checkbox>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #noContent>
    <div class="consent__no-content">
      Brak zgód do wyświetlenia
    </div>
  </ng-template>

</div>

