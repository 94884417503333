import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ComponentsService } from '../_components/components.service';
import { Utils } from '../_helpers/utils';
import { AttachmentCreate } from '../_models/attachment-create.model';
import { Attachment } from '../_models/attachment.model';

const url = environment.api;

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  isAttachmentDeleted: boolean = false;

  constructor(private http: HttpClient, private componentsService: ComponentsService) { }

  createAttachment(formData: any) {
    return this.http.post<Attachment>(url + 'attachment', formData);
  }

  copyAttachment(key: string, attachment: AttachmentCreate) {
    return this.http.post<Attachment>(url + `attachment/copy/${key}`, attachment);
  }

  getAttachment(key: string) {
    return this.http.get(url + `attachment/${key}`, {responseType: "blob", observe: "response"});
  }

  async download(attachment: Attachment) {
    this.getAttachment(attachment.key).subscribe(response => {
      Utils.saveFile(response, attachment.publicFileName);
    }, (error) => {
      this.componentsService.ShowErrorMsg(error);
    });
  }

  /** New endpoint for attachment with auto assign objects */
  createAndAssigned(formData: FormData) {
    return this.http.post<Attachment>(url + 'attachment/v2', formData);
  }

  delete(key: string) {
    return this.http.delete<Attachment>(url + `attachment/${key}`);
  }
}
