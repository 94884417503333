import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) { }

  transform(value): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(value);
    //return this._sanitizer.bypassSecurityTrustStyle(value);
    //this._sanitizer.bypassSecurityTrustScript(value);
    //this._sanitizer.bypassSecurityTrustUrl(value);
    //return this._sanitizer.bypassSecurityTrustResourceUrl(value);
  }

}
