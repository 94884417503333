import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CreateRealizationTime, RealizationTime, UpateRealizationTime, TimeQueryParameters } from '../_models/realization-time.model';
import { Observable } from 'rxjs';
import { Utils } from '../_helpers/utils';

const url = environment.api;

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  constructor(
    private http: HttpClient,
  ) { }

  createTimeRealization(realizationTime: CreateRealizationTime): Observable<CreateRealizationTime> {
    return this.http.post<CreateRealizationTime>(url + 'time', realizationTime);
  }

  getAllTimeRealization(queryParams: TimeQueryParameters): Observable<RealizationTime[]> {
    return this.http.get<RealizationTime[]>(url + 'time', {
      params: Utils.httpParams(queryParams)
    });
  }

  getTimeRealizationById(id: number): Observable<RealizationTime> {
    return this.http.get<RealizationTime>(url + `time/${id}`,);
  }

  updateTimeRealization(id: number, updateRealizationTime: UpateRealizationTime): Observable<RealizationTime> {
    return this.http.patch<RealizationTime>(url + `time/${id}`, updateRealizationTime);
  }

  deleteTimeRealization(id: number): Observable<RealizationTime> {
    return this.http.delete<RealizationTime>(url + `time/${id}`,);
  }
   
}
