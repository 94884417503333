import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KanbanToggle } from './kanban-toggle.model';
import { Utils } from '@panel/helpers';
import { SessionSettings, SessionSettingsAction } from '@panel/models';
import { ThisPlatformService } from 'apps/panelcrm/src/app/_services/this-platform.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-kanban-toggle',
  templateUrl: './kanban-toggle.component.html',
  styleUrls: ['./kanban-toggle.component.scss']
})
export class KanbanToggleComponent {
  
  @Input()
  config: KanbanToggle = new KanbanToggle();

  @Output()
  onToggle: EventEmitter<boolean> = new EventEmitter();

  sessionSetting: SessionSettings;

  subscription: Subscription = new Subscription();

  constructor(
    private p: ThisPlatformService,
  ) { }

  ngOnInit() {
    this.sessionSetting = Utils.manageSessionSetting(SessionSettingsAction.GET);
    this.p.isMobile$.subscribe(isMobile => {
      this.onToggle.emit(true);
    });
  }

  onClickToggle(event) {
    this.config.value = event.checked;
    this.sessionSetting[this.config.sessionsSettingsProperty] = event.checked;  

    Utils.manageSessionSetting(
      SessionSettingsAction.UPDATE, 
      this.sessionSetting
    );

    this.onToggle.emit(event.checked);
  }
  
  ngOnDestroy() { 
    this.subscription?.unsubscribe();
  }
}
