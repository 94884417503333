import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@panel/shared';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppInstanceService, AuthService } from '@panel/auth';

@Component({
  selector: 'lib-reset-password',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule
  ],
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})

export class ResetPasswordComponent implements OnInit {

  form: FormGroup;
  validationError: boolean = false;
  errorMessage: string;
  loading: boolean = false;
  sended: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public appInstanceService: AppInstanceService,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required, Validators.email],
      }),
    });
  }

  sendRecoveryMessagge() {

    if (!this.form.valid) {
      this.validationError = true;
      return;
    }

    let email = this.form.controls['email'].value;

    this.authService.forgetPassword(email).subscribe(
      res => { this.sended = true; },
      error => { console.log(error) }
    )

  }

}
