<div 
  class="kanban__board" 
  cdkDropListGroup
>
  <div class="row">
    <div 
      class="col" 
      *ngFor="let col of (board | async).columns"
    >
      <div class="kanban__header">
        <span>{{col.name}} ({{col?.items?.length}})</span>
      </div>
      
      <div 
        cdkDropList 
        id="{{col.id}}" 
        class="kanban__column"
        [ngStyle]='{
          "background-color": col.backgroundColor+"4d",
          "border": "solid 1px " + col.backgroundColor,
        }'
        [cdkDropListData]="col.items" 
        (cdkDropListDropped)="drop($event, col)"
      >
        <ng-container *ngIf="col?.items?.length > 0; else noData">
          <div *ngFor="let item of col.items">
            <app-mobile-card-view 
              cdkDrag 
              [cdkDragData]="item" 
              [cdkDragDisabled]="(board|async).readonly"
              [rows]="of([item])" 
              [rowsDisplay]="rowsDisplay" 
              [parameters]="parameters"
            ></app-mobile-card-view>
          </div>
        </ng-container>
        <ng-template #noData>
          <div class="kanban__no-data">
            <mat-icon class="kanban__no-data--icon">search_off</mat-icon>
            <p>Brak elementów</p>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>