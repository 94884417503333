import { Component, OnInit } from '@angular/core';
import { Config } from '../_models/config.model';
import { ConfigService } from '../_services/config.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  config: Config;
  loading = true;

  constructor(public configServ: ConfigService) { }

  async ngOnInit() {
    this.config = await this.configServ.getConfig('help.body');
    this.loading = false;
  }

}
