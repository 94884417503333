import { Pipe, PipeTransform } from "@angular/core";
import { Building } from "../building/building.model";

@Pipe({
  name: "buildingAdress"
})
export class BuildingAdressPipe implements PipeTransform {
  transform(value: Building, ...args: any[]): any {
    if (value) {
      //const { street, postCode,  city} = value;
      //return `${street ? street : ''}, ${postCode ? postCode : ''} ${city ? city : ''}`;
      let elems = [];

      if (value.street) {
        elems.push(value.street);
      }

      if (value.postCode) {
        elems.push(value.postCode);
      }

      if (value.city) {
        elems.push(value.city);
      }

      return elems.join(', ');
    }

    return '-';
  }
}
