<div>
  <div class="header">
    <h4 mat-dialog-title class="info-dialog__background">Nowa usługa</h4>
    <div class="custom-alert requirement-description" *ngIf="data.requirementDescription">{{data.requirementDescription}}</div>
  </div>

  <div class="dialog-content custom-scrollbar">
    <mat-form-field>
      <mat-label>Wybierz usługę</mat-label>
      <mat-select
        required
        [(ngModel)]="product"
        (ngModelChange)="onProductChange($event)"
        disableOptionCentering
      >
        <mat-option *ngFor="let product of products" [value]="product">
          {{ product.nameSimple }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="canSelectProperty()" class="product__properties">
      <div *ngFor="let item of propertyGroups">
        <mat-form-field *ngIf="item.productProperties.length > 1">
          <mat-label>{{ item.name }}</mat-label>
          <mat-select
            [formControl]="fItem"
            required
            [(ngModel)]="item.selectedProduct"
            (change)="onPropertySelect(item)"
            name="item.name"
            disableOptionCentering
          >
            <mat-option *ngFor="let property of item.productProperties" [value]="property">
              {{ property.nameSimple }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="fItem.invalid && fItem.hasError('required')">
            Pole rodzaju usługi jest wymagane.
          </mat-error>
        </mat-form-field>

        <!-- quantity | Implement type of control to chooose (int | normal - string | combobox | checkbox)!!! -->

        <mat-form-field *ngIf="item.selectedProduct && isNumber(item.selectedProduct.type)" >
          <mat-label>{{ item.selectedProduct.placeholder }}</mat-label>
          <input
            required
            matInput
            [(ngModel)]="item.selectedProduct.quantity"
            [type]="getInputType(item.selectedProduct.type)"
            name="quantity.value"
          >
        </mat-form-field>

        <!-- quantity | Selectbox -->
        <mat-form-field *ngIf="item.selectedProduct && isCombobox(item.selectedProduct.type)">
          <mat-label>{{ item.selectedProduct.placeholder }}</mat-label>
          <mat-select
            [formControl]="fNewProduct"
            required
            [(ngModel)]="item.selectedProduct.quantity"
            name="quantity.value"
            disableOptionCentering
          >
            <mat-option *ngFor="let v of item.selectedProduct.values" [value]="v.value">{{ v.key }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="fNewProduct.invalid">
            Podanie ilości jest wymagane.
          </mat-error>
        </mat-form-field>

        <!-- quantity | Checkbox  -->
        <div class="checkbox-with-label" *ngIf="item.selectedProduct && this.isCheckbox(item.selectedProduct.type) && item.productProperties.length == 1">
          <mat-checkbox
            required
            name="quantity.value"
            [(ngModel)]="item.selectedProduct.quantity"
            color="warn"
          >
            {{ item.selectedProduct.nameSimple }}
          </mat-checkbox>
          <!-- <p>{{item.selectedProduct.placeholder}}</p> -->
        </div>

        <!-- <mat-checkbox name="quantity.value"
          *ngIf="item.selectedProduct && this.isCheckbox(item.selectedProduct.type) && item.productProperties.length == 1 && item.selectedProduct.name
            == 'Droga kablowa pod przyłącze do budynku'">
          Mam przyłącze do budynku
        </mat-checkbox> -->

        <!-- itemKey | Selectbox -->
        <mat-form-field *ngIf="item.selectedProduct && isCombobox(item.selectedProduct.itemKeyType)">
          <mat-label>{{ item.selectedProduct.placeholder }}</mat-label>
          <mat-select
            [formControl]="fNewProduct"
            required [(ngModel)]="item.selectedProduct.itemKey"
            name="itemKey.value"
            disableOptionCentering
          >
            <mat-option *ngFor="let v of getItems(item.selectedProduct)" [value]="v.key">{{ v.key }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- itemKey | int -->
        <mat-form-field *ngIf="item.selectedProduct && isNumber(item.selectedProduct.itemKeyType)">
          <mat-label>{{ item.selectedProduct.placeholder }} {{priceListKeyRangeName(item.selectedProduct)}}</mat-label>
          <input matInput
            [formControl]="fItemKey"
            required
            [(ngModel)]="item.selectedProduct.itemKey"
            [type]="getInputType(item.selectedProduct.itemKeyType)"
            name="itemKey.value"
          >
        </mat-form-field>
      </div>

      <div *ngIf="validationError" class="custom-alert">
        Uzupełnij pola z gwiazdką.
      </div>

    </div>

    <div class="stepper-btn stepper-btn__space-between">
      <button class="btn secondary-button-outline" (click)="cancel()">Anuluj</button>
      <button class="btn primary-button" (click)="save()">Zapisz</button>
    </div>
  </div>
</div>
