import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SignProtocolData } from '../../task/models/task.interface';
import { Attachment } from '../../_models/attachment.model';
import { AttachmentService } from '../../_services/attachment.service';
import { SignatureService } from '../../_services/signature.service';
import { CreateSignature, SignaturePadData } from '../../_models/signature.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ThisPlatformService } from '../../_services/this-platform.service';
import { OverlayLoaderConfig } from '../../_models/overlay-loader.model';
import { ComponentsService } from '../components.service';
import { ConfirmDialogType } from '../confirm-dialog/confirm-dialog-parameters';


@Component({
  selector: 'app-sign-protocol',
  templateUrl: './sign-protocol.component.html',
  styleUrls: ['./sign-protocol.component.scss']
})

export class SignProtocolComponent {

  protocolSrc: string = '';

  protocolLoaded: boolean = false;
  loading: boolean = false;
  signingProtocol: boolean = false;

  signatureCreated: CreateSignature = new CreateSignature();

  subscription: Subscription = new Subscription();

  overlayConfig: OverlayLoaderConfig = {
    textDisplay: "Trwa podpisywanie protokołu..."
  };

  constructor(
    public dialogRef: MatDialogRef<SignProtocolComponent>,
    @Inject(MAT_DIALOG_DATA)
    public signProtocolData: SignProtocolData,
    public p: ThisPlatformService,
    private attachmentServ: AttachmentService,
    private signatureServ: SignatureService,
    private snackBar: MatSnackBar,
    private componentsServ: ComponentsService
  ) { }

  ngOnInit() { 
    this.getProtocolFromBlob(this.signProtocolData.protocol);

    this.signatureCreated.idTableObject = Number(this.signProtocolData.protocol.id);
    this.signatureCreated.tableName = this.signProtocolData.signaturePadConfig.tableName;
  }

  getProtocolFromBlob(protocol: Attachment) {
    this.loading = true;
    this.subscription = this.subscription.add(
      this.attachmentServ.getAttachment(protocol.key).subscribe(
        (response) => {
          let ct = response.headers.get("Content-Type");
          let blob = new Blob([response.body], { type: ct });
          this.protocolSrc = URL.createObjectURL(blob);
        },
        () => { 
          this.protocolLoaded = false;
          this.loading = false; 
        },
        () => { 
          this.protocolLoaded = true;
          this.loading = false;
        }
      )
    );
  }

  addSignature(signatureData: SignaturePadData[]) {
    this.signingProtocol = true;
    this.signatureCreated.signers = [];
    this.signatureCreated.files = [];
    
    signatureData.forEach(data => {
      this.signatureCreated.signers.push(data.signer);
      this.signatureCreated.files.push(data.signature)
    });
    
    this.protocolSign();
  }

  protocolSign() {
    let formData: FormData = new FormData();

    formData.append('tableName',this.signatureCreated.tableName)
    formData.append('idTableObject',this.signatureCreated.idTableObject.toString())
    this.signatureCreated?.signers?.forEach((value, index) => {
      formData.append(`signers[${index}][personalData]`,value?.personalData);
      formData.append(`signers[${index}][signerType]`,value?.signerType);
    });
    this.signatureCreated.files.forEach((value, index) => {
      formData.append(`files`,value);
    })

    this.signatureServ.createSignature(formData).subscribe(
      (res) => {
        this.loading = true; 
      },
      (err) => { 
        this.openSnack('Błąd podczas podpisywania','custom-snack-bar__error');
        this.loading = false; 
        this.signingProtocol = false;
      },
      () => { 
        this.openSnack('Podpis zapisany', 'custom-snack-bar__success');
        this.dialogRef.close(true);
        this.loading = false; 
        this.signingProtocol = false;
      }
    );
  }

  private openSnack(message: string, className: string) {
    this.snackBar.open(message, '', {
      duration: 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['custom-snack-bar', className]
    });
  }

  private onUnsubscribe() {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async onDismiss() {
    if(this.signProtocolData?.taskDetails?.topic?.requireSignedProtocol) {
      const dialogResult = await this.componentsServ.ShowDialogAsync({
        title: "Czy na pewno chcesz zamknąć bez podpisania protokołu?",
        message: "Podpisanie protokołu jest obligatoryjne",
        minWidth: "300px",
        maxWidth: "600px",
        yesButtonText: "Tak",
        noButtonText: "Nie",
        confirmDialogType: ConfirmDialogType.Danger,
      });

      dialogResult ? this.dismiss() : null;
    } else {
      this.dismiss();
    }
  }

  private dismiss() {
    this.dialogRef.close(false);
    this.onUnsubscribe();
  }

  ngOnDestroy() {
    URL.revokeObjectURL(this.protocolSrc);
    this.onUnsubscribe();
  }

}
