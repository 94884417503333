import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from '../../user/user.service';
import { ConfirmDialogType } from '../../_components/confirm-dialog/confirm-dialog-parameters';
import { Company } from '../../company/company.model';
import { CompanyService } from '../../company/company.service';
import { PriceList } from '../../price-list/price-list.model';
import { PriceListService } from '../../price-list/price-list.service';
import { ComponentsService } from '../../_components/components.service';
import { Building } from '../building.model';
import { BuildingService } from '../building.service';
import { BuildingCompanyMany } from './building-company-many.model';
import { BuildingCompany } from './building-company.model';
import { BuildingCompanyService } from './building-company.service';
import { CompanyType } from '../../company/enum/type.enum';
import { Utils } from '../../_helpers/utils';
import { BuildingPerm } from '../../_enums/permission.enum';

@Component({
  selector: 'app-building-company',
  templateUrl: './building-company.component.html',
  styleUrls: ['./building-company.component.scss']
})
export class BuildingCompanyComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<BuildingCompanyComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: BuildingCompany,
    public buildingService: BuildingService,
    public companyService: CompanyService,
    public buildingCompanyService: BuildingCompanyService,
    private componentsServ: ComponentsService,
    public priceListService: PriceListService,
    private router: Router,
    public userServ: UserService,
  ) {
    if (data.id) {
      this.loading = true;

      this.buildingCompanyService.getBuildingCompany(data.id.toString()).subscribe(
        buildingCompany => {
          this.buildingCompany = buildingCompany;
          this.buildingsSelectLock = true;
          this.companySelectLock = true;
          this.loading = false;
        }
      );
    }

    if (data.idBuilding) {
      this.buildingCompany.idBuilding = data.idBuilding;
      this.buildingsSelectLock = true;
    }

    if (data.idCompany) {
      this.buildingCompany.idCompany = data.idCompany;
      this.companySelectLock = true;
    }

    if (data.companyType) {
      this.companyType = data.companyType;
    }

    if(this.companyType === 1) {
      this.initOperator();
    } else if(this.companyType === 2) {
      this.initTenant();
    }
  }

  buildingCompany: BuildingCompany = new BuildingCompany();
  buildings: Building[];
  companies: Company[];
  companyTypesAvailable: CompanyType[] = [];
  
  buildingsSelectLock = false;
  companySelectLock = false;
  saveLock = false;
  loading = false;
  companyType = 1; // domyślnie operator
  textChooseCompany: string;

  permissionEditBuildings: boolean = false;
  permissionManageCollocation: boolean = false;
  permissionNotify: boolean = false;
  permissionManageDefaultBuilding: boolean = false;

  async ngOnInit() {
    this.companyTypesAvailable = Utils.setCompanyTypes(this.userServ);

    await this.loadPermission();

    this.buildingService.getBuildings().subscribe((list) => {
      this.buildings = list;

      if (!this.buildingCompany.id && this.buildingCompany.idCompany) {
        this.buildingService.getBuildingsByIdCompany(this.buildingCompany.idCompany).subscribe(list =>
          this.buildings = this.buildings.filter(x => !list.find(bc => bc.id == x.id))
        );
      }
    });

    this.companyService.getCompaniesType({
      type: this.companyTypesAvailable
    }).subscribe((list) => {
      this.companies = list;

      if (!this.buildingCompany.id && this.buildingCompany.idBuilding) {
        this.companyService.getCompaniesByIdBuilding(this.buildingCompany.idBuilding).subscribe(list =>
          this.companies = this.companies.filter(x => !list.find(bc => bc.id == x.id))
        );
      }
    });
  }

  initOperator() {
    this.textChooseCompany = 'Wybierz operatora';
  }

  initTenant() {
    this.textChooseCompany = 'Wybierz najemce';
  }

  addPriceList(row: BuildingCompany) {
    this.priceListService.currentPriceList = {
      ...new PriceList(),
      // idBuilding: row.idBuilding,
      // idCompany: row.idCompany
    }

    this.dialogRef.close(false);
    this.router.navigate(['/price-list/add-edit']);
  }

  remove() {
    this.componentsServ.ShowDialog(
      "Potwierdzenie usunięcia",
      "Czy na pewno chcesz usunąć to powiązanie?",
      () => this.buildingCompanyService.delete(this.buildingCompany).subscribe((result) => {
        this.dialogRef.close(true);
      }), ConfirmDialogType.Danger,
    );
  }

  async save() {
    if (!this.buildingCompany.idBuilding || !this.buildingCompany.idCompany) {
      this.componentsServ.ShowMessage({ title: "", message: "Należy wybrać budynek oraz operatora" });
      return;
    }

    if (this.buildingCompany.id) {
      this.saveLock = true;
      await this.buildingCompanyService.update(this.buildingCompany).toPromise();
    }
    else
    {
      if (await this.componentsServ.ShowDialogAsync({ title: "Potwierdzenie", message: "Potwierdzasz przypisanie?", confirmDialogType: ConfirmDialogType.Success,} )) {
        this.saveLock = true;
        await this.buildingCompanyService.create({
          ...new BuildingCompanyMany(),
          buildingCompanies: [
            this.buildingCompany
          ]
        }).toPromise();
      }
    }

    this.dialogRef.close(true);
  }

  private async loadPermission() {
    this.permissionEditBuildings = await this.userServ.hasRolesAsync([
      "buildings.edit",
    ]);

    this.permissionManageCollocation = await this.userServ.hasRolesAsync([
      BuildingPerm.MANAGE_COLLOCATION
    ]);

    this.permissionNotify = await this.userServ.hasRolesAsync([
      BuildingPerm.NOTIFY
    ]);

    this.permissionManageDefaultBuilding = await this.userServ.hasRolesAsync([
      BuildingPerm.MANGE_DEFAULT_BUILDING
    ]);
  }
}
