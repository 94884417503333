import { FormGroup } from "@angular/forms";
import { Observable } from "rxjs";

/**
 * Enum of filter actions
 */
export enum FilterAction {
  Filter = "filter",
  Reset = "Reset"
}

/**
 * Enum of clicked action in filter button
 */
export enum onClickAction {
  Change = "change",
  Clear = "clear"
}

/**
 * Enum of filter button types
 */
export enum FilterButtonType {
  SingleSelection = 'singleselect',
  MultipleSelection = 'multipleselection',
  AutocompleteSelection = 'autocompleteSelection',
  DatePicker = 'datepicker',
}

/**
 * Model of filter button
 */
export class FilterButton {
  buttonType?: FilterButtonType;
  label?: string; //
  placeholder?: string;
  permission?: string[];
  optionList?: FilterOptionValue[]; //add all option if need
  filteredOptionList?: Observable<FilterOptionValue[]>;
  form?: FormGroup;
  formControlName?: string;

}

/**
 * Model of filer option value
 */
export class FilterOptionValue {
  displayName?: string; //display name, friendly name
  value?: string | number; //id, dicitoary key, klucz
  default?: boolean;
}

