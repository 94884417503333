<div
  *ngIf="config" 
  class="tools" 
  [ngClass]="{
    'flex-end' : (
      (!(config?.buttons?.length > 0) || (p.isMobile$ | async)) && config?.mobileDisplay
    )
  }" 
>
  <ng-container *ngIf="!(p.isMobile$ | async) || !config?.mobileDisplay">
    <div *ngIf="config?.buttons && config?.buttons?.length > 0">
      <button *ngFor="let tool of config.buttons" 
        class="btn btn-tool" 
        [ngClass]="tool.isPrimary ? 'primary-button' : 'secondary-button'"
        [hidden]="!tool.permission"
        (click)="resolveToolClick($event, tool)"
      >
        <span [ngClass]="{'icon-material' : tool?.iconName}">
          <img 
            *ngIf="tool.iconSrc && !(p.isMobile$ | async)" 
            [src]="tool.iconSrc" 
          />
          <mat-icon 
            *ngIf="tool?.iconName && !(p.isMobile$ | async)" 
            class="icon-material--margin"  
          >{{ tool.iconName }}</mat-icon>
          
          {{ tool.displayName | translate }}
        </span>
      </button>
    </div>
  </ng-container>

  <div 
    class="search icon" 
    *ngIf="config?.searchTool"
  >
    <input
      size="100"
      class="form-control"
      type="text"
      (ngModelChange)="getTrim(config.searchTool)"
      [(ngModel)]="config.searchTool.text"
      (ngModelChange)="getTrim(config.searchTool)"
      autocomplete="off"
      [placeholder]="config.searchTool.displayName ? config.searchTool.displayName : 'Szukaj...'"
    />
  </div>

</div>
