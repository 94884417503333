import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThisPlatformService } from '../../_services/this-platform.service';
import { Company } from '../../company/company.model';
import { CompanyService } from '../../company/company.service';
import { CompanyType } from '../../company/enum/type.enum';
import { CompanyAssignedData } from './add-company.assigned.model';
import { Utils } from '../../_helpers/utils';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'app-add-company-assigned',
  templateUrl: './add-company-assigned.component.html',
  styleUrls: ['./add-company-assigned.component.scss']
})
export class AddCompanyAssignedComponent {

  companyList: Company[] = [];
  form: FormGroup = new FormGroup({
    selectedCompany: new FormControl(null, {
      validators: [Validators.required]
    })
  });

  constructor(
    public dialogRef: MatDialogRef<AddCompanyAssignedComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: CompanyAssignedData,
    public p: ThisPlatformService,
    public companyServ: CompanyService,
    private userServ: UserService
  ){ }

  async ngOnInit() {
    if(!this.companyServ.companiesLoaded) {
      await this.companyServ.loadCompaniesSync(Utils.setCompanyTypes(this.userServ));
    }
    await this.initCompanyList();

  }

  onAddAssigne() {
    this.dialogRef.close({
      add: true,
      companyId: this.form.controls.selectedCompany.value
    });
  }

  onCancel() {
    this.dialogRef.close({add: false});
  }

  private async initCompanyList() {
    this.companyList = this.companyServ.companies.filter(company => company.id != this.data.currentCompany)
    this.data.companyAssigned.forEach(assigned => {
      this.companyList = this.companyList.filter(company => company.id != assigned.idCompanyAssigned)
    });
  }

  ngOnDestroy() {

  }

}
