import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Attachment } from '../../_models/attachment.model';
import { ThumbnailSettings } from './upload-thumbnail.model';
import { ThisPlatformService } from '../../_services/this-platform.service';

@Component({
  selector: 'app-upload-thumbnail',
  templateUrl: './upload-thumbnail.component.html',
  styleUrls: ['./upload-thumbnail.component.scss']
})
export class UploadThumbnailComponent {

  @Input()
  settings: ThumbnailSettings;

  @Input()
  thumbnail: Attachment;

  @Output()
  thumbnailAdd: EventEmitter<File> = new EventEmitter<File>();

  @Output()
  thumbnailRemove: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @ViewChild('imageInput') myFileInput;
  
  constructor(
    public dialog: MatDialog,
    public p: ThisPlatformService,
  ) { }

  uploadingLoader: boolean = false;
  uploadingLock: boolean = false;

  uploadedThumbnailSrc: string = '';

  onAddFile(event) {
    this.clearThumbnail();
    this.uploadedThumbnailSrc = URL.createObjectURL(event.target.files[0]);
    this.thumbnailAdd.emit(event.target.files[0]);
  }

  onRemoveFile() {
    URL.revokeObjectURL(this.uploadedThumbnailSrc);
    this.uploadedThumbnailSrc = '';
    this.clearThumbnail();
    this.myFileInput.nativeElement.value = '';
    this.thumbnailRemove.emit(true);
  }

  fileUpload(event) {
    this.uploadingLoader = true;

    window.setTimeout(() => {
      if (!this.uploadingLock) this.uploadingLoader = false;
    }, 1500);
  }

  private clearThumbnail() {
    if(this.thumbnail?.filePath) {
      this.thumbnail.filePath = null;
    }
  }

}
