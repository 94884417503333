export enum TaskTranslation {
  Priority = "TASK.PRIORITY.",
  Status = "TASK.STATUS.",
  Topic = "TASK.TOPIC."
}

export enum TaskArrayName {
  Priority = "taskPriority",
  Status = "taskStatus",
  Topic = "taskTopic"
}