import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DictionaryService } from "../../dictionary/dictionary.service";
import { BuildingLocation } from "../building.model";
import { BuildingService } from "../building.service";

@Component({
  selector: "app-building-location",
  templateUrl: "./building-location.component.html",
  styleUrls: ["./building-location.component.scss"],
})
export class BuildingLocationComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<BuildingLocationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: BuildingLocation,
    public buildingServ: BuildingService,
    public dictionaryServ: DictionaryService
  ) {}

  loading = true;
  ngOnInit() {
    if (this.data.id > 0) {
      this.buildingServ
        .getBuildingLocation(this.data.id)
        .subscribe((buildingLocation: BuildingLocation) => {
          this.buildingServ.buildingLocation = buildingLocation;
          this.loading = false;
        });
    } else {
      this.buildingServ.buildingLocation = new BuildingLocation();
      this.buildingServ.buildingLocation = this.data;
      this.loading = false;
    }
  }

  load() {}
  save() {
    if (this.data.id > 0) {
      this.buildingServ.updateBuildingLocation().subscribe(() => {
        this.dialogRef.close(true);
      });
    } else {
      this.buildingServ.createBuildingLocation().subscribe(() => {
        this.dialogRef.close(true);
      });
    }
  }
}
