import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Utils } from '../_helpers/utils';
import { ConsentValue, ConsentValueCreateBody, ConsentValueQuery, ConsentValueUpdateBody } from '../_models/consentValue.model';

const url = environment.api;

@Injectable({
  providedIn: 'root'
})
export class ConsentValueService {

  constructor(
    private http: HttpClient,
  ) { }

  getConsentValues(consentsQuery: ConsentValueQuery) {
    return this.http.get<ConsentValue>(url + 'consent-value', { params: Utils.httpParams(consentsQuery) })
  }

  patchConsentValues(consentValues: ConsentValueUpdateBody) {
    return this.http.patch<ConsentValue>(url + 'consent-value', consentValues);
  }

  // createConsentValues(consentValues: ConsentValueCreateBody) {
  //   return this.http.post(url + 'consent-value', consentValues);
  // }
  


}
