import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Config } from '../_models/config.model';


const url = environment.api;


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  configs: Config[] = [];
  constructor(private http: HttpClient) { }

  async loadConfigs() {
    this.configs = await (this.getConfigs().toPromise());
  }

  getConfigs() {
    return this.http.get<Config[]>(url + 'config');
  }

  async getConfig(key: string): Promise<Config> {
    if (this.configs.length === 0) {
      await this.loadConfigs();
    }

    return this.findConfig(key);
  }

  async getConfigsByKey(key: string): Promise<Config[]> {
    if (this.configs.length === 0) {
      await this.loadConfigs();
    }

    return this.configs.filter(x => x.key === key);
  }

  async getConfigValue(key: string): Promise<string> {
    if (this.configs.length === 0) {
      await this.loadConfigs();
    }

    return this.findConfigValue(key);
  }

  async getConfigForObject(key: string, table: string, idObject: number, findByKeyIfNotExists: boolean = false): Promise<Config> {
    if (this.configs.length === 0) {
      await this.loadConfigs();
    }

    let config = this.findConfigForObject(key, table, idObject);


    return config ? config : (findByKeyIfNotExists ? this.getConfig(key) : null);
  }

  findConfig(key: string): Config {
    return this.configs.find(a => a.key === key);
  }

  findConfigValue(key: string): string {
    let config = this.findConfig(key);
    return config ? config.value : '';
  }

  findConfigForObject(key: string, table: string, idObject: number): Config {
    return this.configs.find(a => a.key === key && a.table == table && a.idObject == idObject);
  }
}
