import { DictionaryValueSimple } from '../dictionary/dictionary.model';
import { ProductProperty } from '../_models/product-property.model';

export class Product {
    id: number;
    idApp: number;
    name: string;
    internalDescription: string;
    externalDescription: string;
    consentDescription: string;
    propertyGroups: PropertyGroup[];
    properties?: any[];
    requirements: ProductRequirement[];
    floorRequired: boolean;
    nameSimple: string;
    specialities: DictionaryValueSimple[];
}


export class PropertyGroup {
  id: number;
  idProduct: number;
  name?: string;
  selectedProduct?: ProductProperty;
  productProperties: ProductProperty[];
  hidden: boolean;
}

export class ProductRequirement {
  id: number;
  idProduct: number;
  idProductRequired: number;
  description: string;
}

export enum ProductSpecialityType {
  UpdateBuildingCompanyCollocation = 1,
  TechnicalConditionsAdditionalDescription = 2
}
