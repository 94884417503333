<div>
  <h4 mat-dialog-title class="info-dialog__background">
    Przypisanie firmy
  </h4>

  <div class="assigned__container custom-scrollbar">
    <form [formGroup]="form" class="width__100 assigned__form">
      <mat-form-field appearance="outline">
        <mat-label>Wybierz firmę do przypisania</mat-label>
        <mat-select
          formControlName="selectedCompany"
        >
          <mat-option 
            *ngFor="let company of companyList"
            [value]="company.id"
          >
            {{ company.name }} | {{ company.city }}, {{ company.postCode }} {{ company.street }}
          </mat-option>
  
        </mat-select>
      </mat-form-field>
    </form>

    <hr class="range-divider__top width__100">
    
    <div class="buttons__space-between width__100">
      <button 
        class="btn btn-outline-secondary" 
        (click)="onCancel()"
      >Anuluj</button>
      <button 
        class="btn btn-primary" 
        (click)="onAddAssigne()" 
      >Przypisz</button>
    </div>

  </div>

</div>
