import { Inject, Injectable } from '@angular/core';
import { NewUser, Role, User, UserQueryParams, UserRole } from '@panel/user';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '@panel/auth';
import { HttpClient } from '@angular/common/http';
import { EnvModel } from '@panel/models';
import { Utils } from '@panel/helpers';

@Injectable({
  providedIn: 'root'
})
export class UserService {

    userLogged: User;
    rolesLoaded = false;
    permission: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    userRole: UserRole[] = [];
  
    newUser: NewUser;
    users: User[];
  
    menuShowSubmenuLegal: boolean = false;
    usersLoaded: boolean = false;
  
    lastSection: any;

    url = this.environment.api;
  
    constructor(
      public authService: AuthenticationService,
      public http: HttpClient,
      @Inject('env') public environment: EnvModel
    ) {
      this.authService.currentUser.subscribe(async user => {
        this.userLogged = user;
      });
    }
  
    newUserProcess(user: NewUser) {
      user.email = user.email ? user.email.trim() : '';
      user.lastName = user.lastName ? user.lastName.trim() : '';
      user.firstName = user.firstName ? user.firstName.trim() : '';
      user.telephone = user.telephone ? user.telephone.trim() : '';
    }
  
    getPermissionSubject() {
      return this.permission.asObservable();
    }
  
    getAll(query?: UserQueryParams) {
      return this.http.get<User[]>(`${this.url}users`, {
        params: query ? Utils.httpParams(query) : null!
      });
    }
  
    getById(id: number) {
      return this.http.get<User>(`${this.url}users/${id}`);
    }
  
    register(user: NewUser) {
      this.newUserProcess(user);
      return this.http.post(`${this.url}users`, user);
    }
  
    update(user: NewUser, id: number) {
      this.newUserProcess(user);
      return this.http.put(`${this.url}users/${id}`, user);
    }
  
    delete(id: number) {
      return this.http.delete(`${this.url}users/${id}`);
    }
  
    resendActivation(id: number) {
      return this.http.post(`${this.url}users/resend-activation-code/${id}`, null);
    }
  
    private getPermission() {
      return this.http.get(`${this.url}permission`);
    }
  
    public get permissionValue(): string[] {
      return this.permission.value;
    }
  
    async getPermision() {
      let res: any = await this.getPermission().toPromise();
  
      this.permission.next(res);
      this.permission.complete();
    }
  
    async hasRolesAsync(roles: string[]): Promise<boolean> {
      if (roles.length === 1 && roles[0] === '') {
        return true;
      }
  
      if (this.permission.value.length == 0)
          await this.permission.toPromise();
  
      return this.checkRoles(roles)!;
    }
  
    hasRoles(roles: string[]): boolean {
      if (roles.length === 1 && roles[0] === '') {
        return true;
      }
  
      return this.checkRoles(roles)!;
    }
  
    getRoles() {
      return this.http.get<Role[]>(this.url + 'role');
    }
  
    loadRoles(callback: any) {
      if (this.userRole && this.rolesLoaded) {
        if (callback) {
          callback();
        }
      }
      else {
        this.getRoles().subscribe((roles: UserRole[]) => {
          this.userRole = roles;
          this.rolesLoaded = true;
  
          if (callback) {
            callback();
          }
        });
      }
    }
  
    checkRoles(roles: string[]) {
      if (!this.permission.value) {
        return false;
      }
  
      if (this.permission.value.includes('admin')) {
        return true;
      }
  
      for (const oneRole of roles) {
        if (!this.permission.value.includes(oneRole)) {
          return false;
        }
  
        return true;
      }
    }
  
    getUserName(user: User) {
      if (!user) {
        return '';
      }
  
      let fn = user.firstName ? user.firstName : '';
      let ln = user.lastName ? user.lastName : '';
      return fn + (fn.length > 0 && ln.length > 0 ? ' ' : '') + ln;
    }
  
    async setDefaultRef() {
      let url = '/assistance';
  
      if (await this.hasRolesAsync(['menu.technical-conditions'])) {
        this.url = '/technical-conditions';
      }
  
      if (await this.hasRolesAsync(['menu.home'])) {
        this.url = '/home';
      }
  
      this.authService.setDefaultRef(this.url);
     }
  
     getConsent(id: number) {
      return this.http.get<User>(`${this.url}users/${id}?consent=true`);
    }
  
    async isModuleActive(idModule: number) {
      if (this.permission.value.includes('admin')) {
        return true;
      }
  
      if( this.userLogged?.disabledModules?.some(
        (m) => m === idModule)
      ) {
        return false;
      } else {
        return true;
      }
    }
  
    async permissionEditCompanyAsync(
      companyId: number,
      roles: string[],
      rolesCompany: string[]
    ): Promise<boolean> {
      if (this.permission.value?.length == 0) {
        await this.permission.toPromise();
      }
  
      if (this.hasRoles(roles)) { return true; } 
      else if (this.hasRoles(rolesCompany) && (companyId === this.userLogged.idCompany)) { return true; } 
      else { return false; }
    }
  
}
