import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BuildingVertical } from "../building.model";
import { BuildingService } from "../building.service";

@Component({
  selector: "app-building-vertical",
  templateUrl: "./building-vertical.component.html",
  styleUrls: ["./building-vertical.component.scss"],
})
export class BuildingVerticalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<BuildingVerticalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: BuildingVertical,
    public buildingServ: BuildingService
  ) {}

  ngOnInit() {
    if (this.data.id > 0) {
      this.buildingServ.loadBuildingVertical(this.data.id);
    } else {
      this.buildingServ.buildingVertical = new BuildingVertical();
      this.buildingServ.buildingVertical.idBuilding = this.data.idBuilding;
    }
  }

  save() {
    if (this.data.id === 0) {
      this.buildingServ.createBuildingVertical().subscribe(() => {
        this.dialogRef.close(true);
      });
    } else {
      this.buildingServ.updateBuildingVertical().subscribe(() => {
        this.dialogRef.close(true);
      });
    }
  }
}
