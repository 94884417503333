import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { UserDetailsPipe } from '../../_pipes/user-details.pipe';
import { DictionaryService } from '../../dictionary/dictionary.service';
import { CardRowType, DeleteEvent, MobileCardDisplayOption, MobileCardParameters, MobileCardRowDisplay, RowType } from './mobile-card-option';

@Component({
  selector: 'app-mobile-card-view',
  templateUrl: './mobile-card-view.component.html',
  styleUrls: ['./mobile-card-view.component.scss']
})
export class MobileCardViewComponent {

  CardRowType = CardRowType;
  RowType = RowType;

  @Input()
  rows: BehaviorSubject<any[]> = new BehaviorSubject(null);

  @Input()
  rowsDisplay: MobileCardRowDisplay[] = [];

  @Input()
  parameters: MobileCardParameters;

  @Input()
  customCss: string = '';

  @Output()
  deleteItem: EventEmitter<DeleteEvent> = new EventEmitter<DeleteEvent>();

  constructor(
    public translate: TranslateService,
    public dictionaryServ: DictionaryService,
    private router: Router,
    private route: ActivatedRoute,
    private userDetailsPipe: UserDetailsPipe,
  ) { }

  ngOnInit() { }

  onDeleteTime(id: number, event: Event) {
    const deleteEvent: DeleteEvent = {
      ...new DeleteEvent(),
      idObject: id,
      event: event,
    };

    this.deleteItem.emit(deleteEvent);
  }

  ngOnDestroy() { }

}
