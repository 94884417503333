<ng-container *ngIf="!customBreadcrumbsArray.length; else customBreadcrumbs">
  <xng-breadcrumb 
  [separator]="iconTemplate"
  >
  <ng-container *xngBreadcrumbItem="let breadcrumb">
    <ng-container>{{ breadcrumb | translate }}</ng-container>
  </ng-container>
  </xng-breadcrumb>
  <ng-template #iconTemplate>
    <mat-icon>chevron_right</mat-icon>
  </ng-template>
</ng-container>
<ng-template #customBreadcrumbs>
  <div class="custom-breadcrumbs xng-breadcrumb-root">
    <ng-container
      *ngFor="
        let breadcrumb of customBreadcrumbsArray;
        let first = first;
        let last = last"
    >
    <mat-icon
      *ngIf="!first"
      class="icon-and-text-in-same-line"
      name="chevron_right"
    ></mat-icon>
    <ion-label
      [ngClass]="{'xng-breadcrumb-trail disabled': last, ' xng-breadcrumb-link': !last}"
      class="xng-breadcrumb-item icon-and-text-in-same-line"
      (click)="router.navigateByUrl(breadcrumb.routeLink)"
    >
      {{ breadcrumb.label }}
    </ion-label>
    </ng-container>
  </div>
</ng-template>